import slider from "../Slider/Slider";
import {SimCardUnitInt} from "../OrderSimCard/SelectNumber/SelectNumber";
import {PersonalDataInt} from "../OrderSimCard/PersonalData/PersonalData";
import {PaymentInt} from "../OrderSimCard/Payment/Payment";

const SET_BASE_URL= "SET_BASE_URL"
const SET_AUTH_TOKEN= "SET_AUTH_TOKEN"
const SET_BASE_REGION = "SET_BASE_REGION"
const SET_LOGIN_STATE = "SET_LOGIN_STATE"
const SET_ADMIN_STATE = "SET_ADMIN_STATE"
const SET_TF_FILTER_STATE = "SET_TF_FILTER_STATE"

const SET_SLIDER = "SET_SLIDER"
const SET_ORDER_DATA = "SET_ORDER_DATA"


//const BASE_URL = "https://192.168.0.15:3001"

const BASE_URL = "https://vamsalam.ru:5000"
//const BASE_URL = "https://vamsalam.ru:5000"

export interface TariffFilter
{
    active:boolean
    appliedFilter:boolean
    operId:string[];
    type:number[];
    tether:number[];

}
interface BaseRegionInt
{
    macroRegionId?: number
    regionId?: number
    name?: string
    makeSelectRegion?:boolean
    isRegionChanged?:boolean
}

interface OrderDataInt
{
    regMode?:number
    regionId?:number
    macroRegionId?:number
    tariffId?:string
    bundleId?:string
    selectedNumber?:SimCardUnitInt
    operId?:string
    name?:string
    macroRegionName?:string
    price?: number
    orderId?:string
    payment?:PaymentInt
    personalData?:PersonalDataInt
}

interface SliderInt
{
    sliderMode?:number;
    dataCardItem?:any;

}

export interface ReduxState
{
    baseUrl: string;
    authToken: string;
    baseRegion: BaseRegionInt;
    isLogin: boolean;
    isAdmin: boolean;
    tfFilter: TariffFilter;
    slider: SliderInt;
    orderData:OrderDataInt;

}
const authToken = localStorage.getItem("authToken")
let lsTfFilter:any = null
try {
    const data = localStorage.getItem("tfFilter")
    if(data ) lsTfFilter = JSON.parse(data)
} catch (e) {}


const defaultState:ReduxState =
    {
        baseUrl: BASE_URL,
        authToken:  authToken ? authToken : "",
        baseRegion: {macroRegionId: 0, regionId: 0, name:"Не выбран",makeSelectRegion:false,isRegionChanged:false},
        isLogin: localStorage.getItem("isLogin")!==undefined ? (localStorage.getItem("isLogin") === "1" ): false,
        isAdmin: localStorage.getItem("isAdmin")!==undefined ? (localStorage.getItem("isAdmin") === "1" ): false,
        tfFilter: lsTfFilter ? lsTfFilter : {active: false,appliedFilter:false,operId:[],type:[],tether:[]},
        slider: {sliderMode: 0,dataCardItem:{}},
        orderData: {}
    }

export default function globalReducer(state= defaultState,action:any ):ReduxState
{
    switch (action.type)
    {
        case SET_BASE_URL:
            return {...state, baseUrl: action.payload}
        case SET_BASE_REGION:
            return {...state, baseRegion: {...state.baseRegion, ...action.payload}}

        case SET_SLIDER:
            return {...state, slider: {...state.slider, ...action.payload}}

        case SET_ORDER_DATA:
            return {...state, orderData: {...state.orderData, ...action.payload}}

        case SET_LOGIN_STATE:
            if(action.payload) localStorage.setItem("isLogin","1")
            else localStorage.setItem("isLogin","0")
            return {...state, isLogin: action.payload}
        case SET_ADMIN_STATE:
            if(action.payload) localStorage.setItem("isAdmin","1")
            else localStorage.setItem("isAdmin","0")
            return {...state, isAdmin: action.payload}

        case SET_AUTH_TOKEN:
            localStorage.setItem('authToken',action.payload);
            return {...state, authToken: action.payload}
        case SET_TF_FILTER_STATE:
            localStorage.setItem('tfFilter',JSON.stringify(action.payload));
            return {...state, tfFilter: action.payload}



        default: return state;
    }
}
export const setBaseUrl = (data:string) => ({type:SET_BASE_URL,payload: data});
export const setLoginState = (data: boolean ) => ({type:SET_LOGIN_STATE,payload: data});
export const setAdminState = (data: boolean ) => ({type:SET_ADMIN_STATE,payload: data});

export const setAuthToken = (bearer:string) => ({type: SET_AUTH_TOKEN,payload: bearer});
export const setTfFilter = (filter:TariffFilter) => ({type: SET_TF_FILTER_STATE,payload:filter});
export const setBaseRegion = (data:BaseRegionInt) => ({type: SET_BASE_REGION,payload: data});
export const setSlider = (data:SliderInt) => ({type: SET_SLIDER,payload: data});

export const setOrderData = (data:OrderDataInt) => ({type: SET_ORDER_DATA,payload: data});
