import styles from './PersonalData.module.css'
import {SimCardUnitInt} from "../SelectNumber/SelectNumber";
import React, {useEffect, useRef, useState} from "react";
import test from '../../Img/capture.png'
import WaitLoader from "../../WaitLoader/WaitLoader";
import useFormValidator, {FormValidatorInt} from "../../FormValidator/FormValidator";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setOrderData} from "../../Reducer/GlobalReducer";
export interface PersonalDataInt
{
    family:string;
    name:string;
    middleName:string;
    birthDate:string;

    type:"RU"|"FOREIGN";
    serial:string;
    number:string;
    depart:string;

    departText:string;
    docDate: string;

    capt?: string;
    valid:boolean;

}

interface NextButtonCallBackInt
{
    (data:PersonalDataInt | undefined):void
}
interface PersonalDataPropsInt
{
    cbExecute: NextButtonCallBackInt
}

export default function PersonalData({cbExecute}:PersonalDataPropsInt)
{

    const dispatch = useDispatch()
    const orderData = useSelector((state: ReduxState) => {
        return state.orderData
    })

    const dataTaskDoc = useRef({
        rayId:""
    })
    const [ldrTaskDoc,rsTaskDoc,exTaskDoc] = useApiQuery("/task/result","post",dataTaskDoc);
    const [checkTaskCount, setCheckTaskCount] = useState(0)
    const [checkTaskTimerId,setCheckTaskTimerId] = useState<NodeJS.Timer>()

    const [stage, setStage] = useState(0)

    const [checkRs, setCheckRs] = useState("")


    const pdData = useRef<PersonalDataInt>()
    const [ldrPdData,rsPdData,exPdData] = useApiQuery("/sell/sim/document/check","post",pdData);

    const dataCapt = useRef({
        name: "pData",
        len:4,
        useDigit: true
    })
    const [ldrDataCapt,rsDataItemCapt,executeLoadDataCapt] = useApiQuery("/auth/capt","post",dataCapt);
    const [capt,setCap]=useState("")


    const [lockForm, setLockForm] = useState(false)
    const [acceptPd, setAcceptPd]= useState(false)


    const normFio = (val: string)=>{
        return String(val).charAt(0).toUpperCase() + String(val).slice(1).toLowerCase();
    }
    const normDepart = (val: string)=>{
        const pl = val.replace(/[^0-9]/ui,'')
        if(pl?.match(/^[0-9]{6}$/ui)) return pl
        return val
    }

    const normDate = (val: string)=>{
        if(val?.match(/^[0-9]{8}$/ui))
            return val.substring(0,2) + "." + val.substring(2,4) + "." + val.substring(4,8)
        if(val?.match(/^[0-9]{2}.[0-9]{2}.[0-9]{4}$/ui))
            return val.substring(0,2) + "." + val.substring(3,5) + "." + val.substring(6,10)
        return val
    }


    const [formData,setFormData]=useState<FormValidatorInt[]>([
        {
            val:"",
            id:"family",
            errText: "Не корректная фамилия",
            regExp: /^[а-я]+[а-я-\s]+$/ui,
            isCheck: true,
            valid: true,
            normalize: normFio,
        },
        {
            val:"",
            id:"name",
            errText: "Не корректное имя",
            regExp: /^[а-я]+[а-я-\s]+$/ui,
            isCheck: true,
            valid: true,
            normalize: normFio,

        },
        {
            val:"",
            id:"middleName",
            errText: "Не корректное отчество",
            regExp: /^[а-я]+[а-я-\s]+$/ui,
            isCheck: true,
            valid: true,
            normalize: normFio,

        },
        {
            val:"",
            id:"birthDay",
            errText: "Не корректная дата рождения",
            regExp: /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui,
            isCheck: true,
            valid: true,
            normalize: normDate,

        },

        {
            val:"",
            id:"serial",
            errText: "Не корректная серия паспорта",
            regExp: /^[0-9]{4}$/ui,
            isCheck: true,
            valid: true
        },

        {
            val:"",
            id:"number",
            errText: "Не корректный номер паспорта",
            regExp: /^[0-9]{6}$/ui,
            isCheck: true,
            valid: true
        },
        {
            val:"",
            id:"depart",
            errText: "Не корректный код департамента",
            regExp: /^([0-9]{6})|([0-9]{3}-[0-9]{3})$/ui,
            isCheck: true,
            valid: true,
            normalize: normDepart,

        },

        {
            val:"",
            id:"date",
            errText: "Не корректная дата выдачи",
            regExp: /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui,
            isCheck: true,
            valid: true,
            normalize: normDate,

        },
        {
            val:"",
            id:"capt",
            errText: "Не корректный код с картинки",
            regExp: /^[a-z0-9]{4,}$/ui,
            isCheck: true,
            valid: true
        },



    ])
    const [validate,validateById,getValue,setValue,getErrText] = useFormValidator(formData)

    const loadNewCapt= ()=>
    {
        executeLoadDataCapt().then((e)=>{
            if(e?.success)setCap(e?.payload)
        })

    }


    useEffect(()=>{
        switch (stage)
        {
            case 0://start
                setCheckTaskCount(0)
                setLockForm(false)
                break
            case 1://process validate
                setLockForm(true)
                break
            case 2://ok valid
                setCheckTaskCount(0)
                setLockForm(false)
                if(pdData.current)pdData.current.valid = true
                dispatch(setOrderData({...orderData,personalData: pdData.current}))

                setTimeout(()=>{
                    if(cbExecute)cbExecute(pdData.current)
                },1000)

                break
            case 3://ok not valid
                setCheckTaskCount(0)
                setLockForm(false)
                break
            case 4://unknown
                setCheckTaskCount(0)
                setLockForm(false)
                break
            default:
                setCheckTaskCount(0)
                setLockForm(false)
        }
    },[stage])

    useEffect(()=>{
        loadNewCapt()
    },[dataCapt])

    const handleSend = () =>{
        if(!validate())return
        pdData.current = {
            family: getValue("family")?.trim(),
            name: getValue("name")?.trim(),
            middleName: getValue("middleName")?.trim(),
            birthDate : getValue("birthDay")?.trim(),
            type: "RU",
            serial: getValue("serial")?.trim(),
            number: getValue("number")?.trim(),
            depart: getValue("depart")?.trim(),
            docDate: getValue("date")?.trim(),
            departText: "",
            capt: getValue("capt")?.trim(),
            valid: false
        }
//        setLockForm(true)
        setStage(1)
        exPdData().then((e)=>{
            if(e?.success && e?.payload?.rayId)
            {
                taskDocCheck(e?.payload?.rayId)
            } else {
                setCheckRs(e?.errorAsString)
                setStage(3)
            }

            loadNewCapt()
//            setLockForm(false)
        }).catch((e)=>{
            console.log(e)
            loadNewCapt()
            setStage(0)
        })


        console.log(pdData.current)

    }


    const taskDocCheck = (rayId: string) =>{
        clearInterval(checkTaskTimerId)
        const int = setInterval(()=>{
            setCheckTaskCount(prevState=>prevState+1)
            if(checkTaskCount>50)
            {
                clearInterval(int)
                setStage(4)
            }

            dataTaskDoc.current.rayId = rayId
            exTaskDoc().then((e)=>{
                try {
                    console.log("task check",e)
                    if(e.success)
                    {
                        if(e.payload.result)
                        {
                            clearInterval(int)
                            setLockForm(false)
                            const rs = e?.payload?.outData
                            console.log("TASK RES ", e?.payload?.outData)
                            console.log("validType", rs?.validType)
                            if(! rs.validType) {
                                setStage(4)
                                setCheckRs("Ошибка сервера")
                                return
                            }

                            if(rs.validType?.match(/^valid/ui))
                            {
                                console.log("valid")
                                setStage(2)
                                setCheckRs(rs?.errorAsString)
                                return;
                            }
                            if(rs.validType?.match(/^invalid/ui))
                            {
                                console.log("invalid")
                                setStage(3)
                                setCheckRs(rs?.errorAsString)
                                return;
                            }
                            setStage(4)
                            setCheckRs(rs?.errorAsString)


                        }
                    }

                } catch (e){
                    setStage(4)
                    setCheckRs("Ошибка формата данных")
                }

                console.log()

            }).catch((e)=>{
                clearInterval(int)
                setStage(0)
            })
        },2500)
        setCheckTaskTimerId(int)

    }

    return(
        <div className={styles.pdCont}>
            <p className={styles.pdTitle}>Согласно закону о связи (Федеральный Закон “О связи” 126-ФЗ), все SIM карты оформляются строго на Российский паспорт. Далее будут запрошены Ваши персональные данные для оформления карты,
                Вы можете ознакомиться с политикой использования персональных данных по ссылке ниже. Во избежание фродовых данных Ваши данные будут проверены через системы СМЭФ на корректность
            </p>
            <div className={styles.pdPersData}>
                <p>Политика использования персональных данных<a href="#">скачать</a></p>
            </div>

            <p>Заполните форму</p>


            <div className={styles.formView}>
                <form >
                    <div className="row g-3 mb-3">
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Фамилия</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Фамилия"}
                                value={getValue("family")}
                                onBlur={()=>validateById("family")}
                                onChange={(e)=>setValue("family",e.target.value)}
                                maxLength={30}
                                readOnly={lockForm}
                            >
                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("family")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Имя</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Имя"}
                                   value={getValue("name")}
                                   onBlur={()=>validateById("name")}
                                   onChange={(e)=>setValue("name",e.target.value)}
                                   maxLength={30}
                                   readOnly={lockForm}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("name")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Отчество</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Отчество"}
                                   value={getValue("middleName")}
                                   onBlur={()=>validateById("middleName")}
                                   onChange={(e)=>setValue("middleName",e.target.value)}
                                   maxLength={40}
                                   readOnly={lockForm}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("middleName")}</div>
                        </div>
                    </div>

                    <div className="row g-3 mb-3">
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Дата рождения</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XX.XX.XXXX"}
                                   value={getValue("birthDay")}
                                   onBlur={()=>validateById("birthDay")}
                                   onChange={(e)=>setValue("birthDay",e.target.value)}
                                   maxLength={10}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("birthDay")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Серия паспорта</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXXX"}
                                   value={getValue("serial")}
                                   onBlur={()=>validateById("serial")}
                                   onChange={(e)=>setValue("serial",e.target.value)}
                                   maxLength={4}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("serial")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Номер паспорта</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXXXXX"}
                                   value={getValue("number")}
                                   onBlur={()=>validateById("number")}
                                   onChange={(e)=>setValue("number",e.target.value)}
                                   maxLength={6}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("number")}</div>
                        </div>
                    </div>


                    <div className="row g-3 mb-3">
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Дата выдачи</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XX.XX.XXXX"}
                                   value={getValue("date")}
                                   onBlur={()=>validateById("date")}
                                   onChange={(e)=>setValue("date",e.target.value)}
                                   maxLength={10}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("date")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Код подразделения</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXX-XXX"}
                                   value={getValue("depart")}
                                   onBlur={()=>validateById("depart")}
                                   onChange={(e)=>setValue("depart",e.target.value)}
                                   maxLength={7}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("depart")}</div>
                        </div>
                    </div>


                    <div className="row g-3 mb-3">
                        <div className="col-12">
                            <div className={styles.captContainer}>
                                <label htmlFor="exampleInputEmail1 m-0" className="form-label col-form-label-sm">Проверочный код</label>
                            </div>
                        </div>
                    </div>


                    <div className="row g-3 mb-3">
                        <div className="col-12 col-md-4 my-auto">
                            <div className={styles.captContainer}>
                                <img className={styles.capImg} src={capt} onClick={()=>{loadNewCapt() }}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 my-auto">
                            <input type="text" className="form-control" placeholder={"Код с картинки"} style={{width: '170px'}}
                                   value={getValue("capt")}
                                   onBlur={()=>validateById("capt")}
                                   onChange={(e)=>setValue("capt",e.target.value)}
                                   maxLength={4}


                            ></input>

                        </div>
                        <div  className={["", styles.errMessage].join(' ')}>{getErrText("capt")}</div>


                    </div>



                    <div className="row g-3 mb-3">
                        <div className="col-12 ">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="gridCheck"
                                       checked={acceptPd}
                                       onChange={()=>setAcceptPd(!acceptPd)}

                                ></input>
                                <label className="form-check-label col-form-label-sm" htmlFor="gridCheck">
                                    Даю согласие на обработку данных
                                </label>
                            </div>
                        </div>

                    </div>


                    <div className="col-12">
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-primary"
                                    disabled={!acceptPd || stage===1}
                                    onClick={()=>handleSend()}
                            >Отправить</button>
                            <WaitLoader loading={ldrPdData} api={rsPdData} onlyLoading={true} >
                                <div className={styles.checkRs}>
                                    {stage === 1 && <div className={styles.wCheck}>{`Идет проверка ${checkTaskCount}`}</div>}
                                    {stage === 2 && <div className={styles.checkOk}>{checkRs}</div>}
                                    {stage === 3 && <div className={styles.checkErr}>{checkRs}</div>}
                                </div>
                            </WaitLoader>

                        </div>

                    </div>





                </form>
            </div>

        </div>
    )
}