import styles from './SelectUnit.module.css'
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {getValue} from "@testing-library/user-event/dist/utils";
import filterRegExp from "../../Util/filterRegExp";


export interface SelectUnitRefInt
{
    loadData: (data: SelectUnitDataInt[], def?:string[]) => Promise<void>,
    setValue: (data: string[]) => Promise<void>,
    getValue: () => string[],
    resetValue: ()=>void
}

interface cbValue
{
    (data:any[]):void
}
export interface SelectUnitPropsInt
{
    inData:SelectUnitDataInt[]
    capt: string
    getVal?: cbValue
    multiSelect?: boolean
    enabled?:boolean
}
export interface SelectUnitDataInt
{
    name:string;
    val:any;
}

interface ItemSelectUnitInt
{
    data: SelectUnitDataInt;
    selected:boolean;
    show:boolean;
}

const SelectUnit = forwardRef<SelectUnitRefInt,SelectUnitPropsInt>( ({capt,inData,getVal,multiSelect,enabled}:SelectUnitPropsInt, ref)=>
{
    const [item,setItem] = useState<ItemSelectUnitInt[]>([])
    const [val,setVal] = useState<any[]>([])
    const [dropped, setDropped] = useState(false)
    const [fndVal, setFndVal]= useState("")
    const [textVal, setTextVal]=useState("")


    const wrapperRef = useRef(null);
    useImperativeHandle(ref, () => ({
         loadData :  loadData,
         setValue :  setValue,
         getValue: getValue,
         resetValue: resetValue
    }));

    const resetValue = ()=>{
        setVal([])
        setTextVal("")
        setFndVal("")
        setItem(item.map((item)=>{
            item.selected = false
            return item
        }))
    }
    const getValue = ():string[] => {
        return val
    }
    async function loadData(data: SelectUnitDataInt[], def?:string[]){
        loadDataItem(data)
    }
    async function setValue (data: string[]){
        //console.log("setValue",data,item)
        if(data.length === 0) return
        const ms = multiSelect ? multiSelect : false
        if(! ms)
        {
            const idx = item.reduce((acc,item, idx)=>{
                //console.log("reduce",item,idx)
                if(item.data.val === data[0])acc = idx
                return acc
            },-1)
            //console.log("found", idx)
            if(idx < 0) return
            handleClickItem(idx,data[0])
            return
        }

    }


    const loadDataItem = (data: SelectUnitDataInt[]) =>{
        const lineList = data.map((unit)=>{
            const  line:ItemSelectUnitInt ={
                data:unit,
                selected:false,
                show:true
            }
            return line
        })
        setItem(lineList)

    }

    useEffect(()=>{

    },[item])


    useEffect(()=>{
        loadDataItem(inData)
        //if(getVal)getVal(inData.map((a)=>{return a.val}))
    },[inData])

    useEffect(()=>{
        console.log("val",val)
    },[val])

    useEffect(()=>{
        const ms = multiSelect ? multiSelect : false
        if(ms)
        {
            const txt = val.length === item.length ? "все" : val.length.toString()
            setTextVal(`[ выбрано (${txt}) ]`)
        }
        if(getVal)getVal(val)
    },[val])

    const isSelectedOne = () =>{
        return item.reduce((acc, itm)=>{
            if(itm.selected) acc = true
            return acc
        },false)
    }


    const handleClickItem = (key:number,val:any) =>{
        //console.log("handleClick",key,val)
        const ms = multiSelect ? multiSelect : false
        if(!ms)
        {
            const newItem = item.map((itm,k)=>{
                if(k===key){itm.selected = true; setTextVal(itm.data.name)}
                else itm.selected=false
                return itm
            })
            setVal([val])
            setItem(newItem)
            setDropped(false)
            setFndVal("")


        }


    }

    const handleSelectItem=(key:number)=>{
        const vl:any=[]
        const newItem = item.map((itm,k)=>{
            if(k===key) itm.selected = !itm.selected
            if(itm.selected)vl.push(itm.data.val)
            return itm
        })
        setItem(newItem)
        setVal(vl)
        //setTextVal(`[ выбрано: ${vl.length} ]`)

    }

    const buildBody=()=>{
        const ms = multiSelect ? multiSelect : false

        return item.map((itm,key)=>{
            if(! itm.show)return null
            if(!ms)return(
                <div key={key} className={styles.bodyItem} onClick={()=>handleClickItem(key,itm.data.val) }>{itm.data.name}</div>
            )
            if(ms)return (
                <div key={key} className={styles.multiLine} onClick={()=>handleSelectItem(key)}>
                    <div  className={styles.bodyMultiItem} onClick={()=>handleClickItem(key,itm.data.val) }>
                        {itm.data.name}
                    </div>
                    <input type="checkbox" className="form-check-input" checked={itm.selected} id={"exampleCheck1"}
                        onChange={()=>{} }
                    ></input>

                </div>
            )
        })
    }

    const filterVal= (e:string)=>{
        const tv = filterRegExp(e.trim())
        setFndVal(tv)

        const newItem = item.map((itm)=>{
            const pattern = new RegExp('('+tv+')',"ui")
            if( ! itm?.data?.name?.toString().match(pattern))itm.show= false
            else itm.show = true
            return itm
        })
        setItem(newItem)
    }

    const handleMultiReset=()=>{
        const newItem = item.map((itm)=>{
            itm.selected = false
            return itm
        })
        setItem(newItem)
        setVal([])
    }
    const handleMultiAll=()=>{
        const vl:any=[]
        const newItem = item.map((itm)=>{
            itm.selected = true
            vl.push(itm.data.val)
            return itm
        })
        setItem(newItem)
        setVal(vl)
        //setTextVal(`[ выбрано: ${vl.length} ]`)

    }


    function useOutsideAlerter(ref:any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event:any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    //console.log(`You clicked outside of me! ${capt}`);
                    setDropped(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }




    const isSelected = isSelectedOne()
    const captText = isSelected ? capt + " : " + textVal : `Выбор [ ${capt} ]`
    const ms = multiSelect ? multiSelect : false
    useOutsideAlerter(wrapperRef);
    const disabled = (enabled === undefined ) ? false: !enabled
    return(


        <div ref={wrapperRef} className={styles.selectCont} >
            <button type="button" className= {["btn btn-secondary",styles.btnCheck].join(' ')}
                    disabled={disabled}
                    onClick={()=>{
                        setDropped(!dropped)
                        filterVal("")
                }}
            >{captText}</button>
            {dropped && <div className={styles.selectBodyCont}>
                <div className={["input-group", styles.selectFind].join(' ')}>
                    <input type="text" className="form-control" placeholder={"поиск..."}
                           value={fndVal}
                           onChange={(e)=>filterVal(e?.target?.value?.toString())}
                    ></input>
                </div >

                {ms&&<div className={styles.btnMultiCont}>
                    <button type="button" className= {["btn btn-secondary",styles.btnMulti].join(' ')}
                    onClick={()=>handleMultiAll()}>Все</button>

                    <button type="button" className= {["btn btn-secondary",styles.btnMulti].join(' ')}
                        onClick={()=>handleMultiReset()}
                    >Сброс</button>

                    <button type="button" className= {["btn btn-secondary",styles.btnMulti].join(' ')}
                            onClick={()=>setDropped(false)}>Закрыть</button>

                </div>}

                {buildBody()}
            </div>}
        </div>
    )

})
export default SelectUnit