import styles from './LK.module.css'
import LeftSideMenu from "../LeftSideMenu/LeftSideMenu";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import NewSimParty from "./NewSimParty/NewSimParty";
import ScanRemains from "./ScanRemains/ScanRemains";
import ActiveSimInfo from "./ActiveSimInfo/ActiveSimInfo";
import ActiveSimJob from "./ActiveSimJob/ActiveSimJob";
import LoadNewDkp from "./LoadNewDkp/LoadNewDkp";
import BaseTarifBuilder from "./TarifBuilder/BaseTarifBuilder";
import RefTarifTag from "./RefTarifTag/RefTarifTag";
import ReviewModule from "./ReviewModule/ReviewModule";
import SysOption from "./SysOption/SysOption";
export default function Lk()
{
    return(
        <div>
            <LeftSideMenu>
                <Routes>
                    <Route path='/test' element={<h3>привет2</h3>} />
                    <Route path='/app/scan' element={<ScanRemains/>} />
                    <Route path='/load' element={<NewSimParty></NewSimParty>} />
                    <Route path='/app/sim/info' element={<ActiveSimInfo></ActiveSimInfo>} />
                    <Route path='/app/sim/job' element={<ActiveSimJob></ActiveSimJob>} />
                    <Route path='/dkp/load' element={<LoadNewDkp></LoadNewDkp>} />
                    <Route path='/tarif/base' element={<BaseTarifBuilder></BaseTarifBuilder>} />
                    <Route path='/tarif/tag' element={<RefTarifTag></RefTarifTag>} />
                    <Route path='/tarif/review' element={<ReviewModule></ReviewModule>} />
                    <Route path='/option' element={<SysOption></SysOption>} />
                </Routes>
            </LeftSideMenu>
        </div>
    );
}