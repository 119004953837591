import styles from './SecondMenu.module.css'
import iconGeo from '../Img/icon_geo.png'
import iconLK from '../Img/icon_lk.png'
import {useDispatch, useSelector} from "react-redux";
import {
    ReduxState,
    setAuthToken,
    setBaseRegion,
    setLoginState, setSlider,
    setTfFilter
} from "../Reducer/GlobalReducer";
import React, {useRef, useState} from "react";
import ModalWindow from "../ModalWindow/ModalWindow";
import LoginForm from "../LoginForm/LoginForm";
import {useLocation, useNavigate} from "react-router-dom";
import RegionChange from "../RegionChange/RegionChange";
import useApiQuery, {ApiQueryResponse} from "../ApiQuery/UseApiQuery";

export const SecondMenuContext = React.createContext<any | undefined>(undefined);

function SecondMenu()
{
    const mockData = useRef(null)
    const [ldrLogout,rsLogout,exLogout] = useApiQuery("/auth/logout","get",mockData,cbLogout);


    const dispatch = useDispatch();

    const baseRegion = useSelector((state:ReduxState)  => {return state.baseRegion});

    const tarifFilter = useSelector((state:ReduxState)  => {return state.tfFilter});

    const navigate = useNavigate();
    const location = useLocation()

    const isRegionChanged = useSelector((state:ReduxState)  => {return state.baseRegion.isRegionChanged})
    const isLogin =  useSelector((state:ReduxState)  => {return state.isLogin})

    function cbLogout(payload:ApiQueryResponse){
        if(payload.success || true)
        {
            dispatch(setAuthToken(""))
            dispatch(setLoginState(false))
            navigate('/')
        }
    }
    const lkMake=() =>{
        if(isLogin)exLogout().then()
        else navigate('/auth')
    }


    return(
        <div className={styles.secondMenu}>
            <div className={styles.secondMenuContainer}>
                <div className={[styles.baseOption, styles.geo].join(' ')}
                     onClick={()=>{
                            //navigate('/region/change')
                                dispatch(setBaseRegion({makeSelectRegion:true}))
                        }}>
                    <div>
                        <img src={iconGeo}></img>
                        <span className={isRegionChanged ? "" : styles.blink}>Выберите регион:</span>

                    </div>
                    <div>
                        <span>[{baseRegion.macroRegionId}]</span>
                        <span>{baseRegion.name}</span>
                    </div>

                </div>

                <div className={[styles.baseOption, styles.catalog].join(' ')}
                     onClick={  () =>
                     {
                         dispatch(setTfFilter(
                             {...tarifFilter,
                                 //active:!tarifFilter.active,
                                 active: false
                             }))
                         dispatch(setSlider({sliderMode:0}))
                         navigate('/')
                     }

                     }
                >
                    <span>Каталог тарифов</span>
                </div>

                <div className={[styles.baseOption, styles.catalog].join(' ')}
                onClick={  () =>
                {
                    dispatch(setTfFilter(
                    {...tarifFilter,
                        //active:!tarifFilter.active,
                        active: true
                    }))
                    dispatch(setSlider({sliderMode:0}))
                    navigate('/')
                }

                }
                >
                    <span>Фильтр тарифов</span>
                </div>
                <div className={[styles.baseOption, styles.lk].join(' ')} onClick={()=>navigate('/auth')}>
                    <div>
                        <span>Личный кабинет</span>
                        <img src={iconLK}></img>
                        <span
                            onClick={()=>lkMake()}
                        >{!isLogin ? "[Вход]" : "[Выход]"}</span>
                    </div>
                </div>

            </div>

        </div>
    );
}
export default SecondMenu