import styles from './TarifFilter.module.css'
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setTfFilter} from "../Reducer/GlobalReducer";
import useApiQuery from "../ApiQuery/UseApiQuery";
import {SelectUnitDataInt} from "../FormTemplate/SelectUnit/SelectUnit";


interface FilterSetInt
{
    (a:FilterInt):any
}
interface FilterPropsInt
{
    name: string;
    filter:FilterInt;
    setFilter: FilterSetInt;
}


interface FilterItemListInt
{
    [key:string]:any;
    itemId:any;
    itemChecked:boolean;
}
interface FilterInt
{
    dropped:boolean;
    filterItemList?:FilterItemListInt[]
    opOnlyOneSelect?:boolean
}
export default function TariffFilter()
{

    const tarifTypeList = useRef()
    const [ldrTarifTypeList,rsTarifType,exTarifType] = useApiQuery("/ref/tariff/type","get",tarifTypeList);
    const [tarifTypeListData, setTarifTypeListData] = useState<SelectUnitDataInt[]>([])


    const tfFilter = useSelector((state:ReduxState)  => {return state.tfFilter} )
    const dispatch = useDispatch();


    const [operFilter, setOperFilter] = useState<FilterInt>({
        dropped:false,

    })

    const [typeFilter, setTypeFilter] = useState<FilterInt>({
        dropped:false,

    })
    const [modemFilter, setModemFilter] = useState<FilterInt>({
        dropped:false,

    })

    const [useModemList, setUseModemList] = useState([
        {
            name: "Да",
            val:0
        },
        {
            name: "Нет",
            val:1
        },

    ])

    const [typeList, setTypeList] = useState([
        {
            name: "Для смартфона",
            val:0
        },
        {
            name: "Только для модема",
            val:2
        },
        {
            name: "Только для интернета",
            val:1
        },

    ])

    const [operList, setOperList] = useState([
        {
            name: "Мегафон",
            operId: "MEGAFON"
        },
/*        {
            name: "Йота",
            operId: "YOTA"
        },
        {
            name: "Билайн",
            operId: "BEELINE"
        },
        {
            name: "МТС",
            operId: "MTS"
        },
        {
            name: "Ростелеком",
            operId: "RTK"
        },*/
        {
            name: "T2",
            operId: "TELE2"
        },
        {
            name: "Билайн",
            operId: "BEELINE"
        },
    ])

    useEffect(()=>{
        loadTarifTypeList()
    },[tarifTypeList])

    //Проверка на то что выбран фильтр ли в storage
    const checkSelected= (val:any , ms:any[])=>{
        if(!ms || !val) return false
        return ms.reduce((acc,vl)=>{
            if(val===vl)acc = true
            return acc
        },false)
    }


    useEffect(()=>{
        if(! tfFilter.active) resetFilter(false)
    },[tfFilter.active])

    useEffect(()=>{
        setOperFilter({
            dropped:!!tfFilter?.operId.length,
            opOnlyOneSelect: false,
            filterItemList: operList.map((item)=>{
                return {...item,itemId:item.operId,itemChecked:checkSelected(item.operId, tfFilter?.operId)}
            })
        })

        setTypeFilter({
            dropped:!!tfFilter?.type.length,
            filterItemList: typeList.map((item)=>{
                return{...item, itemId:item.val,itemChecked:checkSelected(item.val, tfFilter?.type)}
            })
        })
        setModemFilter({
            dropped:!!tfFilter?.tether.length,
            filterItemList: useModemList.map((item)=>{
                return{...item, itemId:item.val,itemChecked:checkSelected(item.val, tfFilter?.tether)}
            })
        })

    },[typeList,operList,useModemList])


    const loadTarifTypeList = () =>{
        exTarifType().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return                    {
                        name: item?.name,
                        val: item?.typeId

                    }
                }))
                setTarifTypeListData(aData)
                setTypeList(aData)
                return
            }
        }).catch((e)=>{
        })

    }

    const getFilterItemCheckState = (filter:FilterInt, val:any)=>{
        if(! filter?.filterItemList || val===undefined) return false;
        return filter?.filterItemList.reduce((acc:boolean, item:FilterItemListInt)=>{
            if(item.itemId === val) acc = item.itemChecked
            return acc
        }, false)
    }
    const setFilterItemCheckState = (filter:FilterInt, setFilter:any, val:any, checked:boolean)=>{
        if(! filter?.filterItemList || val===undefined || !setFilter) return ;
        setFilter(  {...filter, itemList: filter.filterItemList.map((item=>{
            if(item.itemId === val) item.itemChecked = checked
            if(item.itemId !== val && filter?.opOnlyOneSelect) item.itemChecked = false
            //console.log("item", item)
            return item
        }))})
    }



    const buildFilter = ({filter,setFilter,name}:FilterPropsInt)=>{
//        const buildFilter = (name:string, filterItemList:FilterInt, setFilterItemList: any)=>{
        if(! filter?.filterItemList) return  null
        return(
            <div className={styles.tfBlock}>
                <div className={styles.tfHead}
                     onClick={()=>{
                         const dropped = filter.dropped
                         setFilter({...filter,dropped: !dropped})
                     }}>
                    <i className={['bx bxs-chevron-down arrow',styles.blockArrow, filter.dropped ? styles.dropped:"" ].join(' ')}></i>
                    <span>{name}</span>
                </div>
                <hr className={styles.hrBlock}></hr>
                <div className={[styles.itemContainer, filter.dropped ? "" :"d-none" ].join(' ')}>
                    {filter.filterItemList?.map((item, key)=>{
                        const checked = getFilterItemCheckState(filter,item.itemId)
                        //console.log("getCheckState ", checked, item.val)
                        return (
                            <form key={key}>
                                <div className={styles.tfItem}
                                onClick={()=>{setFilterItemCheckState(filter, setFilter,item.itemId,!checked)}}
                                >
                                    <input className={["form-check-input", styles.itemCheckBox].join(' ')} type={"checkbox"}
                                    checked={checked}
                                    onChange={()=>{}}
                                    ></input>
                                    {item?.name}
                                </div>
                            </form>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getFilterValue = (filter:FilterInt)=>
    {
        if(filter.filterItemList && filter.filterItemList?.length ===0 )return [];
        const rs:any[]=[]
        let checkCount = 0
        filter.filterItemList?.map((item)=>{
            if(item.itemChecked){  rs.push(item.itemId); checkCount++}
        })
        if(checkCount===0 || checkCount === filter.filterItemList?.length ) return []
        return rs;
    }

    const resetFilterValue = (filter:FilterInt, setFilter:any)=>
    {
        const itemList = filter.filterItemList?.map((item)=>{item.itemChecked = false; return item})
        setFilter({...filter,
            itemList: itemList, dropped:false

        })
    }

    const makeFilter = ()=>{
        const operFilterValue = getFilterValue(operFilter);
        const modemFilterValue = getFilterValue(modemFilter);
        const typeFilterValue = getFilterValue(typeFilter);
        dispatch(setTfFilter({
            ...tfFilter,
            appliedFilter: true,
            operId: operFilterValue,
            tether: modemFilterValue,
            type: typeFilterValue

        }))
        //console.log(operFilterValue,modemFilterValue,typeFilterValue)
    }
    const resetFilter = (hide:boolean)=>{
        resetFilterValue(operFilter, setOperFilter)
        resetFilterValue(modemFilter, setModemFilter)
        resetFilterValue(typeFilter, setTypeFilter)
        dispatch(setTfFilter({
            ...tfFilter,
            active: hide,
            appliedFilter: false,
            operId: [],
            tether: [],
            type: []

        }))

    }

    const closeFilter = (hide:boolean) =>{
        resetFilter(hide)
    }


    if(! tfFilter.active)
    {
        return null
    }
    return(
        <div className={styles.tfListContainer}>
            <div className={styles.tfList}>
                {buildFilter({name:"Оператор",filter:operFilter, setFilter:setOperFilter})}
                {buildFilter({name:"Тип sim карты",filter:typeFilter, setFilter:setTypeFilter})}
                {buildFilter({name:"Работа на раздачу",filter:modemFilter, setFilter:setModemFilter})}
                <div className={styles.btContainer}>
                    <div className={styles.btn} onClick={()=>makeFilter()}>Применить</div>
                    <div className={styles.btn} onClick={()=>resetFilter(true)}>Сбросить</div>
                    <div className={styles.btn} onClick={()=>closeFilter(false)}>Закрыть</div>
                </div>
            </div>
        </div>
    )
}