import styles from './InputItem.module.css'
import {useEffect, useRef, useState} from "react";


interface InputItemPreProcessInt
{
    (val:any):string
}
interface InputItemCbModeInt
{
    (val:any):void
}

interface InputItemPropsInt
{
    regExp?:RegExp
    val:string|boolean|number
    mode:string// ed
    onDelete?:InputItemCbModeInt
    onChange?:InputItemCbModeInt
    onBlur?:InputItemCbModeInt
    onUpload?:InputItemCbModeInt
    onIncorrect?:InputItemCbModeInt
    onPreProcess?:InputItemPreProcessInt

    title?:string
    info?:string
    type?:string

    inputType?:number
    lineCount?:number


}
export default function InputItem(data:InputItemPropsInt)
{
   // console.log("reload")
    const [val, setVal] = useState<any>("")
    const [isEditable, setIsEditable] = useState(false)
    const [isCorrect, setIsCorrect] = useState(true)
    const [showHint, setShowHint] = useState(false)
    const lockSend = useRef(true)

    const [inputType, setInputType] = useState(data?.inputType ? data?.inputType : 0)


    const inputRef = useRef<HTMLInputElement >(null)



    useEffect(()=>{
        if(data.mode.match(/e/ui))setIsEditable(true)
        if(data.val !== undefined && data.val !==null)setVal(data.val)
//        if(data.val === null) setVal("")
        else setVal("")
        //setIsCorrect(true)
    },[data])

    useEffect(()=>{
        //if(showInput && inputRef?.current) inputRef.current.focus()
    },[isEditable])

    useEffect(()=>{
        //console.log("val effect ",val, typeof val, lockSend.current)
        lockSend.current = false

    },[val])

    const buildModeList = () =>{
        const itm = data.mode?.split('')?.map((item,idx)=>{
            if(item.match(/e/ui)) return(
                <div key={idx} className={[styles.modeItm].join(' ')}
                onClick={()=>{
                    if(inputRef?.current) {
                        inputRef.current.focus()
                        inputRef.current.select()
                    }
                }}
                ><i className={'bx bx-edit-alt'}></i></div>
            )
            if(item.match(/d/ui)) return <div key={idx} className={styles.modeItm}><i className='bx bx-message-alt-x'></i></div>
            if(item.match(/u/ui)) return <div key={idx} className={styles.modeItm}
                onClick={()=>{
                    if(isCorrect && data?.onUpload) data.onUpload(val)
                }}
                ><i className='bx bx-upload'></i></div>
            if(item.match(/a/ui)) return <div key={idx} className={styles.modeItm}><i className='bx bxs-file-plus'></i></div>
            if(item.match(/i/ui)) return <div key={idx} className={styles.modeItm} onClick={()=>handleInfo()}><i className='bx bx-help-circle'></i></div>
        })
        return itm ? <div className={styles.modeCont}>{itm}</div> : null

    }

    const handleInfo = () =>{
        setShowHint(true)
        setTimeout(()=>setShowHint(false),2000)
    }
    const buildTitle = () =>{
        if(data?.title) return (
            <div className={[styles.itmTitle,"col-sm-4"].join(' ')}>{data.title}</div>
        )
        return null
    }

    const handleCloseCheckBox = (e: any) =>{
        if(val === undefined) return;
        if(data?.onBlur && !lockSend.current)data.onBlur(val ? false: true)
    }

    const handleCloseInput = (e:any)=>{
        if(val === undefined) return;
        let vl = typeof val ==="string" ? val.trim() : val.toString().trim()
        if(data?.onPreProcess) vl = data.onPreProcess(vl)

        //console.log("ref ",lockSend.current,vl)
        if(data?.regExp)
        {
            const rg = data?.regExp ? data?.regExp : /.*/ui
            if(!vl?.toString().match(rg))
            {
                console.log("set incorrect" ,isCorrect)
                setIsCorrect(false)
                if(data?.onIncorrect)data.onIncorrect(vl)
                return
            }
        }
        //setShowInput(false)
        console.log("set correct ok")
        setIsCorrect(true)
        if(data?.onBlur && !lockSend.current)data.onBlur(vl )
        lockSend.current = true
    }
    //console.log("curr is cor", isCorrect)
    const dt = inputType ===1 ? 5: 0
    return(
        <div className={styles.itmCont}>
            <div className={[styles.itmValCont,"row"].join(' ')}>
                <div className={[styles.itmTitle,`col-sm-${4+dt} `].join(' ')}>{data.title}</div>
                <div className={[styles.valCont,`col-sm-${8-dt}`].join(' ')}>
                    <div className={[styles.valBlock].join(' ')}>
                        <div className={[styles.inpCont, inputType === 1 ? styles.cursor : ""].join(' ')}>

                            { inputType === 0 &&
                                <input className={"form-control form-control-sm"} type={"text"} value={val} ref={inputRef}
                                       onChange={(e)=>setVal(e.target?.value)}
                                       onBlur={(e)=>handleCloseInput(e)}
                                       readOnly={!isEditable}
                                    //onDoubleClick={()=>setShowInput(isEditable)}
                                       inputMode={data?.type ==="numeric" ? data.type : "text"}

                                       onKeyDown={(e)=>{
                                           if(e.key?.match(/enter/ui)) {
                                               handleCloseInput(e)
                                               e.currentTarget.blur()
                                           }
                                       }}

                                />
                            }
                            { inputType === 1 &&
                                <input className={["form-check-input",styles.cursor].join(' ')} type={"checkbox"} checked={val} ref={inputRef}
                                       onChange={(e)=>
                                       {
                                           setVal(val ? false : true)
                                           handleCloseCheckBox(e)
                                       }}
                                       disabled={!isEditable}
                                />
                            }
                            { inputType === 2 &&
                                <textarea className={"form-control form-control-sm"}  value={val}
                                       onChange={(e)=>setVal(e.target?.value)}
                                       onBlur={(e)=>handleCloseInput(e)}
                                       readOnly={!isEditable}
                                          rows={data?.lineCount ? data?.lineCount: 3 }
                                    //onDoubleClick={()=>setShowInput(isEditable)}
                                       inputMode={data?.type ==="numeric" ? data.type : "text"}

                                       onKeyDown={(e)=>{
                                           if(e.key?.match(/enter/ui)) {
                                               handleCloseInput(e)
                                               e.currentTarget.blur()
                                           }
                                       }}

                                />
                            }



                        </div>
                        {buildModeList()}

                    </div>
                    {showHint && <div className={styles.hint}>{data?.info}</div>}
                    {!isCorrect && <div className={styles.valInc}>Не корректное значение</div>}
                </div>
            </div>
        </div>
    )
}