import styles from './ReviewModule.module.css'
import ActiveRecordItem from "../../FormTemplate/ActiveRecordItem/ActiveRecordItem";
import React, {useEffect, useRef, useState} from "react";
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import TableItem from "../../FormTemplate/TableItem/TableItem";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import WaitLoader from "../../WaitLoader/WaitLoader";

interface ReviewUpdateInt
{
    id:number
    fieldList:string[]
    valList:any[]
}

export default function ReviewModule(){

    const errorLineRef = useRef<ErrorLineItemRef>(null)


    const [reply,setReply] = useState("")

    const [reviewRecord, setReviewRecord] = useState<any>()

    const review = useRef()
    const [ldrReviewList,rsReview,exReview] = useApiQuery("/tariff/review/list/all","get",review);

    const updateReview = useRef<ReviewUpdateInt>()
    const [ldrUpdateReview,rsUpdateReview,exUpdateReview] = useApiQuery("/tariff/review/update","post",updateReview);

    const removeReview = useRef({
        topicId:0
    })
    const [ldrRemoveReview,rsRemoveReview,exRemoveReview] = useApiQuery("/tariff/review/remove","post",removeReview);

    const replyReview = useRef({
        topicId:0,
        author:"",
        text:""
    })
    const [ldrReplyReview,rsReplyReview,exReplyReview] = useApiQuery("/tariff/review/reply","post",replyReview);


    const [reviewData, setReviewData] = useState<any[]>([])

    useEffect(()=>{
        loadReviewData()
    },[review])

    useEffect(()=>{
        if(reviewRecord?.replyList?.length>0) setReply(reviewRecord?.replyList[0]?.text)
        else setReply("")
    },[reviewRecord])


    const  loadReviewData = ()=>{
        exReview().then((e)=>{
            if(e?.success)
            {
                setReviewData(e?.payload)
                console.log(e?.payload)
            }

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка сервера",true,2000)
        })
    }

    const handleUpdate = ()=>{
        const id = reviewRecord?.id ? reviewRecord?.id : undefined
        if(id === undefined)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не выбран топик",true,2000)
            return
        }
        const body = {
            show: reviewRecord?.show ? true : false
        }
        updateReview.current = {
            fieldList: ['show'],
            valList: [body.show],
            id: id
        }

        exUpdateReview().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show("Топис обновлен", false,2000)
                loadReviewData()
                return
            }

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка сервера", true,2000)
        })



    }

    const handleRemove = () =>{
        const id = reviewRecord?.id ? reviewRecord?.id : undefined
        if(id === undefined)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не выбран топик",true,2000)
            return
        }

        removeReview.current.topicId = id
        exRemoveReview().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show("Топис удален", false,2000)
                loadReviewData()
                return
            }

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка сервера", true,2000)
        })


    }

    const handleReply = () =>{
        const id = reviewRecord?.id ? reviewRecord?.id : undefined
        if(id === undefined)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не выбран топик",true,2000)
            return
        }
        if(reply.length < 1)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не введен ответ",true,2000)
            return
        }
        replyReview.current = {
            topicId: id,
            author: "Sim-Unlim",
            text: reply
        }

        console.log(replyReview.current)
        exReplyReview().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show("Ответ добавлен", false,2000)
                loadReviewData()
                return
            }

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка сервера", true,2000)
        })


    }

    return(
        <div className={[styles.mainCont].join(' ')}>
            <MainFormTemplate title={"Тэги тарифов"} maxWidth={"100%"}>
                <ActiveRecordItem active={reviewRecord?.id ? true: false}></ActiveRecordItem>

                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={reviewRecord?.author}
                            mode={""}
                            title={"Автор"}
                            info={"Только число"}
                            onBlur={(e)=>setReviewRecord({...reviewRecord,author:e})}
                        />

                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={reviewRecord?.show}
                            mode={"e"}
                            title={"Отображать"}
                            info={"Только число"}
                            inputType={1}
                            onBlur={(e)=>setReviewRecord({...reviewRecord,show:e})}
                        />
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={reviewRecord?.reply}
                            mode={""}
                            title={"Ответ"}
                            info={"Только число"}
                            inputType={1}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={reviewRecord?.name}
                            mode={""}
                            title={"Тариф"}
                            info={"Только число"}
                        />

                    </div>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={reviewRecord?.eMail}
                            mode={""}
                            title={"Почта"}
                            info={"Только число"}
                        />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={reviewRecord?.text}
                            mode={""}
                            title={"Вопрос"}
                            inputType={2}
                            lineCount={7}
                            info={"Только число"}
                        />

                    </div>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={reply}
                            mode={"e"}
                            title={"Ответ"}
                            lineCount={7}
                            inputType={2}
                            info={"Только число"}
                            onBlur={(e)=>setReply(e)}


                        />
                    </div>
                </div>



                <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>

                <div className={"row justify-content-center py-2"}>
                    <div className={styles.btnCont}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleUpdate()}
                            >Обновить</button>

                        </div>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleReply()}
                            >Ответить</button>

                        </div>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleRemove()}
                            >Удалить</button>

                        </div>
                        <WaitLoader loading={ldrUpdateReview || ldrRemoveReview} api={rsUpdateReview} onlyLoading={true}>

                        </WaitLoader>

                    </div>
                </div>



            </MainFormTemplate>
            <WaitLoader loading={ldrReviewList} api={rsReview} onlyLoading={true}>
                <TableItem
                    onPageCount={20}
                    data={reviewData}
                    onReload={loadReviewData}
                    onRecord={(e)=>setReviewRecord(e)}
                    column={[
                        {
                            title: "Тариф",
                            mode: "sf",
                            type: 0,
                            fieldId: "name",
                            maxLen: 30

                        },
                        {
                            title: "Дата",
                            mode: "sf",
                            type: 4,
                            fieldId: "date",
                            maxLen: 30

                        },
                        {
                            title: "Автор",
                            mode: "sf",
                            type: 0,
                            fieldId: "author",
                            maxLen: 30

                        },
                        {
                            title: "Отображать",
                            mode: "sf",
                            type: 5,
                            fieldId: "show",

                        },
                        {
                            title: "Ответ",
                            mode: "sf",
                            type: 5,
                            fieldId: "reply",

                        },

                    ]}


                ></TableItem>
            </WaitLoader>


        </div>
    )
}