import styles from './SortListItem.module.css'
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";


export interface SortListItemRefInt
{
    getGroupList:()=>SortListItemLineInt[]
}

interface SortListItemBlockInt
{
    name:string
    field:string
    use:boolean
    idx?:number

}

export interface SortListItemLineInt
{
    name:string
    field:string
}
interface SortListItemPropsInt
{
    fieldList: SortListItemLineInt[]
}
const  SortListItem = forwardRef<SortListItemRefInt,SortListItemPropsInt>(({fieldList}:SortListItemPropsInt, ref)=>{

    useImperativeHandle(ref, () => ({
        getGroupList : getGroupList
    }));


    const [datalist, setDataList] = useState<SortListItemBlockInt[]>([])

    const setLineState = (id:number)=>{
        setDataList( datalist.map((item,idx)=>{
            if(idx === id)item.use = !item.use
            return item
        }))
    }

    const getGroupList = ()=>{
        const st:Array<SortListItemLineInt> = []
        return datalist.reduce((acc,item)=>{
            if( !item.use)
            acc.push( {
                name: item.name,
                field: item.field
            })
            return acc

        }, st)
    }


    const moveLine = (id:number, dir:number)=>{
        let newIdx = id+dir
        const newArr = structuredClone(datalist)
        if(newIdx < 0 ) newIdx = 0
        if(newIdx > newArr.length-1) newIdx = newArr.length -1
        console.log(`old ${id} new ${newIdx}`)
        const oldEl = structuredClone(newArr[id])
        const newEl = structuredClone(newArr[newIdx])


        newArr.splice(id,1,newEl)
        newArr.splice(newIdx,1,oldEl)

        console.log("dataList", newArr)

        setDataList(newArr)









    }



    const buildList = () =>{
        return datalist.map((item,idx)=>{
            return(
                <div key={idx} className={[styles.slItemCont, item.use ? "" : styles.itmOff].join(' ')}

                >
                    <div className={styles.itmTitle}
                         onClick={()=>setLineState(idx)}
                    >
                        {item.name}
                    </div>
                    <div className={styles.arrCont}>
                        <div className={styles.arrItm}
                            onClick={()=>moveLine(idx,-1)}
                        ><i className='bx bx-chevron-up'></i></div>
                        <div className={styles.arrItm}
                             onClick={()=>moveLine(idx,1)}
                        ><i className='bx bx-chevron-down'></i></div>
                    </div>
                </div>
            )
        })
    }

    useEffect(()=>{
        setDataList( fieldList.map((item,idx)=>{
            return {...item,idx:0,use: true}
        }))
    },[fieldList])

    return (
        <div className={"d-flex mb-1"}>
            <div className={styles.slCont}>
                { true && <div className={"d-flex mb-1"}>Группировка</div>}
                {buildList()}
            </div>

        </div>
    )
})

export default SortListItem