import {useContext, useEffect, useRef, useState} from "react";
import useApiQuery from "../ApiQuery/UseApiQuery";
import WaitLoader from "../WaitLoader/WaitLoader";
import ModalWindow from "../ModalWindow/ModalWindow";
import styles from './RegionChange.module.css'
import {useDispatch} from "react-redux";
import {setBaseRegion} from "../Reducer/GlobalReducer";
import {useLocation, useNavigate} from "react-router-dom";
import {SliderContext} from "../Slider/Slider";
import {SecondMenuContext} from "../SecondMenu/SecondMenu";
interface macroListInt
{
    name:string;
    regionList:[]
    id: number
    macroRegionId: number
    regionId: number
}

export interface RegionChangePropsInt
{
    moveAfterPath?: string
}

export default function RegionChange()
{
    const [stage , setStage] = useState(0)
    const location = useLocation();

    const data = useRef({})
    const [ldrData,rsDataItem,executeLoadData] = useApiQuery("/region/list","get",data);
    const [items, setItems] = useState([])

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedRegion, setSelectedRegion] = useState({
        macroRegionId:-1,
        regionId:-1,
        name:""
    })

    const finalMove= ()=>{
        dispatch(  setBaseRegion({...selectedRegion,isRegionChanged:true,makeSelectRegion:false}))
//        dispatch(setBaseRegion({makeSelectRegion:false}))

    }

    useEffect(() => {
        executeLoadData().then((e)=>{
            if(e?.success ) {
                setItems(e?.payload)
                //dispatch(setSliderMode(0))
            }
        })
    }, []);

    const buildMacroList = () => {
        return items.map((item:macroListInt,index)=>{
            return (<li key={item?.id} className={["list-group-item", styles.line].join(' ')}
            onClick={(e)=>{
                e.preventDefault()
                setSelectedRegion({...selectedRegion,macroRegionId: item?.macroRegionId})
                setStage(1)
            }}
            >{item?.name}</li>)
        })
    }


    const buildRegionList = () =>{
        console.log("macro", selectedRegion)
        const macro = items.reduce((acc,item:macroListInt)=>{
            if(item.macroRegionId === selectedRegion.macroRegionId) acc = item.regionList
            return acc;
        },[])
        console.log(macro)
        if(macro.length === 0)setStage(0)
        return macro.map((item:macroListInt,index)=>{
            return (<li key={item?.id} className={["list-group-item", styles.line].join(' ')}
                        onClick={()=>{
                            setSelectedRegion({...selectedRegion,regionId: item?.regionId,name: item?.name})
                            setStage(2)
                        }}
            >{item?.name}</li>)
        })
    }



    useEffect(()=>{
        //if(stage === 2)dispatch(setBaseRegion(selectedRegion))
        console.log("stage change",selectedRegion)
    },[stage])

    const onClose=()=>{
        dispatch(setBaseRegion({makeSelectRegion:false}))
    }
    const regionSelect = () =>{

        switch (stage)
        {
            case 0:
                return(
                    <div className={styles.modalContainer}>
                        <div className={styles.macroRegion}>
                            <ul className="list-group">
                                {buildMacroList()}
                            </ul>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className={styles.modalContainer}>
                        <div className={styles.macroRegion}>
                            <ul className="list-group">
                                {buildRegionList()}
                                {<li key="mKey" className={["list-group-item", styles.lineBack].join(' ')}
                                    onClick={()=>setStage(0)}
                                >Назад</li>}
                            </ul>
                        </div>
                    </div>
                )
            default:
//                dispatch(setBaseRegion({makeSelectRegion:false}))

                setTimeout(()=>{finalMove()},1000)
                return (
                    <div className={styles.modalContainer}>
                    <div className={styles.macroRegion}>
                        <div className={styles.modal}>
                            <div className={styles.modalContent}>
                                <div className={styles.finish}>Регион сменен</div>

                            </div>
                        </div>
                    </div>
                    </div>
                )

        }
    }

    return(

        <WaitLoader loading={ldrData} api={rsDataItem} onlyLoading={false}>
            <ModalWindow onClose={onClose}>
                {regionSelect()}
            </ModalWindow>
        </WaitLoader>
    )
}