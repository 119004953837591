import styles from './RefTarifTag.module.css'
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import ActiveRecordItem from "../../FormTemplate/ActiveRecordItem/ActiveRecordItem";
import React, {useEffect, useRef, useState} from "react";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import TableItem from "../../FormTemplate/TableItem/TableItem";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";

interface TarifTagGlobalUpdateInt {
    fieldList: string[],
    valList: any[]
    id:number
}

interface TarifTagCreateInt
{
    tagId:string
    tagName: string
    val:string
    desc: string
}
export default function RefTarifTag(){
    const [tagList, setTagList] = useState<any>([])
    const [tagRecord, setTagRecord] = useState<any>()
    const errorLineRef = useRef<ErrorLineItemRef>(null)

    const updateTag = useRef<TarifTagGlobalUpdateInt>()
    const [ldrUpdateTag,rsUpdateTag,exUpdateTag] = useApiQuery("/ref/tariff/tag/update","post",updateTag);

    const createTag = useRef<TarifTagCreateInt>()
    const [ldrCreateTag,rsCreateTag,exCreateTag] = useApiQuery("/ref/tariff/tag/create","post",createTag);


    const removeTag = useRef({
        tagId:""
    })
    const [ldrRemoveTag,rsRemoveTag,exRemoveTag] = useApiQuery("/ref/tariff/tag/remove","post",removeTag);



    const tagListData = useRef()
    const [ldrTagData,rsTagData,exTagData] = useApiQuery("/ref/tariff/tag/full","get",tagListData);




    const loadTarifTag = ()=>{
        exTagData().then((e)=>{
            console.log(e)
            if(e?.success)
            {
                setTagList(e?.payload)
            }
        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })

    }

    useEffect(()=>{
        loadTarifTag()
    },[tagListData])

    useEffect(()=>{
        console.log("eff tagRecord",tagRecord)
    },[tagRecord])
    const handleUpdateTag = () =>{
        const id = tagRecord?.id ? tagRecord.id : undefined
        if(id === undefined)
        {
            if(errorLineRef.current )errorLineRef.current?.show("Не выбран тэг",true,2000)
            return
        }
        const body  = {
            tagId: tagRecord?.tagId ? tagRecord.tagId : undefined,
            desc: tagRecord?.desc ? tagRecord.desc : undefined,
            val: tagRecord?.val ? tagRecord.val : undefined,
            tagName: tagRecord?.tagName ? tagRecord.tagName : undefined
        }

        let good = true
        let kl = ""

        let fieldList = new Array<string>
        let valList = new Array<any>
        for(const [key,val] of Object.entries(body))
        {
            if(val === undefined)
            {
                good = false
                kl = key
                break
            }
            fieldList.push(key)
            valList.push(val)
        }

        if(!good && errorLineRef.current)errorLineRef.current.show(`Заполнены не все поля [${kl}]`,true, 2000)
        if(!good) return

        updateTag.current = {
            fieldList: fieldList,
            valList: valList,
            id: id
        }
        exUpdateTag().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Тэг ${body.tagId} обновлен`,false,3000)
                loadTarifTag()
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу обновить тэг",true,3000)

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })

    }

    const handleCreateTag = () =>{
        const body  = {
            tagId: tagRecord?.tagId ? tagRecord.tagId : undefined,
            desc: tagRecord?.desc ? tagRecord.desc : undefined,
            val: tagRecord?.val ? tagRecord.val : undefined,
            tagName: tagRecord?.tagName ? tagRecord.tagName : undefined,
        }

        let good = true
        let kl = ""

        let fieldList = new Array<string>
        let valList = new Array<any>
        for(const [key,val] of Object.entries(body))
        {
            if(val === undefined)
            {
                good = false
                kl = key
                break
            }
            fieldList.push(key)
            valList.push(val)
        }

        if(!good && errorLineRef.current)errorLineRef.current.show(`Заполнены не все поля [${kl}]`,true, 2000)
        if(!good) return
        createTag.current = body
        exCreateTag().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Тэг ${body.tagId} создан`,false,3000)
                loadTarifTag()
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу создать тэг",true,3000)

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })



    }
    const handleRemoveTag = () =>{
        const tagId = tagRecord?.tagId ? tagRecord.tagId : undefined
        if(tagId === undefined)
        {
            if(errorLineRef.current )errorLineRef.current?.show("Не выбран тэг",true,2000)
            return
        }
        removeTag.current.tagId = tagId
        exRemoveTag().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Тэг ${tagId} удален`,false,3000)
                loadTarifTag()
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу удалить тэг",true,3000)

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })


    }

    return(
        <div className={[styles.mainCont].join(' ')}>
            <MainFormTemplate title={"Тэги тарифов"} maxWidth={"100%"}>
                <ActiveRecordItem active={tagRecord?.id ? true: false}></ActiveRecordItem>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={tagRecord?.tagId}
                            mode={"e"}
                            title={"ID"}
                            info={"Только число"}
                            onBlur={(e)=>setTagRecord({...tagRecord,tagId:e})}
                            regExp={/^[a-z]+[a-z-0-9]+$/ui}/>

                    </div>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={tagRecord?.tagName}
                            mode={"e"}
                            title={"Тэг"}
                            info={"Только число"}
                            onBlur={(e)=>setTagRecord({...tagRecord,tagName:e})}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tagRecord?.desc}
                            mode={"e"}
                            inputType={2}
                            lineCount={10}
                            title={"Краткое описание"}
                            info={"Только число"}
                            onBlur={(e)=>setTagRecord({...tagRecord,desc:e})}
                        />

                    </div>
                    <div className={"col-sm-8"}>
                        <InputItem
                            inputType={2}
                            lineCount={10}
                            val={tagRecord?.val}
                            mode={"e"}
                            regExp={/^[а-я]+/ui}
                            title={"Текст"}
                            info={"Только число"}
                            onBlur={(e)=>setTagRecord({...tagRecord,val:e})}
                        />
                    </div>
                </div>
                <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>

                <div className={"row justify-content-center py-2"}>
                    <div className={styles.btnCont}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleCreateTag()}
                            >Создать тэг</button>

                        </div>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleUpdateTag()}
                            >{`Обновить [ ${tagRecord?.tagId ? tagRecord?.tagId: '-' } ]`}</button>

                        </div>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                    onClick={()=>handleRemoveTag()}
                            >{`Удалить [ ${tagRecord?.tagId ? tagRecord?.tagId: '-' } ]`}</button>

                        </div>
                        <WaitLoader loading={ldrUpdateTag} api={rsTagData} onlyLoading={true}>

                        </WaitLoader>

                    </div>
                </div>


            </MainFormTemplate>
            <WaitLoader loading={ldrTagData} api={rsTagData} onlyLoading={true}>
                    <TableItem
                        data={tagList}
                        onPageCount={20}
                        onReload={loadTarifTag}
                        onRecord={(e)=>setTagRecord(e)}
                        column={[
                            {
                                title: "id",
                                fieldId: "tagId",
                                maxLen:10,
                                mode: "sf",
                                type: 0
                            },
                            {
                                title: "Тэг",
                                fieldId: "tagName",
                                maxLen:30,
                                mode: "sf",
                                type: 0
                            },
                            {
                                title: "Описание",
                                fieldId: "desc",
                                maxLen:60,
                                mode: "sf",
                                type: 0
                            },

                        ]}
                    ></TableItem>

            </WaitLoader>
        </div>
    )
}