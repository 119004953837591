import styles from './LoadNewDkp.module.css'
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import React, {useRef, useState} from "react";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import WaitLoader from "../../WaitLoader/WaitLoader";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import UseLongTask, {LongTaskItemCbInt} from "../../LongTaskItem/UseLongTask";
import useLongTask from "../../LongTaskItem/UseLongTask";
export default function LoadNewDkp()
{

    const [fileSelected, setFileSelected] = useState(false)
    const data = useRef(new FormData())
    const [ldrXlsFile,rsXlsFile,exXlsFile] = useApiQuery("/excel/upload","file",data);

    const [taskData, setTaskData] = useState<any>({})

    const newDkp = useRef({
        filePath:"",
        validate: false,
        update: false
    })
    const [ldrNewDkp,rsNewDkp,exNewDkp] = useApiQuery("/pd/load/new","post",newDkp);

    const [ldrNewDkpTask, exNewDkpTask] = useLongTask(100,5000, (state, data, code,cnt)=>onNewDkpTaskTick(state, data,code,cnt),(state, data, code,cnt)=>onNewDkpTaskTick(state, data,code,cnt))

    const errorLineRef = useRef<ErrorLineItemRef>(null)
    const [validate , setValidate] = useState(false)
    const [update , setUpdate] = useState(false)


    const [mode,setMode] = useState(0)


    const handleFile = (e :React.ChangeEvent<HTMLInputElement>) =>{
        data.current.delete("file")
        if(! e.target.files)
        {
            setFileSelected(false)
            return
        }
        if(e.target.files.length > 0 )setFileSelected(true)
        else setFileSelected(false)
        data.current.append("file",e.target.files[0])
    }



    const onNewDkpTaskTick:LongTaskItemCbInt= (state,data,code,cnt) =>
    {
        console.log(`cb state: ${state} code ${code}`, data)
        setTaskData(data)
        if(state )
        {
            if(errorLineRef.current!== null)errorLineRef.current.show("Задача выполнена",false,0)
            setMode(2)
            return
        }

        if(!state && code === 1)
        {
            if(errorLineRef.current!== null)errorLineRef.current.show("Истекло время задачи",true,0)
            setMode(2)
            return;

        }
    }
    const handleLoad = () =>{
        if(!fileSelected)
        {
            if(errorLineRef.current !== null)errorLineRef.current.show("Файл не выбран",true,2000)
            return
        }
        exXlsFile().then((e)=>{
            if(e?.success !== undefined && e.success && e?.payload)
            {
                console.log("payload",e?.payload)
                newDkp.current.filePath = e?.payload
                newDkp.current.validate = validate
                newDkp.current.update = update

                exNewDkp().then((e)=>{
                    if(e?.success )
                    {
                        //checkTaskResult(e?.payload)

                        exNewDkpTask(e?.payload)

                        setMode(1)
                        return

                    }
                    if(errorLineRef.current !== null)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка выполнения задачи" ,true,2000)
                }).catch((e)=>{
                    console.log(e)
                    if(errorLineRef.current !== null)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка сервера" ,true,2000)
                })
                return

            }
            if(errorLineRef.current !== null)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка загрузки файла" ,true,2000)
        }).catch((e)=>{
            if(errorLineRef.current !== null)errorLineRef.current.show(e?.errorAsString ? e?.errorAsString : "Ошибка сервера" ,true,2000)
        })



    }


    return(
        <div className={styles.mainCont} >
            <MainFormTemplate title={"Загрузить новые ДКП"} maxWidth={"600px"}>
            <div className={"row mb-3"}>
                <div className={"cols-sm-12"}>
                    <input type="file" className="form-control" accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                           onChange={(e)=>handleFile(e)}
                    >
                    </input>
                </div>

            </div>
            <div className={"row mb-3"}>
                <div className={"col-sm-12"}>
                    <InputItem
                        val={validate}
                        mode={"ei"}
                        inputType={1}
                        info={"При добавление, проверить на валидность"}
                        title={"Проверить на валидность"}
                        //startEdit={true}
                        onBlur={(e)=>
                        {
                            setValidate(e)
                        }}
                        //onUpload={(e)=>handleUpRemains(e)}
                    />

                </div>
            </div>
                <div className={"row mb-3"}>
                    <div className={"col-sm-12"}>
                        <InputItem
                            val={update}
                            mode={"ei"}
                            inputType={1}
                            info={"Данные обновятся, только обновление"}
                            title={"Обновить данные"}
                            //startEdit={true}
                            onBlur={(e)=>
                            {
                                setUpdate(e)
                            }}
                            //onUpload={(e)=>handleUpRemains(e)}
                        />

                    </div>
                </div>

            {mode > 0 &&
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <div className={styles.dtCont}>
                            <div>Всего обработано :<span>{taskData?.recTotal}</span></div>
                            <div>Корректные :<span>{taskData?.recRight}</span></div>
                            <div>Добавлено :<span>{taskData?.recAdded}</span></div>
                            <div>Валидных :<span>{taskData?.recValidate}</span></div>
                        </div>

                    </div>
                </div>

            }

            <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>

            <div className={"row"}>
                <div className={"col-sm-2 d-flex align-self-center"}>
                    <div className={styles.btnCont}>
                        <button type="button" className="btn btn-secondary "
                                onClick={()=>handleLoad()}
                                disabled={mode === 1}
                        >Загрузить</button>

                    </div>
                </div>
            </div>



            </MainFormTemplate>
        </div>
    )
}