import {useState} from "react";

export interface FormValidatorInt
{
    id: string;
    val:string;
    isCheck: boolean;
    valid: boolean;
    regExp:RegExp;
    errText:string;
    normalize?:FormValidateValue
    enabled?:boolean

}

export interface FormValidateValidate
{
    ():boolean
}

export interface FormValidateValue
{
    (id:string):string
}

export interface FormValidateValueById
{
    (id:string):boolean
}

export interface FormValidateGetItemInt
{
    (id:string):FormValidatorInt[]
}

export interface FormValidateSetValue
{
    (id:string, val:string):void
}


const useFormValidator=(data:FormValidatorInt[]):[validate:FormValidateValidate, validateById:FormValidateValueById,getValue:FormValidateValue, setValue:FormValidateSetValue,getErrText:FormValidateValue,getItem:FormValidateGetItemInt]=>{

    const [formData, setFormData] = useState<FormValidatorInt[]>(data)
    function validate()
    {
        let valid = true;
        setFormData( data.map((item)=>{
            if(item?.isCheck)
            {
                try {
                    //console.log(`item ${item.val} reg ${item.regExp} pl: ${item.val.match(item.regExp)}` )
                    if(! item?.val?.match(item.regExp)){valid = false;item.valid = false;}
                    else item.valid = true;

                } catch (e){ valid = false; console.log(e)}
            }
            return item;

        }))
        return valid
    }

    function findById(id:string)
    {
        const def:FormValidatorInt[] =[]
        return data.reduce((acc, item)=>{
            if(item?.id === id) acc.push(item)
            return acc;
        },def)
    }


    function getItem(id:string)
    {
        console.log(`find ${id}`, findById(id))
        return  findById(id)
    }

    function getValue(id:string)
    {
        const item = findById(id)
        if(id?.length ===0 ) return ""
        return item[0]?.val
    }

    function setValue(id:string, val:string)
    {
        setFormData(
            formData.map((item)=>{
                if(item?.id === id)
                {
                    if(item?.normalize) item.val = item.normalize( val)
                    else item.val = val

                }
                return item
            })
        )
    }




    function validateById(id:string)
    {
        let valid = true
        setFormData(formData.map((item)=>{
            if(item?.isCheck && item?.id === id)
            {
                if(! item?.val?.match(item.regExp)){valid = false;item.valid = false;}
                else item.valid = true
            }

            return item
        }))
        return valid
    }

    function getErrText(id:string)
    {
        const item = findById(id)
        //console.log("err :",item[0])
        if(id.length ===0 || item[0]?.valid) return ""
        return item[0]?.errText
    }


    return [validate, validateById,getValue,setValue,getErrText,getItem]

}
export default useFormValidator