import styles from './BaseTarifBuilder.module.css'
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import React, {useEffect, useRef, useState} from "react";
import WaitLoader from "../../WaitLoader/WaitLoader";
import TableItem from "../../FormTemplate/TableItem/TableItem";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import ActiveRecordItem from "../../FormTemplate/ActiveRecordItem/ActiveRecordItem";
import SelectUnit, {SelectUnitDataInt, SelectUnitRefInt} from "../../FormTemplate/SelectUnit/SelectUnit";
import TarifTagBuilder from "./TarifTagBuilder";
import TarifPacketBuilder from "./TarifPacketBuilder";



interface CreateContentItemInt
{
    regionId:number,
    val:number
}
interface CreateContentTarifInt
{
    tfId: string
    apAp:CreateContentItemInt[],
    apPacket:CreateContentItemInt[],
    apNet:CreateContentItemInt[]
}

interface UpdateTarifGlobalInt
{
    id:number
    fieldList: string[]
    valList: any[]
}

interface CreateBaseTarifInt
{
    tfId: string;
    regionList: string;//SIB;SPB
    operId: string;
    name: string;
    enabled: boolean;
    smsCount: number;
    type: number;
    price: number;
    optList: string;
    snList: string;
    descFile?: string;
    useMNP: boolean;
    tagBundle: string;
    regMode: number;
    categoryId: number;
    bundleId: string;

}

export default function BaseTarifBuilder()
{

    const [formRefData, setFormRefData] = useState({
        oper: "",
        bundle:"",
        category:"",
        type:""

    })

    const errorLineRef = useRef<ErrorLineItemRef>(null)

    const bundleListRef = useRef<SelectUnitRefInt>(null)
    const operListRef = useRef<SelectUnitRefInt>(null)


    const updateBaseTarif = useRef<UpdateTarifGlobalInt>()
    const [ldrBaseTarifUpdate,rsBaseTarifUpdate,exBaseTarifUpdate] = useApiQuery("/tariff/update/base","post",updateBaseTarif);

    const removeTariff = useRef({
        tfId:""
    })
    const [ldrTarifRemove,rsTarifRemove,exTarifRemove] = useApiQuery("/tariff/remove","post",removeTariff);


    const tarifCreateContent = useRef<CreateContentTarifInt>()
    const [ldrTarifContent,rsTarifCreateContent,exTarifCreateContent] = useApiQuery("/tariff/create/content","post",tarifCreateContent);


    const tarifCreateBase = useRef<CreateBaseTarifInt>()
    const [ldrTarifCreateBase,rsTarifCreateBase,exTarifCreateBase] = useApiQuery("/tariff/create/base","post",tarifCreateBase);



    const [cpAp, setCpAp] = useState<CreateContentItemInt[]>([])
    const [cpNet, setCpNet] = useState<CreateContentItemInt[]>([])
    const [cpPacket, setCpPacket] = useState<CreateContentItemInt[]>([])


    const tarifContentList = useRef({
        tariffId:""
    })
    const [ldrTarifContentList,rsTarifContent,exTarifContent] = useApiQuery("/tariff/content","post",tarifContentList);
    //const [tarifContentListData, setTarifContentData] = useState<any[]>([])
    const [apAp, setApAp] = useState<any[]>([])
    const [apNet, setApNet] = useState<any[]>([])
    const [apPacket, setApPacket] = useState<any[]>([])




    const tarifTypeList = useRef()
    const [ldrTarifTypeList,rsTarifType,exTarifType] = useApiQuery("/ref/tariff/type","get",tarifTypeList);
    const [tarifTypeListData, setTarifTypeListData] = useState<SelectUnitDataInt[]>([])


    const regionAllList = useRef()
    const [ldrRegionAll,rsRegionAll,exRegionAll] = useApiQuery("/ref/macro/all","get",tarifTypeList);
    const [regionAllListData, setRegionAllListData] = useState<any[]>([])


    const tarifOptionList = useRef()
    const [ldrOptionList,rsOptionList,exOptionList] = useApiQuery("/ref/tariff/option","get",tarifOptionList);
    const [tarifOptionListData, setTarifOptionListData] = useState<SelectUnitDataInt[]>([])


    const simCategoryList = useRef()
    const [ldrSimCategoryList,rsSimCategoryList,exSimCategoryList] = useApiQuery("/ref/sim/category","get",simCategoryList);
    const [simCategoryListData, setSimCategoryListData] = useState<SelectUnitDataInt[]>([])


    const tarifSocialList = useRef()
    const [ldrSocialList,rsSocialList,exSocialList] = useApiQuery("/ref/tariff/social","get",tarifSocialList);
    const [tarifSocialListData, setTarifSocialListData] = useState<SelectUnitDataInt[]>([])



    const operList = useRef()
    const [ldrOperList,rsOperList,exOperList] = useApiQuery("/ref/oper","get",operList);
    const [operListData, setOperListData] = useState<SelectUnitDataInt[]>([])

    const macroList = useRef()
    const [ldrMacroList,rsMacroList,exMacroList] = useApiQuery("/ref/region/macro","get",macroList);
    const [macroListData, setMacroListData] = useState<SelectUnitDataInt[]>([])


    const bundleList = useRef()
    const [ldrBundleList,rsBundleList,exBundleList] = useApiQuery("/ref/tariff/bundle","post",bundleList);
    const [bundleListData, setBundleListData] = useState<SelectUnitDataInt[]>([])
    const [bundleListHotData, setBundleListHotData] = useState<any[]>([])


    const tarifList = useRef()
    const [ldrTarifList,rsTarifList,exTarifList] = useApiQuery("/tariff/ref/list","get",tarifList);
    const [tariffListData , setTarifListData] = useState<any[]>([])
    const [tarifListRecord, setTarifListRecord] = useState<any>({})

    const [tarifDefListRecord, setTarifDefListRecord] = useState<any>()



    useEffect(()=>{
        let  newFormData = formRefData

        newFormData =  {...newFormData, type: findItemByVal(tarifTypeListData,tarifListRecord?.type)}
        newFormData =  {...newFormData, oper: findItemByVal(operListData,tarifListRecord?.operId)}

        newFormData =  {...newFormData, category: findItemByVal(simCategoryListData,tarifListRecord?.categoryId)}
        if(! tarifListRecord?.operId)newFormData =  {...newFormData, bundle: ""}


        setFormRefData(newFormData)
        if(tarifListRecord?.operId)filterBundleList(tarifListRecord?.operId)

    },[tarifListRecord])



    useEffect(()=>{
        loadTarifList()
        loadOperList()
        loadBundleList()
        loadMacroList()
        loadTarifOptionList()
        loadTarifSocialList()
        loadSimCategoryList()
        loadTarifTypeList()
        loadRegionAllList()
    },[tarifList])




    const loadTarifContent = (tarifId:string) =>{
        if(! tarifId) return
        tarifContentList.current.tariffId = tarifId
        exTarifContent().then((e)=>{
            if(e?.success )
            {
                console.log("t content", e?.payload)
                if(e?.payload?.apAp) setApAp(e?.payload?.apAp)
                if(e?.payload?.apNet) setApNet(e?.payload?.apNet)
                if(e?.payload?.apPacket) setApPacket(e?.payload?.apPacket)
            }
        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })


    }

    const loadRegionAllList = () =>{
        exRegionAll().then((e)=>{
            //console.log("region all data", e?.payload)
            if(e?.success )
            {
                setRegionAllListData(e?.payload)
                return
            }
        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })

    }

    const loadTarifList = () =>{
        exTarifList().then((e)=>{
            if(e?.success )
            {
                setTarifListData(e?.payload)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })


    }
    const loadOperList = () =>{
        exOperList().then((e)=>{
            if(e?.success )
            {
                setOperListData(e?.payload?.map((item:any)=>{
                    return {
                        name: item?.name,
                        val: item?.operId
                    }
                }))
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }
    const loadBundleList = () =>{
        exBundleList().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return item
                }))
                setBundleListHotData(aData)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }

    const loadMacroList = () =>{
        exMacroList().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return                    {
                        name: item?.name + ` [ ${item?.macroRegionId} ]`,
                        val: item?.macroRegionId

                    }
                }))
                setMacroListData(aData)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }
    const loadSimCategoryList = () =>{
        exSimCategoryList().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return                    {
                        name: item?.name,
                        val: item?.categoryId

                    }
                }))
                setSimCategoryListData(aData)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }

    const loadTarifOptionList = () =>{
        exOptionList().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return                    {
                        name: item?.desc + ` [ ${item?.optionId.toUpperCase()} ]`,
                        val: item?.optionId

                    }
                }))
                setTarifOptionListData(aData)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }
    const loadTarifSocialList = () =>{
        exSocialList().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return                    {
                        name: item?.desc,
                        val: item?.optionId

                    }
                }))
                setTarifSocialListData(aData)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }
    const loadTarifTypeList = () =>{
        exTarifType().then((e)=>{
            if(e?.success )
            {
                const aData = (e?.payload?.map((item:any)=>{
                    return                    {
                        name: item?.name,
                        val: item?.typeId

                    }
                }))
                setTarifTypeListData(aData)
                return
            }
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        }).catch((e)=>{
            if(errorLineRef.current )errorLineRef.current?.show(e?.errorAsString,true,0)
        })

    }

    const findItemByVal= (data:SelectUnitDataInt[], val:any)=>
    {
        if(val === undefined || val === null) return ""
        //return ""
        const vl = typeof val === "string" ? val.trim() : val.toString()
        console.log(data,val)
        return data?.reduce((acc, item)=>{
            if(item?.val == vl)acc = item?.name
            return acc
        },"")
    }



    const filterBundleList = (operId:string)=>{
        const filter = (bundleListHotData.filter((item)=>{
            if(item?.operId === operId) return true
            return false
        }))

        setBundleListData(filter?.map((item)=>{
            return {
                name: item?.desc,
                val: item?.bundleId
            }
        }))
    }


    const handleRecord = (data:any)=>{
        setTarifListRecord(data)
        console.log("record",data)

        let newFormData = formRefData
        if(data?.bundleId)
        {
            //filterBundleList(data.operId)
            newFormData = {...newFormData,bundle: bundleListHotData.reduce((acc,item)=>{
                if(item?.operId === data?.operId && item?.bundleId === data?.bundleId)acc = item?.desc
                return acc
                },"")}
        }
        setFormRefData(newFormData)
        loadTarifContent(data?.tfId)
    }

    const handleCreateContent = ()=>{

        const tfId = tarifListRecord?.tfId ? tarifListRecord?.tfId : undefined
        if(tfId === undefined)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не указан (не выбран) tfId",true,2000)
            return

        }

        let goodAp = cpAp.reduce((acc,item)=>{
            if(item.regionId === 0) acc = true
            return acc
        },true)
        if(! goodAp)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не указан АП по дефолту",true,2000)
            return
        }

        goodAp = cpNet.reduce((acc,item)=>{
            if(item.regionId === 0) acc = true
            return acc
        },true)
        if(! goodAp)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не указан NET по дефолту",true,2000)
            return
        }
        goodAp = cpPacket.reduce((acc,item)=>{
            if(item.regionId === 0) acc = true
            return acc
        },true)
        if(! goodAp)
        {
            if(errorLineRef.current)errorLineRef.current.show("Не указан PACKET по дефолту",true,2000)
            return
        }

        const data:CreateContentTarifInt =
            {
                tfId: tfId,
                apAp: cpAp,
                apNet: cpNet,
                apPacket: cpPacket
            }
        tarifCreateContent.current = data
        exTarifCreateContent().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Пакеты тарифа ${data.tfId} обновлены`,false,3000)
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу создать пакеты тарифа",true,3000)
        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })


        console.log(data)



    }

    const handleCreateBase = () =>{
        console.log(tarifListRecord)
        const body:CreateBaseTarifInt =
            {
                tfId: tarifListRecord?.tfId !== undefined ? tarifListRecord?.tfId : undefined ,
                name:tarifListRecord?.tfId !== undefined ?  tarifListRecord?.name : undefined,
                bundleId:tarifListRecord?.bundleId !== undefined ?  tarifListRecord?.bundleId : undefined,
                categoryId:tarifListRecord?.categoryId !== undefined ?  tarifListRecord?.categoryId : undefined,
                enabled:tarifListRecord?.enabled !== undefined ?  tarifListRecord?.enabled : undefined,
                operId:tarifListRecord?.operId !== undefined ?  tarifListRecord?.operId : undefined,
                optList:tarifListRecord?.optList !== undefined ?  tarifListRecord?.optList : undefined,
                price:tarifListRecord?.price !== undefined ?  tarifListRecord?.price : undefined,
                regMode:tarifListRecord?.regMode !== undefined ?  tarifListRecord?.regMode : undefined,
                regionList:tarifListRecord?.regionIdList !== undefined ?  tarifListRecord?.regionIdList : undefined,
                smsCount:tarifListRecord?.smsCount !== undefined ?  tarifListRecord?.smsCount : undefined,
                snList:tarifListRecord?.snList !== undefined ?  tarifListRecord?.snList : undefined,
                tagBundle:tarifListRecord?.tagBundle !== undefined ?  tarifListRecord?.tagBundle : undefined,
                type:tarifListRecord?.type !== undefined ?  tarifListRecord?.type : undefined,
                useMNP:tarifListRecord?.useMNP !== undefined ?  tarifListRecord?.useMNP : undefined,
            }
        let good = true
        let kl = ""
        for(const [key,val] of Object.entries(body))
        {
            if(val === undefined)
            {
                good = false
                kl = key
                break
            }
        }

        if(!good && errorLineRef.current)errorLineRef.current.show(`Заполнены не все поля [${kl}]`,true, 2000)
        if(!good) return

        tarifCreateBase.current = body

        exTarifCreateBase().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Тариф ${body.tfId} создан`,false,3000)
                loadTarifList()
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу создать тариф",true,3000)
        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })






    }

    const handleUpdateBase = () => {
        console.log(tarifListRecord)
        const id = tarifListRecord?.id ? tarifListRecord?.id : undefined
        if (id === undefined) {

            if(errorLineRef.current)errorLineRef.current.show("Не выбран тариф",true,3000)
            return;
        }
        const body =
            {
                tfId: tarifListRecord?.tfId !== undefined ? tarifListRecord?.tfId : undefined ,
                name:tarifListRecord?.tfId !== undefined ?  tarifListRecord?.name : undefined,
                bundleId:tarifListRecord?.bundleId !== undefined ?  tarifListRecord?.bundleId : undefined,
                categoryId:tarifListRecord?.categoryId !== undefined ?  tarifListRecord?.categoryId : undefined,
                enabled:tarifListRecord?.enabled !== undefined ?  tarifListRecord?.enabled : undefined,
                operId:tarifListRecord?.operId !== undefined ?  tarifListRecord?.operId : undefined,
                optList:tarifListRecord?.optList !== undefined ?  tarifListRecord?.optList : undefined,
                price:tarifListRecord?.price !== undefined ?  tarifListRecord?.price : undefined,
                regMode:tarifListRecord?.regMode !== undefined ?  tarifListRecord?.regMode : undefined,
                regionIdList:tarifListRecord?.regionIdList !== undefined ?  tarifListRecord?.regionIdList : undefined,
                smsCount:tarifListRecord?.smsCount !== undefined ?  tarifListRecord?.smsCount : undefined,
                snList:tarifListRecord?.snList !== undefined ?  tarifListRecord?.snList : undefined,
                tagBundle:tarifListRecord?.tagBundle !== undefined ?  tarifListRecord?.tagBundle : undefined,
                type:tarifListRecord?.type !== undefined ?  tarifListRecord?.type : undefined,
                useMNP:tarifListRecord?.useMNP !== undefined ?  tarifListRecord?.useMNP : undefined,
            }
        let good = true
        let kl = ""

        let fieldList = new Array<string>
        let valList = new Array<any>
        for(const [key,val] of Object.entries(body))
        {
            if(val === undefined)
            {
                good = false
                kl = key
                break
            }
            fieldList.push(key)
            valList.push(val)
        }

        if(!good && errorLineRef.current)errorLineRef.current.show(`Заполнены не все поля [${kl}]`,true, 2000)
        if(!good) return

        console.log(fieldList, valList,id)

        updateBaseTarif.current =
            {
                fieldList: fieldList,
                valList: valList,
                id:id
            }
        exBaseTarifUpdate().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Тариф ${body.tfId} обновлен`,false,3000)
                loadTarifList()
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу обновить тариф",true,3000)

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })

    }

    const handleRemoveTariff = () =>{
        const tfId = tarifListRecord?.tfId ? tarifListRecord?.tfId : undefined
        if(tfId === undefined ) {
            if(errorLineRef.current)errorLineRef.current.show("Не выбран тариф",true,3000)
            return
        }

        if(removeTariff.current)removeTariff.current.tfId = tfId

        exTarifRemove().then((e)=>{
            if(e?.success)
            {
                if(errorLineRef.current)errorLineRef.current.show(`Тариф ${tfId} удален`,false,3000)
                loadTarifList()
                return
            }
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Не могу удалить тариф",true,3000)

        }).catch((e)=>{
            if(errorLineRef.current)errorLineRef.current.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,3000)
        })





    }
    const ldr = ldrTarifList || ldrBundleList || ldrTarifContent || ldrMacroList || ldrOperList || ldrTarifTypeList || ldrTarifCreateBase || ldrBundleList ||
         ldrOptionList || ldrBaseTarifUpdate || ldrTarifRemove

    return(
        <div className={styles.mainCont} >
            <MainFormTemplate title={"Настройки тарифов"} maxWidth={"100%"}>
                <ActiveRecordItem active={tarifListRecord?.id ? true : false}></ActiveRecordItem>
                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.name}
                            mode={"e"}
                            title={"Наименование"}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, name: e})}


                        ></InputItem>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.tfId}
                            mode={"ei"}
                            info={"[a-z0-9]{4,}"}
                            title={"Идентификатор"}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, tfId: e})}


                        ></InputItem>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.regMode}
                            mode={"e"}
                            title={"Схема продаж"}
                            regExp={/[0-9]/ui}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, regMode: Number(e)})}


                        ></InputItem>
                    </div>


                </div>
                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.smsCount}
                            mode={"e"}
                            title={"Кол-во SMS"}
                            regExp={/(^[1-9][0-9]{1,4}$)|(^0$)/ui}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, smsCount: Number(e)})}


                        ></InputItem>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.useMNP ? true : false}
                            mode={"e"}
                            title={"Возможность MNP"}
                            inputType={1}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, useMNP: !!e})}

                        ></InputItem>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.enabled}
                            mode={"e"}
                            title={"Активен"}
                            inputType={1}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, enabled: !!e})}

                        ></InputItem>
                    </div>



                </div>
                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={formRefData.oper}
                            mode={""}
                            title={"Оператор"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit ref={operListRef} inData={operListData} capt={"Оператор"} getVal={(e)=>{
                                filterBundleList(e[0])
                                setFormRefData({...formRefData,bundle: ""})
                                setTarifListRecord({...tarifListRecord,operId:e[0],bundleId:""})
                            }}  />
                        </div>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={formRefData.bundle}
                            mode={""}
                            info={"[a-z0-9]{4,}"}
                            title={"Набор тарифов"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit ref={bundleListRef} inData={bundleListData} capt={"Набор тарифов"} enabled={bundleListData.length >0} getVal={(e)=>{
                                setFormRefData({...formRefData,bundle: findItemByVal(bundleListData,e[0])})
                                setTarifListRecord({...tarifListRecord,bundleId:e[0]})

                            }}  />
                        </div>

                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.price}
                            mode={"e"}
                            title={"Цена"}
                            regExp={/(^[1-9][0-9]{0,4}$)|^0$/ui}
                            onBlur={(e)=> setTarifListRecord({...tarifListRecord, price: Number(e)})}


                        ></InputItem>
                    </div>


                </div>
                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.regionIdList}
                            mode={""}
                            title={"Регионы"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit ref={operListRef} inData={macroListData} capt={"Регионы"} multiSelect={true} getVal={(e)=>{
                                if(macroListData.length === e.length)
                                {
                                    setTarifListRecord({...tarifListRecord,regionIdList:"ALL;"})
                                    return
                                }
                                setTarifListRecord({...tarifListRecord,regionIdList: e.length ? e.join(';')+";" : "ALL;"})


                            }}/>
                        </div>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.optList}
                            mode={""}
                            title={"Опции тарифа"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit inData={tarifOptionListData} capt={"Опции"} multiSelect={true} getVal={(e)=>{
                                setTarifListRecord({...tarifListRecord,optList: e.join(';')+";"})


                            }}/>
                        </div>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={tarifListRecord?.snList}
                            mode={""}
                            title={"Безлимиты тарифа"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit inData={tarifSocialListData} capt={"Опции"} multiSelect={true} getVal={(e)=>{
                                setTarifListRecord({...tarifListRecord,snList: e.join(';')+";"})


                            }}/>
                        </div>
                    </div>


                </div>
                <div className={"row"}>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={formRefData.category}
                            mode={""}
                            title={"Категория sim"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit  inData={simCategoryListData} capt={"Категория"} getVal={(e)=>{
                                setTarifListRecord({...tarifListRecord,categoryId:Number(e[0])})
                            }}  />
                        </div>
                    </div>
                    <div className={"col-sm-4"}>
                        <InputItem
                            val={formRefData.type}
                            mode={""}
                            title={"Тип тарифа"}

                        ></InputItem>
                        <div className={styles.suCont}>
                            <SelectUnit  inData={tarifTypeListData} capt={"Тип тарифа"} getVal={(e)=>{
                                setTarifListRecord({...tarifListRecord,type:Number(e[0])})
                            }}  />
                        </div>
                    </div>



                </div>
                <div className={styles.rowBlock}>
                    <div className={["row"].join(' ')}>
                        <div className={"col-sm-4 "}>
                            <InputItem
                                val={tarifListRecord?.tagBundle}
                                mode={""}
                                title={"Тэги тарифа"}

                            ></InputItem>
                        </div>
                        <div className={"col-sm-8 "}>
                            <div className={styles.suCont}>
                                <TarifTagBuilder onUpload={(e)=>setTarifListRecord({...tarifListRecord,tagBundle: e.join(';')+";"})}></TarifTagBuilder>
                            </div>

                        </div>


                    </div>

                </div>
                <div className={styles.rowBlock}>
                    <div className={["row"].join(' ')}>
                        <div className={"col-sm-4 "}>
                            <TarifPacketBuilder
                                name={"Абонетская плата"}
                                data={apAp}
                                regionList={regionAllListData}
                                onChange={(e)=>{
                                    setCpAp(e)
                                }}
                            />
                        </div>
                        <div className={"col-sm-4 "}>
                            <TarifPacketBuilder
                                name={"Пакет интернета (Гб.)"}
                                data={apNet}
                                regionList={regionAllListData}
                                onChange={(e)=>setCpNet(e)}
                            />

                        </div>
                        <div className={"col-sm-4 "}>
                            <TarifPacketBuilder
                                name={"Пакет минут"}
                                data={apPacket}
                                regionList={regionAllListData}
                                onChange={(e)=>setCpPacket(e)}

                            />

                        </div>
                    </div>

                    <div className={"row justify-content-center py-2"}>
                        <div className={"col-sm-2 d-flex justify-content-center"}>
                            <div className={styles.btnCont}>
                                <button type="button" className="btn btn-secondary "
                                    onClick={()=>handleCreateContent()}
                                >{`Обновить контент [ ${tarifListRecord?.tfId ? tarifListRecord?.tfId : '-' } ]`}</button>

                            </div>
                        </div>
                    </div>



                </div>


                <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>
                <div className={"row justify-content-center py-2 my-2"}>
                    <WaitLoader loading={ldr} api={rsTarifList} onlyLoading={true}>
                    </WaitLoader>
                </div>

                <div className={"row justify-content-center py-2"}>
                    <div className={styles.btnCont}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleUpdateBase()}
                            >{`Обновить [ ${tarifListRecord?.tfId ? tarifListRecord?.tfId : '-' } ]`}</button>

                        </div>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleCreateBase()}
                            >Создать тариф</button>

                        </div>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                onClick={()=>handleRemoveTariff()}
                            >{`Удалить [ ${tarifListRecord?.tfId ? tarifListRecord?.tfId : '-' } ]`}</button>

                        </div>

                    </div>
                </div>
            </MainFormTemplate>


            <WaitLoader loading={ldrTarifList} onlyLoading={true} api={rsTarifList} >
                <TableItem
                    onPageCount={20}
                    onRecord={handleRecord}
                    onReload={loadTarifList}
                    data={tariffListData}
                    column={[
                        {
                            title: "Наименование",
                            fieldId: "name",
                            mode: "sf",
                            type:0
                        },
                        {
                            title: "Оператор",
                            fieldId: "operId",
                            mode: "sf",
                            type:0
                        },
                        {
                            title: "id",
                            fieldId: "tfId",
                            mode: "sf",
                            type:0
                        },
                        {
                            title: "Цена",
                            fieldId: "price",
                            mode: "sf",
                            type:1
                        },
                        {
                            title: "Активен",
                            fieldId: "enabled",
                            mode: "s",
                            type:5
                        },
                    ]}

                ></TableItem>
            </WaitLoader>

        </div>


    )
}