import React, {ReactNode} from "react";
import styles from './WaitLoader.module.css'
import loader from '../Img/loader.gif'
import {ApiQueryResponse} from "../ApiQuery/UseApiQuery";
import ModalWindow from "../ModalWindow/ModalWindow";
import {RiCloseLine} from "react-icons/ri";
import {useNavigate} from "react-router-dom";

interface WaitLoaderInt
{
    children: ReactNode| undefined
    loading: boolean;
    api: ApiQueryResponse
    onlyLoading:boolean
    showError?:boolean
}

export default function WaitLoader({children,loading,api,onlyLoading,showError}:WaitLoaderInt)
{
    const navigate = useNavigate()
    if(loading) return(
        <div className={styles.container}>
            <img className={styles.image} src={loader}></img>
        </div>
    );


    if(! api.success && !loading && api.code!==-200 && !onlyLoading)
        return(
            <ModalWindow onClose={()=>{navigate('/')}}>
                <div className={styles.modal}>
                    <div className={styles.modalHeader}>
                        <h5 className={styles.heading}>Ошибка</h5>
                    </div>
                    <div className={styles.modalContent}>
                        {api.errorAsString}
                    </div>
                </div>
            </ModalWindow>
        )
    if(showError && !api.success && api.code!==-200)
        return (
            <h3>{api.errorAsString}</h3>
        )


    return (
        <>
            {children}
        </>
    )
}