import styles from './ActiveRecordItem.module.css'
import React from "react";
interface ActiveRecordItemPropsInt
{
    active:boolean
}
export default function ActiveRecordItem({active}:ActiveRecordItemPropsInt)
{
    return(
        <div className={"row"}>
            <div className={styles.arCont}>Активная запись<div className={[active ? styles.arGood : styles.arBad, styles.arInd].join(' ')}></div></div>
        </div>
    )
}