import styles from './MainFormTemplate.module.css'
import {ReactNode} from "react";

interface MainFormTemplatePropsInt
{
    children: ReactNode| undefined
    maxWidth?:string
    title?: string
}
export default function MainFormTemplate({children,maxWidth,title}:MainFormTemplatePropsInt)
{
    return(
        <div className={styles.frCont} style={{maxWidth: maxWidth ? maxWidth: "100%"}}>
            <form>
                <div className={"row"}>
                    <div className={"col-12 mb-1"}>
                        <div>{title}</div>

                    </div>
                </div>
                {children}
            </form>
        </div>
    )
}