import styles from './Slider.module.css'
import PropTypes from "prop-types";
import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import useApiQuery, {ApiQueryResponse} from "../ApiQuery/UseApiQuery";
import SlideArrow from "./SlideArrow";
import SlideDotList from "./SlideDotList";
import SlideList from "./SlideList";
import useWindowSize from "../Hook/UseWindowSize";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../Reducer/GlobalReducer";
import TariffFilter from "../TarifFilter/TariffFilter";
import {Route, Routes, useLocation} from "react-router-dom";
import Lk from "../LK/LK";
import LoginForm from "../LoginForm/LoginForm";
import RegionChange from "../RegionChange/RegionChange";
import FullCard from "./FullCard";
import WaitLoader from "../WaitLoader/WaitLoader";
import {AppContext} from "../App";

interface SliderProps
{
    width:string;
    height: string;
    autoPlay:boolean;
    autoPlayTime:number;

}


export interface SliderContextInt
{
    goToSlide: (a:number)=>void
    changeSlide: (a:number)=>void
    slidesCount: number
    slideNumber: number
    items: { }[]

}
export const SliderContext = React.createContext<any | undefined>(undefined);
function Slider(props:SliderProps)
{
    const dispatch = useDispatch();

    const {regionId,macroRegionId} = useSelector((state:ReduxState)  => {return state.baseRegion} )
    const tfFilter = useSelector((state:ReduxState)  => {return state.tfFilter} )
    const containerRef = useRef<HTMLInputElement>(null)

    const mode = useSelector((state:ReduxState)  => {return state.slider.sliderMode} )

    //const [mode, setMode] = useState(0)

//    const [cardDataItem, setCardDataItem] = useState<any>(undefined)


    const data = useRef({
        regionId:regionId,
        macroRegionId: macroRegionId
    })
    const [ldrData,rsDataItem,executeLoadData] = useApiQuery("/tariff/list","post",data);


    const [itemList, setItemList] = useState<any[]>([]);
    const [baseData, setBaseDate] = useState<any[]>([])

    const [slide, setSlide] = useState(0);
    const [touchPosition, setTouchPosition] = useState(null)

    const [startData, setStartData] = useState(false)

    const [width, height] = useWindowSize();
    const [screenSlideCount,setScreenSlideCount] = useState(Number(getComputedStyle(document.documentElement).getPropertyValue('--slide-cnt')))


    useEffect(()=>{
        const slideCnt =Number(getComputedStyle(document.documentElement).getPropertyValue('--slide-cnt'))
        let cnt = slideCnt
        if(tfFilter.active && slideCnt >2) cnt = slideCnt -1
        setScreenSlideCount(cnt)

    },[width,tfFilter,itemList])



    useEffect(()=>{
        if(containerRef === null) return
        containerRef?.current?.scrollIntoView()
    },[itemList.length,tfFilter.appliedFilter,mode])


    const filterByOPerId = (val:any)=>{
        if(val === undefined) return false
        if(tfFilter.operId.length === 0) return true
        const flVal= tfFilter.operId.reduce((acc, item)=>{
            const pattern =new RegExp("("+item.toString()+")","ui")
            if(val.match(pattern))acc = true
            return acc;
        }, false)
        return flVal
    }


    const filterByTether = (val:any)=>{
        if(val === undefined) return false
        if(tfFilter.tether.length === 0) return true
        const flVal= tfFilter.tether.reduce((acc, item)=>{
            if(item === 1 && !val.match(/tt;/ui)) acc = true
            if(item === 0 && val.match(/tt;/ui)) acc = true
            return acc;
        }, false)
        return flVal
    }

    const filterBySimType = (val:any)=>{
        if(val === undefined) return false
        if(tfFilter.type.length === 0) return true
        const flVal= tfFilter.type.reduce((acc, item)=>{
            const fVal = Number(val)
            if(fVal === item ) acc = true
            return acc;
        }, false)
        return flVal

    }


    const filterData= ()=>{
        if(baseData?.length === 0)return
        const filteredData = baseData?.filter((item)=>{
            console.log("base item",item.operId)
            if(! filterByOPerId(item?.operId)) return false
            if(! filterBySimType(item?.type)) return false
            if(! filterByTether(item?.optList)) return false
            return true

        })
       // console.log("filtered data",filteredData)
        setItemList(filteredData)
        setSlide(0)

    }

    useEffect(()=>{
            filterData()
        },[tfFilter])



    //Загрузка по изменению data
    useEffect(() => {
        data.current.macroRegionId = macroRegionId
            data.current.regionId = regionId
        loadData().then();
    }, [data,macroRegionId,regionId]);

    //Загрузка
    async function loadData()
    {
        const lData = await executeLoadData()
        console.log("data form server ",lData.payload,data)
        if(lData.success )
        {
            setBaseDate(lData.payload)
            setItemList(lData.payload)
            setSlide(0)
            setStartData(true)
        }

    }
    // применяем фильтр когда загружены базовые данные
    useEffect(()=>{
        if(tfFilter.appliedFilter && baseData.length >0)filterData()
    },[baseData])

    const changeSlide = (direction = 1) => {
        //console.log(`slide ${slide} direction ${direction} screen ${screenSlideCount} len ${itemList.length}`)
        if(!itemList) return;
        let slideNumber = 0;
        let p =0
        if (slide + direction < 0) {
            slideNumber = itemList.length - screenSlideCount;
        } else {
            p = (itemList.length-screenSlideCount+1) >0 ? (itemList.length-screenSlideCount+1): 1
            slideNumber = ((slide + direction) % p);
        }
        //console.log(`set ${slideNumber} p ${p}`)
        setSlide(slideNumber);
    };

    const goToSlide = (number:number) => {
//        console.log(`move to slide ${number}`)
        if(!itemList) return;
        setSlide(number % itemList.length);

    };

    const handleTouchStart = (e:any) => {
        const touchDown = e.touches[0].clientX;

        setTouchPosition(touchDown);
    }

    const handleTouchEnd = (e:any)=>{
        const currentPosition = e?.touches[0]?.clientX;
        if(currentPosition)setTouchPosition(currentPosition)
    }
    const handleTouchMove = (e:any) => {
        if (touchPosition === null) {
            return;
        }

        const currentPosition = e.touches[0].clientX;
        const direction = touchPosition - currentPosition;


        if (direction < -80) {
            changeSlide(-1);
        }

        if (direction > 80) {
            changeSlide(1);
        }
        //setfake(direction)
        //setTouchPosition(touchPosition);
    }

    useEffect(() => {
        if (!props.autoPlay) return;

        const interval = setInterval(() => {
            changeSlide(1);
        }, props.autoPlayTime);

        return () => {
            clearInterval(interval);
        };
    }, [itemList?.length, slide]); // when images uploaded or slide changed manually we start timer

    if(mode===1) return(
        <SliderContext.Provider
            value={{
                //setMode,
            }}>

            <FullCard />
        </SliderContext.Provider>
    )
    //if(!rsDataItem.success) return null
    return(
        <WaitLoader loading={ldrData} api={rsDataItem} onlyLoading={true}>

            <div className={styles.sliderContainer}>
                <TariffFilter/>
            <div
                ref={containerRef}
                style={{width:props.width, height: props.height }}
                className= {styles.slider}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}>
                <SliderContext.Provider
                    value={{
                        goToSlide,
                        changeSlide,
                        //setMode,
//                        setCardDataItem,
                        slidesCount: itemList?.length,
                        slideNumber: slide,
                        screenSlideCount: screenSlideCount,
                        itemList: itemList,
                    }}>
                    <SlideDotList/>
                    <SlideArrow/>
                    {startData && <SlideList/>}
                </SliderContext.Provider>
            </div>
            </div>
        </WaitLoader>
    )
}
export default Slider




