import {useRef} from "react";
import useApiQuery from "../ApiQuery/UseApiQuery";

const useCheckLogin = ():boolean =>
{
    const data = useRef({})
    const [ldrLoginCheck,rsLoginCheck,executeLoginCheck] = useApiQuery("/auth/check","get",data);
    let rs = false
    async function check()
    {
        return await executeLoginCheck()
    }
    return true
}
export default  useCheckLogin