import styles from './TarifTagBuilder.module.css'
import {useEffect, useRef, useState} from "react";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import {SelectUnitDataInt} from "../../FormTemplate/SelectUnit/SelectUnit";
import TableItem from "../../FormTemplate/TableItem/TableItem";

interface TarifTagBuilderPropsInt
{
    onUpload: (data:string[]) => void;
}
interface TarifTagBuilderPadInt
{
    tagId:string
    tag:string
    selected:boolean
    order:number
}
export default function TarifTagBuilder({onUpload}:TarifTagBuilderPropsInt)
{
    const tarifTagList = useRef()
    const [ldrTarifTagList,rsTarifTag,exTarifTag] = useApiQuery("/ref/tariff/tag","get",tarifTagList);
    const [tarifTagListData, setTarifTagListData] = useState<any[]>([])
    const [dataRecord, setDataRecord] = useState<any>({})
    const [padList,setPadList] = useState<TarifTagBuilderPadInt[]>([])

    useEffect(()=>{
        exTarifTag().then((e)=>{
            if(e?.success)
            {
                setTarifTagListData(e?.payload)
            }
        })
    },[tarifTagList])

    const buildPadList = ()=>{
        return padList.map((item,idx)=>{
            return (
                <div key={idx} className={[styles.padLine, item?.selected ? styles.padLineSel : ""].join(' ')} >
                    <div className={["d-flex mx-1"].join(' ')} onClick={()=>handleSelect(idx)}>{item.tagId}</div>
                    <div className={["d-flex mx-1 w-100"].join(' ')} onClick={()=>handleSelect(idx)}>{item.tag}</div>
                    <div className={["d-flex flex-column mx-1", styles.lineArr].join(' ')}>
                        <div className={["d-flex"].join(' ')} onClick={()=>moveLine(idx,-1)}><i className='bx bx-chevron-up'></i></div>
                        <div className={["d-flex"].join(' ')} onClick={()=>moveLine(idx,1)}><i className='bx bx-chevron-down'></i></div>
                    </div>
                </div>
            )
        })
    }

    const handleSelect = (id:number)=>{
        return setPadList(padList.map((item,idx)=>{

            item.selected = idx === id;
            return item
        }))
    }
    const handleAdd = () =>{
        if(! dataRecord?.id) return
        const already = padList.reduce((acc,item)=>{
            if(item.tagId === dataRecord?.tagId)acc = true
            return acc
        }, false)
        if(already) return;
        setPadList([...padList, {
            tagId: dataRecord?.tagId,
            tag: dataRecord?.tagName,
            order:0,
            selected: false
        }])

    }

    const handleUpload = () =>{
        const data = padList.map((item)=>item.tagId)
        if(onUpload)onUpload(data)
    }

    const isSelected = () =>{
        return padList.reduce((acc,item)=>{
            if(item.selected) acc = true
            return acc
        }, false)
    }

    const moveLine = (id:number, dir:number)=>{
        let newIdx = id+dir
        const newArr = structuredClone(padList)
        if(newIdx < 0 ) newIdx = 0
        if(newIdx > newArr.length-1) newIdx = newArr.length -1
        console.log(`old ${id} new ${newIdx}`)
        const oldEl = structuredClone(newArr[id])
        const newEl = structuredClone(newArr[newIdx])


        newArr.splice(id,1,newEl)
        newArr.splice(newIdx,1,oldEl)

        console.log("dataList", newArr)

        setPadList(newArr)









    }

    const handleDelete = ()=>{
        setPadList(padList.filter((item)=>{
            if(item.selected) return false
            return true
        }))
    }

    return(
        <div className={styles.cont}>
            <div className={styles.tableCont}>

            <TableItem
                data={tarifTagListData}
                onPageCount={5}
                onRecord={(e)=>setDataRecord(e)}
                column={[
                    {
                        type:0,
                        fieldId:"tagId",
                        title:"id",
                        mode:"sf"
                    },
                    {
                        type:0,
                        fieldId:"tagName",
                        title:"Тэг",
                        mode:"sf"
                    },
                    {
                        type:0,
                        fieldId:"desc",
                        title:"Описание",
                        mode:"sf",
                        maxLen: 30
                    },
                                    ]}
            ></TableItem>
            </div>

            <div className={styles.pmCont}>
                <div className={styles.arrCont}>
                    <div onClick={()=>handleAdd()} className={[dataRecord?.id ? "" : styles.dis].join(' ')}><i className='bx bxs-right-arrow-square'></i></div>
                    <div onClick={()=>handleDelete()} className={[isSelected() ? "" : styles.dis].join(' ')}><i className='bx bxs-trash-alt'></i></div>
                    <div onClick={()=>handleUpload()}><i className='bx bx-upload'></i></div>
                </div>
                <div className={styles.padCont}>
                    {buildPadList()}
                </div>
            </div>
        </div>
    )
}