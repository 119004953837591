import styles from './SimPersonalData.module.css'
import React, {useEffect, useRef, useState} from "react";
import useFormValidator, {FormValidatorInt} from "../../FormValidator/FormValidator";
import WaitLoader from "../../WaitLoader/WaitLoader";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import SelectUnit, {SelectUnitDataInt} from "../../FormTemplate/SelectUnit/SelectUnit";
import tdkImg from '../../Img/Pass/tdk.png'
import uzbImg from '../../Img/Pass/uzb.png'
import kgzImg from '../../Img/Pass/kgz.png'
import {SimInfoResultInt} from "../SimInfo/SimInfo";
import createUtilityClassName from "react-bootstrap/createUtilityClasses";
import DateToIso from "../../Util/DateToIso";

interface SimPersonalDataCbInt {
    (code:number):void
}
interface SimPersonalDataPropsInt
{
    cbExecute: SimPersonalDataCbInt
    simInfo: SimInfoResultInt | undefined
}



export default function SimPersonalData({simInfo,cbExecute}:SimPersonalDataPropsInt)
{
    const [test, setTest] = useState("")

    function normTrans(word:string){
        type k = {
            [key:string]:string
        }
        word = word.toLowerCase()
        let answer = '';
        const converter:k = {
            'а': 'a',
            'е': 'e',
            'к': 'k',    'м': 'm',
            'о': 'o',    'р': 'p',    'с': 'c',    'т': 't',
            'у': 'y',    'х': 'x',
        };

        for (let i = 0; i < word.length; ++i ) {
            //setTest(word[i].toString() + " : " + converter[word[i].toString()])
            if (converter[word[i].toString()] === undefined){
                //setTest("1_"+word[i])
                answer += word[i];
            } else {
                //setTest("0_"+converter[word[i].toString()])
                answer += converter[word[i].toString()];
            }
        }

        return answer.toUpperCase();
    }

    const normFio = (val: string)=>{
        return String(val).charAt(0).toUpperCase() + String(val).slice(1).toLowerCase();
    }
    const normDepart = (val: string)=>{
        const pl = val.replace(/[^0-9]/ui,'')
        if(pl?.match(/^[0-9]{6}$/ui)) return pl
        return val
    }

    const normDate = (val: string)=>{
        if(val?.match(/^[0-9]{8}$/ui))
            return val.substring(0,2) + "." + val.substring(2,4) + "." + val.substring(4,8)
        if(val?.match(/^[0-9]{2}.[0-9]{2}.[0-9]{4}$/ui))
            return val.substring(0,2) + "." + val.substring(3,5) + "." + val.substring(6,10)
        return val
    }

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [canvasData, setCanvasData] = useState<ImageData>()

    const citizenshipData = useRef()
    const [ldrCitizenship,rsCitizenship,exeCitizenship] = useApiQuery("/ref/citizenship","get",citizenshipData);
    const [citizenshipList, setCitizenshipList] = useState<SelectUnitDataInt[]>([])
    const [citizenshipListVal, setCitizenshipListVal] = useState<string[]>([])

    const [error, setError] = useState({
        isError : false,
        text:""
    })



    const simRegData = useRef(
        {
            icc:"",
            ctn:"",
            citizenshipId:"",
            capt:"",
            family:"",
            name:"",
            middleName:"",
            birthDay:"",
            serial:"",
            number:"",
            issueDateFrom:"",
            issueDateTo:"",
            gender:"",
            imei:"",
        })
    const [ldrSimReg,rsSimReg,exSimReg] = useApiQuery("/api/application/sim/activate/self","post",simRegData);


    const [genderListVal, setGenderListVal] = useState<string[]>([])
    const [genderList, setGenderList] = useState<SelectUnitDataInt[]>([
        {
            name: "Мужской",
            val: "M"
        },
        {
            name: "Женский",
            val: "F"
        }

    ])





    const [formData,setFormData]=useState<FormValidatorInt[]>([
        {
            val:"",
            id:"gender",
            errText: "Не выбран пол",
            regExp: /^M|F$/ui,
            isCheck: true,
            valid: true,
        },
        {
            val:"",
            id:"citizenship",
            errText: "Не выбрано гражданство",
            regExp: /.*/ui,
            isCheck: true,
            valid: true,
        },
        {
            val:"",
            id:"family",
            errText: "Не корректная фамилия",
            regExp: /^[а-я]+[а-я-\s]+$/ui,
            isCheck: true,
            valid: true,
            normalize: normFio,
        },
        {
            val:"",
            id:"name",
            errText: "Не корректное имя",
            regExp: /^[а-я]+[а-я-\s]+$/ui,
            isCheck: true,
            valid: true,
            normalize: normFio,

        },
        {
            val:"",
            id:"middleName",
            errText: "Не корректное отчество",
            regExp: /^[а-я]+[а-я-\s]+$|^\s*$/ui,
            isCheck: true,
            valid: true,
            normalize: normFio,

        },
        {
            val:"",
            id:"birthDay",
            errText: "Не корректная дата рождения",
            regExp: /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui,
            isCheck: true,
            valid: true,
            normalize: normDate,

        },

        {
            val:"",
            id:"serial",
            errText: "Не корректная серия паспорта",
            regExp: /^[0-9a-z]{1,4}$|^\s*$/ui,
            isCheck: false,
            valid: true,
            enabled: true,
            normalize: normTrans,

        },

        {
            val:"",
            id:"number",
            errText: "Не корректный номер паспорта",
            regExp: /^[0-9a-z]{6,12}$/ui,
            isCheck: true,
            valid: true,
            normalize: normTrans,
        },
        {
            val:"",
            id:"date",
            errText: "Не корректная дата выдачи",
            regExp: /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui,
            isCheck: true,
            valid: true,
            normalize: normDate,

        },
        {
            val:"",
            id:"dateTo",
            errText: "Не корректная дата окончания",
            regExp: /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui,
            isCheck: true,
            valid: true,
            normalize: normDate,

        },
        {
            val:"",
            id:"capt",
            errText: "Не корректный код с картинки",
            regExp: /^[a-z0-9]{4,}$/ui,
            isCheck: true,
            valid: true
        },
        {
            val:"000000000000000",
            id:"imei",
            errText: "Не корректный imei",
            regExp: /^[0-9]{14,18}$/ui,
            isCheck: false,
            valid: true
        },




    ])
    const [validate,validateById,getValue,setValue,getErrText,getItem] = useFormValidator(formData)
    const [stage, setStage] = useState(0)
    const [lockForm, setLockForm] = useState(false)
    const [capt,setCap]=useState("")
    const dataCapt = useRef({
        name: "pSelf",
        len:4,
        useDigit: true
    })
    const [ldrDataCapt,rsDataItemCapt,executeLoadDataCapt] = useApiQuery("/auth/capt","post",dataCapt);

    const loadNewCapt= ()=>
    {
        executeLoadDataCapt().then((e)=>{
            if(e?.success)setCap(e?.payload)
        })

    }
    useEffect(()=>{
        loadNewCapt()
    },[dataCapt])


    useEffect(()=>{
        setValue('gender',genderListVal[0])
        setValue('citizenship',citizenshipListVal[0])
        validateById('gender')
        validateById('citizenship')


    },[genderListVal,citizenshipListVal])

    useEffect(()=>{
        switch (stage)
        {
            case 1://Процесс идет
                setLockForm(true)
                break
            case 2://Успешно
                loadNewCapt()
                console.log("регистрация прошла успешно")
                setTimeout(()=>cbExecute(0),3000)
                break
            case 3: //Ошибка
                loadNewCapt()
                setLockForm(false)
                //setStage(0)
                break

            default:
                setLockForm(false)
        }

    },[stage])


    useEffect(()=>{
        loadPassImage().then()
        setFormData(formData.map((item)=>{
            item.isCheck = true
            item.enabled = true
            return item
        }))
        if(citizenshipListVal[0]?.match(/(tdk)|(uzb)/ui))
        {
            setFormData( formData.map((item)=>{
                if(item.id?.match(/serial/ui))
                {
                    item.isCheck = false
                    item.enabled = false
                    //item.errText = ''
                }
                return item
            }))
        }

    },[citizenshipListVal])

    async function loadPassImage()
    {
        const ct =  citizenshipListVal[0]
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if(! context || !canvas) return
        context.clearRect(0,0,canvas.width,canvas.height)
        const img = new Image()
        context.globalAlpha = 0.9
        if(ct?.match(/tdk/ui))
        {
            img.src = tdkImg
        }
        if(ct?.match(/uzb/ui))
        {
            img.src = uzbImg
        }
        if(ct?.match(/kgs/ui))
        {
            img.src = kgzImg
        }

        if(img.src)
            return new Promise((resolve)=>{
                img.onload = ()=>{
                    console.log("try paint")
                    canvas.height = img.height
                    canvas.width = img.width
                    context?.drawImage(img,0,0)

                    setCanvasData(context.getImageData(0,0,canvas.width ,canvas.height))
                    resolve(true)
                }
                img.onerror = ()=>resolve(false)

            })

    }


    useEffect(()=>{
        exeCitizenship().then((e)=>{
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.name, val: item?.citizenId}
                })
                if(newList)setCitizenshipList(newList)
            }
        })
    },[citizenshipData])


    const drawFamily = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,152,61,88,17)
        if(ct.match(/kgs/ui))drawRect(context,123,45,75,14)
        if(ct.match(/uzb/ui)){
            drawRect(context,200,90,88,19)
            context.font = 'bold 18px/1.5 Arial, sans-serif';
            context.fillStyle = "red"
            context.fillText("На русском языке", 295, 105);
        }
    }

    const drawName = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,152,99,75,17)
        if(ct.match(/kgs/ui))drawRect(context,123,73,75,14)

        if(ct.match(/uzb/ui))
        {
            drawRect(context,200,135,95,19)
            context.font = 'bold 18px/1.5 Arial, sans-serif';
            context.fillStyle = "red"
            context.fillText("На русском языке", 302, 150);
        }
    }

    const drawMiddleName = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,227,99,119,17)
        if(ct.match(/kgs/ui))drawRect(context,123,99,98,14)

        if(ct.match(/uzb/ui))
        {
            drawRect(context,0,0,0,0)
            context.font = 'bold 15px/1.5 Arial, sans-serif';
            context.fillStyle = "red"
            context.fillText("Отчество на первой странице паспорта", 295, 105);
            context.fillText("На русском языке", 295, 125);

        }

    }

    const drawBirthDay = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,152,167,78,17)
        if(ct.match(/uzb/ui))drawRect(context,200,195,117,19)
        if(ct.match(/kgs/ui))drawRect(context,123,153,60,14)

    }
    const drawNumber = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,334,35,79,17)
        if(ct.match(/uzb/ui))drawRect(context,466,48,104,19)
        if(ct.match(/kgs/ui))drawRect(context,224,157,62,14)

    }

    const drawDateFrom = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,152,194,80,18)
        if(ct.match(/uzb/ui))drawRect(context,200,266,117,19)
        if(ct.match(/kgs/ui))drawRect(context,425,84,62,14)


    }

    const drawDateTo = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        if(ct.match(/tdk/ui))drawRect(context,260,194,80,18)
        if(ct.match(/uzb/ui))drawRect(context,200,294,117,19)
        if(ct.match(/kgs/ui))drawRect(context,224,180,62,14)


    }

    const drawIMEI = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const ct = citizenshipListVal[0]
        if(! context || !canvas || !ct) return
        drawRect(context,0,0,0,0)
        context.strokeStyle = "#ffffff"
        context.clearRect(10,11,400,60)
        context.font = 'bold 20px/1.5 Arial, sans-serif';
        context.fillStyle = "red"
        context.fillText("Наберите на телефоне *#06#", 15, 35);
        context.fillText("Введите IMEI телефона, только один", 15, 60);

    }

    const drawRect = (ctx: CanvasRenderingContext2D,x:number,y:number,dx:number,dy:number) =>{
        if(canvasData) ctx.putImageData(canvasData,0,0)
        ctx.strokeStyle = "#ff0001"
        ctx.lineWidth = 3
        ctx.strokeRect(x,y,dx,dy)
    }



    const handleRegister = ()=>{
        if(! validate())
        {
            setError({
                isError: true,
                text: "Заполните все значения помеченные красным"
            })
            return
        }
        setError({...error,isError: false})

        try {
            simRegData.current =
                {
                    family: getValue("family"),
                    name: getValue("name"),
                    middleName: getValue("middleName"),
                    birthDay: DateToIso(getValue("birthDay")),
                    gender: getValue("gender"),
                    citizenshipId: getValue("citizenship"),
                    issueDateTo: DateToIso(getValue("dateTo")),
                    issueDateFrom: DateToIso(getValue("date")),
                    imei: getValue("imei"),
                    serial: getValue("serial"),
                    number: getValue("number"),
                    ctn: simInfo?.ctn ? simInfo.ctn : "",
                    icc: simInfo?.icc ? simInfo.icc : "",
                    capt: getValue("capt")
                }

        } catch (e) {
            console.log(e)
            return;
        }

        console.log("ready", simRegData.current)
        setStage(1)
        exSimReg().then((e)=>{
            console.log(e)
            if(e?.success !== undefined && e.success  )
            {
                const payload = e?.payload
                if(payload?.state !== undefined && payload.state)
                {
                    setStage(2)
                    return;
                }
                console.log("set error",payload)
                setError({
                    isError: true,
                    text: payload?.errorAsString ? payload.errorAsString : "Ошибка регистрации, попробуйте позже"
                })
                setStage(3)
                return
            }
            setError({
                isError: true,
                text: e?.errorAsString ? e.errorAsString : "Ошибка сервера, попробуйте позже"
            })
            setStage(3)
            return

        })


    }



    const isCitizenSelect = citizenshipListVal.length ? true : false
    return(
        <div className={styles.pdCont}>
            <div className={styles.formView}>
                <form >
                    <div className={["row g-3 mb-1", citizenshipListVal.length ? "" : "d-none"].join(' ') }>
                        <div className={styles.canvasTitle}>Заполните согласно Вашему документу</div>
                        <div className={styles.passCanvasCont}>
                            <canvas  className={styles.passCanvas} ref={canvasRef}/>

                        </div>
                    </div>
                    <div className="row g-3 mb-1">
                        <div className="col-md-6 ">
                            <label htmlFor="inputEmail4" className={["form-label col-form-label-sm", isCitizenSelect ? "d-none" : ""].join(' ')}>Выберите гражданство</label>
                            <SelectUnit inData={citizenshipList} capt={"Гражданство"} getVal={setCitizenshipListVal} />
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("citizenship")}</div>

                        </div>
                        <div className="col-md-6 ">
                            <h4 className={styles.ctnInfo}><span>Ваш номер : </span>{" +7" + simInfo?.ctn}</h4>
                        </div>

                    </div>


                    <div className="row g-3 mb-1">
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Фамилия</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Фамилия"}
                                   value={getValue("family")}
                                   onBlur={()=>validateById("family")}
                                   onChange={(e)=>setValue("family",e.target.value)}
                                   maxLength={30}
                                   readOnly={lockForm}
                                   onFocus={drawFamily}
                                   disabled={ !isCitizenSelect}
                            >
                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("family")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Имя</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Имя"}
                                   value={getValue("name")}
                                   onBlur={()=>validateById("name")}
                                   onChange={(e)=>setValue("name",e.target.value)}
                                   maxLength={30}
                                   readOnly={lockForm}
                                   onFocus={drawName}
                                   disabled={ !isCitizenSelect}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("name")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Отчество</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Отчество"}
                                   value={getValue("middleName")}
                                   onBlur={()=>validateById("middleName")}
                                   onChange={(e)=>setValue("middleName",e.target.value)}
                                   maxLength={40}
                                   readOnly={lockForm}
                                   onFocus={drawMiddleName}
                                   disabled={ !isCitizenSelect}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("middleName")}</div>
                        </div>
                    </div>

                    <div className="row g-3 mb-1">
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Дата рождения</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XX.XX.XXXX"}
                                   value={getValue("birthDay")}
                                   onBlur={()=>validateById("birthDay")}
                                   onChange={(e)=>setValue("birthDay",e.target.value)}
                                   maxLength={10}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                                   onFocus={drawBirthDay}
                                   disabled={ !isCitizenSelect}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("birthDay")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Номер паспорта</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXXXXX..."}
                                   value={getValue("number")}
                                   onBlur={()=>validateById("number")}
                                   onChange={(e)=>setValue("number",e.target.value)}
                                   maxLength={12}
                                   readOnly={lockForm}
                                   onFocus={drawNumber}
                                   disabled={ !isCitizenSelect}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("number")}</div>
                        </div>

                        <div className="col-md-4" style={{alignContent:"start"}}>
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Укажите Ваш пол</label>
                            <SelectUnit inData={genderList} capt={"Пол"} getVal={setGenderListVal} />
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("gender")}</div>
                        </div>

                    </div>


                    <div className="row g-3 mb-1">
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Дата выдачи</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XX.XX.XXXX"}
                                   value={getValue("date")}
                                   onBlur={()=>validateById("date")}
                                   onChange={(e)=>setValue("date",e.target.value)}
                                   maxLength={10}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                                   onFocus={drawDateFrom}
                                   disabled={ !isCitizenSelect}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("date")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Действителен до</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XX.XX.XXXX"}
                                   value={getValue("dateTo")}
                                   onBlur={()=>validateById("dateTo")}
                                   onChange={(e)=>setValue("dateTo",e.target.value)}
                                   maxLength={10}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                                   onFocus={drawDateTo}
                                   disabled={ !isCitizenSelect}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("dateTo")}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">IMEI телефона (*#06#)</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXXXXXXXXXXXXXX"}
                                   value={getValue("imei")}
                                   onBlur={()=>validateById("imei")}
                                   onChange={(e)=>setValue("imei",e.target.value)}
                                   maxLength={18}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                                   disabled={ !isCitizenSelect || true}
                                   onFocus={drawIMEI}

                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("imei")}</div>
                        </div>




                    </div>



                    <div className="row g-3 mb-3">
                        <div className="col-12">
                            <div className={styles.captContainer}>
                                <label htmlFor="exampleInputEmail1 m-0" className="form-label col-form-label-sm">Проверочный код</label>
                            </div>
                        </div>
                    </div>


                    <div className="row g-3 mb-3">
                        <div className="col-12 col-md-6 my-auto">

                            <div className={styles.captRow}>
                                <div className={styles.captContainer}>
                                    <img className={styles.capImg} src={capt} onClick={()=>{loadNewCapt() }}/>
                                </div>
                                <input type="text" className="m-1 form-control" placeholder={"Код с картинки"}
                                       value={getValue("capt")}
                                       onBlur={()=>validateById("capt")}
                                       onChange={(e)=>setValue("capt",e.target.value)}
                                       maxLength={6}
                                       inputMode={"numeric"}



                                ></input>

                            </div>

                        </div>
                        <div  className={["", styles.errMessage].join(' ')}>{getErrText("capt")}</div>


                    </div>



                    { error.isError &&
                        <div className="row g-3 mb-1">
                            <div className="col-md-12">
                                <p className={styles.errCont}>{error.text}</p>
                            </div>
                        </div>
                    }


                    <div className="col-12">
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-primary"
                                    disabled={stage===1}
                                    onClick={()=>handleRegister()}
                            >Регистрация</button>
                            <button type="button" className="btn btn-primary"
                                    disabled={stage===1}
                                    onClick={()=>cbExecute(1)}
                            >Назад</button>
                            <WaitLoader loading={ldrSimReg} api={rsSimReg} onlyLoading={true}>

                            </WaitLoader>
                            {stage === 1 &&
                                 <div className={styles.regText}>Идет регистрация...</div>
                            }
                            {stage === 2 &&
                                <div className={styles.regSuccess}>Регистрация прошла успешно</div>
                            }



                        </div>

                    </div>





                </form>
            </div>

        </div>
    )
}