import React, {useEffect, useRef, useState} from "react";
import ModalWindow from "../ModalWindow/ModalWindow";
import styles from './LoginForm.module.css'
import {RiCloseLine} from "react-icons/ri";
import {useNavigate} from "react-router-dom";
import useFormValidator, {FormValidatorInt} from "../FormValidator/FormValidator";
import capt from '../Img/capture.png'
import useApiQuery from "../ApiQuery/UseApiQuery";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setAdminState, setAuthToken, setLoginState, setSlider} from "../Reducer/GlobalReducer";
import useCheckLogin from "../Util/CheckLogin";
import WaitLoader from "../WaitLoader/WaitLoader";


export default function LoginForm()
{

    const [showCapt, setShowCapt] = useState(false)
    const [captImage,setCaptImage] = useState("")

    const isLog = useCheckLogin()
    const [loginError,setLoginError] = useState({
        show:false,
        text: ""
    })
    const dispatch = useDispatch();
    const isLogin = useSelector((state:ReduxState)  => {return state.isLogin} )

    const data = useRef({
        user:"",
        pass:"",
        capt:""
    })

    const mockData = useRef(null)
    const [ldrLoginCheck,rsLoginCheck,exLoginCheck] = useApiQuery("/auth/check","get",mockData);

    const [ldrLogin,rsLogin,exLogin] = useApiQuery("/auth/login","post",data,cbLogin);

    const navigate = useNavigate();

    const [saveMe , setSaveMe] = useState(true)

    const defLogin =localStorage.getItem('defLogin')

    const [formData,setFormData]=useState<FormValidatorInt[]>([
        {
            val:( defLogin ? defLogin :""),
            id:"login",
            errText: "Не корректный логин",
            regExp: /^[a-z]+[a-z0-9-]+$/i,
            isCheck: true,
            valid: true
        },
        {
            val:"",
            id:"pass",
            errText: "Не корректный пароль",
            regExp: /^.+$/i,
            isCheck: true,
            valid: true
        },
        {
            val:"",
            id:"capt",
            errText: "Не корректная капча",
            regExp: /^[a-z0-9]{4}$/i,
            isCheck: false,
            valid: true
        },
    ])

    useEffect(()=>{
        exLoginCheck().then((payload)=>{
            console.log(`payload`, payload)
        })

    },[mockData])


    function cbLogin(data: any)
    {
        console.log(data);
        try {

            if(data?.success !==undefined && data.success === false)
            {
                setLoginError({
                    show: true,
                    text: data?.errorAsString
                })
                if(data.payload.useCapt === true)
                {
                    setCaptImage(data.payload.capt)
                    setShowCapt(true)
                }
            }
            if(data?.success !==undefined && data.success)
            {
                dispatch(setAdminState(data.payload?.adminRole ? data.payload.adminRole: false))
                dispatch(setLoginState(true))
                dispatch(setAuthToken(data?.payload?.token))
                navigate('/lk')
            }


        } catch (e){}


    }
    const login =()=>{
        data.current.user = getValue("login")
        data.current.pass = getValue("pass")
        data.current.capt = getValue("capt")
        exLogin().then()
    }

    const btnLogin = ()=>{
        if(! validate())return

        if(saveMe)localStorage.setItem("defLogin", getValue("login"))
        else localStorage.removeItem("defLogin")
        login()
    }



    const [validate,validateById,getValue,setValue,getErrText] = useFormValidator(formData)



    const buildForm = () =>{
        return(
            <div className={styles.modalContainer}>
                <div className={styles.modal}>
                    <form >
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Логин</label>
                            <input type="text" className="form-control"
                                   aria-describedby="emailHelp"
                                   value={getValue("login")}
                                   onBlur={()=>validateById("login")}
                                   onChange={(e)=>setValue("login",e.target.value)}></input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("login")}</div>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Пароль</label>
                            <input type="password" className="form-control"
                                   value={getValue("pass")}
                                   onBlur={()=>validateById("pass")}
                                   onChange={(e)=>setValue("pass",e.target.value)}
                                   onKeyDown={(e)=>{
                                       if(e.key.match(/enter/i))btnLogin()
                                   }}
                            ></input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("pass")}</div>

                        </div>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" checked={saveMe} id={"exampleCheck1"}
                                   onChange={(e)=>{setSaveMe( !saveMe)

                                   }}
                            ></input>
                            <label className="form-check-label" htmlFor="exampleCheck1">Запомнить логин</label>
                        </div>

                        <div className={["mb-3 form-check px-0", (!showCapt ? "d-none" : "")].join(' ')}>
                            <div className={styles.captContainer}>
                                <label htmlFor="exampleInputEmail1" className="form-label">Капча</label>
                                <img className={styles.capImg} src={captImage}/>
                            </div>
                            <input type="text" className="form-control"
                                   aria-describedby="emailHelp"
                                   value={getValue("capt")}
                                   onBlur={()=>validateById("capt")}
                                   onChange={(e)=>setValue("capt",e.target.value)}
                                   onKeyDown={(e)=>{
                                       if(e.key.match(/enter/i))btnLogin()
                                   }}

                            ></input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("capt")}</div>
                        </div>

                        <div  className={["mb-3", styles.errMessage, (loginError.show ? "" : "d-none")].join(' ')}>{loginError.text}</div>
                        <div className={""}>
                            <button type="button" className="btn btn-secondary"
                            onClick={(e)=>{
                                btnLogin()
                            }}
                            onKeyDown={(e)=>{
                                if(e.key.match(/enter/i))btnLogin()
                            }}

                            >Вход</button>
                            <button type="button" className="btn btn-secondary" onClick={()=>{dispatch(setSlider({sliderMode:0}));navigate('/')}}>Отмена</button>

                        </div>
                    </form>

                </div>

            </div>
        )
    }

        if(ldrLoginCheck)
        {
            return(
                <WaitLoader loading={ldrLoginCheck} api={rsLoginCheck} onlyLoading={true}>
                    <></>
                </WaitLoader> );
        }

        if(rsLoginCheck.success) {dispatch(setSlider({sliderMode:0}));navigate('/lk')}

        return(
        <>
            <ModalWindow onClose={()=>{navigate('/')}}>
                {buildForm()}
            </ModalWindow>
        </>
    )

}