import {SliderContext} from "./Slider";
import {useContext} from "react";
import Dot from "./SlideDot";
import styles from './SliderDotList.module.css'


function SlideDotList()
{
    const context = useContext(SliderContext);
    const renderDots = () => {
        const dots = [];
        const cnt = context ? (context.slidesCount - context.screenSlideCount+1) : 0
        console.log(`cnt ${cnt}`)
        for (let i = 0; i < cnt; i++) {
            dots.push(<Dot key={`dot-${i}`} number={i} />);
        }

        return dots;
    };

    return <div className={styles.dotList}>{renderDots()}</div>;
}
export default SlideDotList