import {useEffect, useState} from "react";
import SimPersonalData from "./PersonalData/SimPersonalData";
import SimInfo, {SimInfoResultInt} from "./SimInfo/SimInfo";
import SelfRegNote from "./SelfRegNote/SelfRegNote";

export default function  SimSelfRegister(){
    const [stage, setStage] = useState(0)
    const [simData, setSimData] = useState<SimInfoResultInt>()

    const cbSimInfo = (data: SimInfoResultInt) =>{
        if(data.code === 0)
        {
            setSimData(data)
            setStage(1)

        }
        if(data.code === 1)
        {
            setSimData(data)
            setStage(2)
        }
    }

    const cbPersonalData = (code:number) =>{
        if(code === 1) setStage(0)
        if(code === 0) setStage(2)
    }

    useEffect(()=>{
        switch (stage)
        {
            case 0:
                break
            case 1:
                break
        }
    },[stage])

    return(
        <>
            {stage === 0 && <SimInfo cbExecute={cbSimInfo}/>}
            {stage === 1 && <SimPersonalData cbExecute={cbPersonalData} simInfo={simData}/>}
            {stage === 2 && <SelfRegNote ctn={simData?.ctn}/>}

        </>

    )
}