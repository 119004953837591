import {MutableRefObject, useEffect, useRef, useState} from "react";
import useApiQuery from "../ApiQuery/UseApiQuery";


export interface LongTaskItemCbInt
{
    (state:boolean, data:any, code:number,cnt:number):void
}
interface LongTaskItemInt
{
    count:number
    onTick?:LongTaskItemCbInt
    onResult:LongTaskItemCbInt
    rayId: string
    delay?:number
}

interface UseLongTaskExecute
{
    (rayId: string):void
}

const  UseLongTask = (count:number,delay?:number,onResult?:LongTaskItemCbInt, onTick?:LongTaskItemCbInt): [boolean, UseLongTaskExecute] =>
{
    const [isProcess, setIsProcess] = useState(false)
    const dataTaskResult = useRef({
        rayId:""
    })
    const [ldrTaskResult,rsTaskResult,exTaskResult] = useApiQuery("/task/result","post",dataTaskResult);

    const [tick, setTick] = useState(-1)
    const [taskTimerId,setTaskTimerId] = useState<NodeJS.Timer>()

    useEffect(()=>{
        //console.log("onResult",onResult)
        if(tick === -1) return
        if(tick > count)
        {
            if(onResult && isProcess)onResult(false,{},1,-1)//time out
            return
        }
        const timerId =setInterval (run,delay ? delay : 2000)
        return ()=>clearInterval(timerId)
    },[tick])


    function execute(rayId:string){
        dataTaskResult.current.rayId = rayId
        setTick(0)
        setIsProcess(true)
    }

    function run() {
        //console.log(`tick ${tick} of ${count}`,onResult)
        setTick(tick+1)
        exTaskResult().then(e=>makeTaskResult(e))
            .catch((e)=>makeTaskError(e))

    }


    const makeTaskResult = (e:any) =>{
        if(e?.success)
        {
            if(e?.payload?.result)
            {
                setTick(count+1)
                setIsProcess(false)
                if(onResult)onResult(true,e?.payload?.outData  ? e.payload.outData : {} , 0,tick)
                return
            }
            if(e?.payload?.result !== undefined && !e?.payload?.result)
            {
                if(onTick)onTick(false,e?.payload?.outData  ? e.payload.outData : {}, 0,tick)
                return
            }


        }
    }

    const makeTaskError = (e:any) =>{
        setTick(count+1)
        if(onResult)onResult(false,e,2,tick)
    }


    return [isProcess,execute]

}

export  default UseLongTask