import styles from "./TableItem.module.css"
import {useEffect, useState} from "react";
import filterRegExp from "../../Util/filterRegExp";

export interface TableItemDataItemInt
{
    [key:string]:any
}

interface TableItemCbRecordInt
{
    (data:TableItemDataItemInt):void
}
interface TableItemCbReloadInt
{
    ():void
}


export interface TableItemColumnInt
{
    title:string
    fieldId:string
    type:number // 0 - string 1-number 2-date 3-time 4 -dateTime 5 logic 6-pass
    mode:string // s -sort f- filter h- hide
    filterMode?:string
    maxLen?:number
}

interface TableItemPropsInt
{
    column:TableItemColumnInt[]
    data: TableItemDataItemInt[]
    onRecord?: TableItemCbRecordInt
    onReload?: TableItemCbReloadInt
    onPageCount:number
}

export default function TableItem({onPageCount,data,column,onRecord,onReload}:TableItemPropsInt)
{
    const [tblData, setTblData] = useState<TableItemDataItemInt[]>([])
    const [frameData, setFrameData] = useState<TableItemDataItemInt[]>([])
    const [page, setPage] = useState(0)
    const [pageCount, setPageCount] = useState(0)

    const [vlPage, setVlPage] = useState(1)

    const [actRow, setActRow] = useState(-1)
    const [actCal, setActCal] = useState(-1)

    const [filterVal, setFilterVal] = useState(column.map((item)=>{
        return {
            val:"",
            used: false
        }
    }))


    const [tblColumn, setTblColumn] = useState(
        column.map((item)=>{
            return {...item, sort : -1}
        })
    )

    useEffect(()=>{
        setTblData(data)
        setActRow(-1)
        setActCal(-1)
    },[data])

    useEffect(()=>{
        init()
    },[tblData])


    useEffect(()=>{
        if(onRecord && actRow < 0)onRecord({})
    },[actRow])

    const init = () =>{
        //console.log("init data")
        setPageCount(Math.floor(tblData.length / onPageCount) +  (tblData.length % onPageCount > 0 ? 1 : 0))
        loadFrameData(0)

    }


    useEffect(()=>{
    },[frameData])

    const loadFrameData = (page: number) =>{
        if(page < 0) return
        if((tblData.length - (page) * onPageCount) < 0  ) return
       // console.log("frame load ",page * onPageCount, page * onPageCount + onPageCount)
        setFrameData(tblData.slice(page * onPageCount,page * onPageCount + onPageCount))
        setPage(page)
    }

    const handleSort = (id:number) =>{
        setActCal(id)
        setActRow(-1)
        //console.log("active call",id)
        setTblColumn(tblColumn.map((item,idx)=>{
            if(idx === id)
            {
                switch (item.sort)
                {
                    case -1 : item.sort = 0
                        sortData(1,item.fieldId,item.type)
                        break
                    case 0 : item.sort = 1
                        sortData(-1,item.fieldId,item.type)
                        break
                    case 1 : item.sort = 0
                        sortData(1,item.fieldId,item.type)
                        break
                }
            }
            return item
        }))
    }


    const sortData = (dir: number, fieldId :string, type:number)=>{
        const cpy = [...tblData]
        const sortedData = cpy.sort((a,b)=>{
            let rs = 0
            try {
                let aField = a[fieldId]
                let bField = b[fieldId]

                //console.log(aField,bField, type)

                if(aField === undefined || bField === undefined) return 0
                if(aField === null && bField === null) return 0

                if(aField === null && bField !== null) return 1
                if(aField !== null && bField === null) return -1

                if(type === 1)
                {
                    if(Number(aField) >  Number(bField)) rs = -1
                    if(Number(aField) <  Number(bField)) rs = 1
                }
                if(type === 0 || type ===6)
                {
                    if((aField) >  (bField)) rs = 1
                    if((aField) <  (bField)) rs = -1
                }
                if(type === 2 || type === 3 || type === 4)
                {
                    const d1 = new Date(aField)
                    const d2 = new Date(bField)
                    if( d1 >  d2) rs = 1
                    if( d1 <  d2) rs = -1
                }
                if(type === 5)
                {

                    if(aField > bField) rs= -1
                    if(aField < bField) rs= 1



                }



            } catch (e) {}
            //console.log(a[fieldId], b[fieldId],type,rs)


            return rs*dir

        })

        //console.log(sortedData)
        setTblData(sortedData)
    }

    const setFilterValue = (id:number,val:string) =>{
        setFilterVal( filterVal.map((item,idx)=>{
            if(idx === id) item.val = val.trim()
            return item
        }))
    }
    const getFilterValue = (id:number) =>{
        const a = {val:"", used: false}
        return filterVal.reduce((acc,item,idx)=>{
            if(idx === id) acc = item
            return acc
        },a)
    }



    const handleFilter = (fieldId:string, id:number) =>{
        let cpy = [...tblData]
        const val = getFilterValue(id)?.val
        const rg = new RegExp(filterRegExp(val),"uim")
        cpy = cpy.filter((item)=>{
            if(item[ fieldId ] !==undefined && item[ fieldId ]?.toString().match(rg)) return true
            return false
        })
        setTblData(cpy)
        setFilterVal( filterVal.map( (item,idx)=>{
            if(idx === id)item.used = true
            return item
        }))


    }

    const handleResetFilter = () =>{
        setFilterVal( filterVal.map((item,idx)=>{
            item.used = false
            item.val =""
            return item
        }))
        setTblData(data)

    }

    const buildFilter = () =>{
        const useFilter = tblColumn.reduce((acc,item)=>{
            if(item?.mode?.match(/f/ui) && !item?.mode?.match(/h/ui)) acc = true
            return acc
        },false)
        if(! useFilter) return null
        return(
            <tbody><tr>
                {
                    tblColumn.map((item,idx)=>{
                        if(item?.mode?.match(/h/ui)) return null
                        if(!item?.mode?.match(/f/ui)) return (
                            <td key={idx} className={styles.flCell}>

                            </td>
                        )
                        const fVal = getFilterValue(idx)
                        return(
                            <td key={idx} className={styles.flCell}>
                                <div className={styles.flCont}>
                                    <div className={styles.flInput}>
                                        <input className={"form-control form-control-sm"} value={fVal?.val}
                                            onChange={(e)=>setFilterValue(idx,e.target?.value)}
                                            onKeyDown={(e)=>{
                                                if(e.key?.match(/enter/ui))handleFilter(item.fieldId,idx)
                                            }}

                                        ></input>
                                    </div>
                                    <div className={[styles.flImg, fVal?.used ? styles.flUsed : ""].join(' ')}
                                        onClick={()=>handleFilter(item.fieldId,idx)}
                                    >
                                        <i className='bx bx-filter-alt'></i>
                                    </div>
                                    <div className={styles.flImg}
                                         onClick={()=>handleResetFilter()}
                                    >
                                        <i className='bx bx-message-alt-x'></i>
                                    </div>
                                </div>

                            </td>
                        )
                    })

                }

            </tr></tbody>
        )
    }


    const buildHead= ()=>{
        return(
            <thead><tr>
                {tblColumn.map((item,idx)=>{
                    if(item.mode.match(/h/ui)) return null
                    if(item.mode.match(/s/ui))
                    {
                        return(
                            <td key={idx} onClick={()=>handleSort(idx)}>
                                <div className={styles.tiHeadCont}>
                                    <div className={styles.tiSortArrow} >
                                        {item.sort === -1 && <div><i className='bx bx-sort'></i></div>}
                                        {item.sort === 0 && <div><i className='bx bx-sort-up'></i></div>}
                                        {item.sort === 1 && <div><i className='bx bx-sort-down'></i></div>}
                                    </div>
                                    <div>
                                        {item.title}
                                    </div>
                                </div>
                            </td>
                        )
                    }
                    return(
                        <td key={idx} >
                            <div className={styles.tiHeadCont}>
                                <div>
                                    {item.title}
                                </div>
                            </div>
                        </td>
                    )

                })}
            </tr></thead>
        )



    }

    const buildDataLine = (line :TableItemDataItemInt,lineIdx:number) =>{
        let oddClass = lineIdx % 2 ? styles.even : styles.odd

        return tblColumn.map((item,idx)=>{
            let odd = idx === actCal ? oddClass : ""
            if(lineIdx === actRow) odd = styles.actRow
            if(item.mode.match(/h/ui)) return null
            if(line[ item.fieldId ] !==undefined)
            {
                let k = line[ item.fieldId ]
                if(k === undefined || k=== null) k =""
                const vl = typeof k === "string" ? k.trim() : k.toString()
                let txt = vl
                if(item.type === 2)
                {
                    txt = new Date(vl)?.toLocaleString()?.substring(0,10)
                }
                if(item.type === 4)
                {
                    txt =  new Date(vl)?.toLocaleString()
                }
                if(item.type === 3)
                {
                    txt =  new Date(vl)?.toLocaleString()?.substring(12)
                }
                if(item.type === 5)
                {
                    const vl  = (line[ item.fieldId ]) ? true :false
                    return (
                        <td className={[odd].join(' ')} key={idx}>
                            <div className={styles.tiDataBox}>
                                <input type={"checkbox"} checked={vl} readOnly={true}></input>
                            </div>
                        </td>
                    )
                }

                if(item.type === 6)
                {
                    txt = "*****"
                }

                //console.log("txt",txt)

                const vlText = txt ? txt : ""
                const text = (item?.maxLen && vlText.length > item?.maxLen) ? vlText?.substring(0,item.maxLen)+"..." : vlText
                return (
                    <td className={[odd].join(' ')} key={idx}>{text}</td>
                )
            }
            return (
                <td className={[odd].join(' ')} key={idx}></td>
            )
        })
    }

    const buildData = ()=>{
        return (
            <tbody>
            {
                frameData.map((item,idx)=>{
                    return(
                        <tr key={idx} onClick={()=>{handleActiveRow(idx,item)}}>{buildDataLine(item,idx)}</tr>
                    )
                })

            }
            </tbody>
        )

    }


    const handleActiveRow = (idx:number, data:TableItemDataItemInt)=>{
        try {
            if(onRecord) onRecord(data)

        } catch (e) {

        }
        setActRow(idx)
    }



    const handleSetPage = (page:number) =>{
        //console.log("page",page)
        let pl = page
        if(vlPage < 1 || page < 1 ) pl = 1
        if(vlPage > pageCount || page > pageCount) pl = pageCount


        loadFrameData(pl-1)
        setVlPage(pl)
        setActRow(-1)




    }

    const buildFooter = () =>{
        const cp = tblColumn.reduce((acc,item)=>{
            if(item.mode.match(/h/ui)) return acc
            acc+=1
            return acc
        },0)
        return(
            <tfoot>
                <tr>
                    <td colSpan={cp}>
                        <div className={ styles.tiFootCont}>
                            <div className={styles.tiFotText}>[{tblData.length}]</div>
                            <div className={styles.tiFotText}>страница</div>
                            <div className={styles.tiFotArr} onClick={()=>{
                                handleSetPage(vlPage-1)
                            }}>
                                    <i className='bx bx-left-arrow'></i>
                            </div>
                            <div className={styles.tiPageIn}>
                                <input className="form-control form-control-sm" value={vlPage}
                                    inputMode={"numeric"}
                                    onBlur={(e)=>handleSetPage(vlPage)}
                                    onKeyDown={(e) =>{
                                        if(e.key.match(/enter/ui))handleSetPage(vlPage)
                                    }}
                                    onChange={(e) => setVlPage(Number(e.target.value))}
                                />
                            </div>
                            <div className={styles.tiFotArr} onClick={()=>{
                                handleSetPage(vlPage+1)

                            }}>
                                <i className='bx bx-right-arrow'></i>
                            </div>
                            <div className={styles.tiFotText}>из {pageCount}</div>
                            {onReload &&
                                <div className={styles.tiFotReload} onClick={()=>{
                                    if(onReload)onReload()
                                    }}>
                                    <i className='bx bx-refresh'></i>
                                </div>
                            }



                        </div>
                    </td>
                </tr>
            </tfoot>
        )
    }


    return(
        <div className={"row w-100"}>
            <div className={styles.tiCont}>
                <table className={styles.tiTable}>
                    {buildHead()}
                    {buildFilter()}
                    {buildData()}
                    {buildFooter()}
                </table>
            </div>

        </div>
    )
}