import styles from './ScanRemains.module.css'
import InputItem from "../../FormTemplate/InputItem/InputItem";
import React, {useEffect, useRef, useState} from "react";
import TableItem, {TableItemDataItemInt} from "../../FormTemplate/TableItem/TableItem";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import ActiveRecordItem from "../../FormTemplate/ActiveRecordItem/ActiveRecordItem";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
export default function ScanRemains()
{

    const errorLineRef = useRef<ErrorLineItemRef>(null)
    const testData = useRef()
    const [ldrTestData,rsTestData,exTestData] = useApiQuery("/api/application/user/list","get",testData);

    const updData = useRef({
        id:-1,
        fieldList:new Array<string>,
        valList:new Array<any>
    })
    const [ldrUpdData,rsUpdData,exUpdData] = useApiQuery("/api/application/user/update","post",updData);


    const [dataItem, setDataItem] = useState<TableItemDataItemInt>()

    const [tData, setTData] = useState([])

    const [errLine,setErrLine] = useState({
        isError: false,
        show: false,
        text: ""
    })


    useEffect(()=>{
        loadData()
    },[testData])

    useEffect(()=>{
        console.log(dataItem)
    },[dataItem])


    const loadData= ()=>{
        exTestData().then((e)=>{
            if(e?.success !== undefined && e.success)
            {
                //console.log("set payload")
                setTData(e?.payload)
            }

        })

    }

    const handleUpdate = () =>{
        if(dataItem?.id === undefined) return
        updData.current.id = dataItem?.id
        updData.current.fieldList= []
        updData.current.valList=[]

        updData.current.fieldList.push("coinRemains")
        updData.current.valList.push(Number(dataItem?.coinRemains))

        updData.current.fieldList.push("active")
        updData.current.valList.push(Number(dataItem?.active ? true: false))


        exUpdData().then((e)=>{
            console.log(e)
            if(e?.success !== undefined && e.success)
            {
                if(errorLineRef.current !== null)errorLineRef.current?.show("Задача выполнена",false,2000)
                loadData()
                return
            }
            if(errorLineRef.current !== null)errorLineRef.current?.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,2000)

        })



    }

    const handleUpRemains = (val: string) =>{
        if(dataItem?.id === undefined) return

        updData.current.id = dataItem?.id
        updData.current.fieldList= []
        updData.current.fieldList.push("coinRemains")
        updData.current.valList=[]
        updData.current.valList.push(Number(val))
        exUpdData().then((e)=>{
            console.log(e)
            if(e?.success !== undefined && e.success)
            {
                if(errorLineRef.current !== null)errorLineRef.current?.show("Задача выполнена",false,2000)
                loadData()
                return
            }
            if(errorLineRef.current !== null)errorLineRef.current?.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,2000)

        })


    }
    return(
            <div className={[styles.mainCont].join(' ')}>

                    <MainFormTemplate title={"Данные о пользователях"} maxWidth={"800px"}>
                        <ActiveRecordItem active={dataItem?.id ? true : false}></ActiveRecordItem>
                        <div className={"row"}>
                            <div className={"col-sm-6"}>
                                <InputItem
                                    val={dataItem?.user}
                                    mode={""}
                                    title={"Пользователь"}
                                    info={"Только число"}
                                    onBlur={(e)=>setDataItem({...dataItem,user:e})}
                                    regExp={/^.*$/}/>

                            </div>
                            <div className={"col-sm-6"}>
                                <InputItem
                                    val={dataItem?.pass}
                                    mode={""}
                                    title={"Пароль"}
                                    info={"Только число"}
                                    //startEdit={true}
                                    onBlur={(e)=>console.log(e)}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-6"}>
                                <InputItem
                                    val={dataItem?.coinRemains}
                                    mode={"eui"}
                                    info={"Число [0-99]"}
                                    title={"Остаток сканов"}
                                    //startEdit={true}
                                    onBlur={(e)=>
                                    {
                                        setDataItem({...dataItem,coinRemains:e})
                                        //handleUpRemains(e)
                                    }}
                                    onUpload={(e)=>handleUpRemains(e)}
                                    regExp={/^[0-9]{1,3}$/}/>
                            </div>
                            <div className={"col-sm-6"}>
                                <InputItem
                                    val={dataItem?.coinTotal}
                                    mode={""}
                                    info={"Описание текст"}
                                    title={"Всего сканов"}
                                    //startEdit={true}
                                    onBlur={(e)=>
                                    {
                                        setDataItem({...dataItem,memo:e})
                                        //handleUpRemains(e)
                                    }}
                                    regExp={/^[0-9]{1,3}$/}/>
                            </div>
                        </div>


                        <div className={"row"}>
                                <div className={"col-sm-6"}>
                                    <InputItem
                                        val={dataItem?.active}
                                        mode={"e"}
                                        inputType={1}
                                        info={"Число [0-1]"}
                                        title={"Активен"}
                                        //startEdit={true}
                                        onBlur={(e)=>
                                        {
                                            console.log("checkbox",e)
                                            setDataItem({...dataItem,active:e})
                                            //handleUpRemains(e)
                                        }}
                                        //onUpload={(e)=>handleUpRemains(e)}
                                    />

                                </div>




                        </div>
                        <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>
                        <div className={"row"}>
                            <div className={"col-sm-2 d-flex align-self-center"}>
                                <div className={styles.btnCont}>
                                    <button type="button" className="btn btn-secondary "
                                            disabled={dataItem?.id ? false: true}
                                            onClick={()=>handleUpdate()}
                                    >Обновить</button>
                                    <WaitLoader loading={ldrUpdData} api={rsUpdData} onlyLoading={true} >
                                    </WaitLoader>

                                </div>
                            </div>
                        </div>


                    </MainFormTemplate>

                    <WaitLoader loading={ldrTestData || ldrUpdData} api={rsTestData} onlyLoading={true}>
                            <TableItem
                                onPageCount={20}
                                column={[
                                    {
                                        title: "Пользователь",
                                        fieldId: "user",
                                        mode: "sf",
                                        type: 0

                                    },
                                    {
                                        title: "Пароль",
                                        fieldId: "pass",
                                        mode: "s",
                                        type: 6

                                    },
                                    {
                                        title: "Описание",
                                        fieldId: "memo",
                                        mode: "sf",
                                        type: 0

                                    },
                                    {
                                        title: "Дата рождения",
                                        fieldId: "birthDay",
                                        mode: "sh",
                                        type: 2
                                    },
                                    {
                                        title: "Остаток",
                                        fieldId: "coinRemains",
                                        mode: "s",
                                        type: 1
                                    },
                                    {
                                        title: "Всего",
                                        fieldId: "coinTotal",
                                        mode: "s",
                                        type: 1
                                    },
                                    {
                                        title: "Акт",
                                        fieldId: "active",
                                        mode: "s",
                                        type: 5
                                    }

                                ]}

                                data={tData}
                                onReload={()=>loadData()}
                                onRecord={(data)=>{
                                    setDataItem(data)
                                }}

                            ></TableItem>

                    </WaitLoader>

            </div>


    )
}