import styles from './SysOption.module.css'
import ActiveRecordItem from "../../FormTemplate/ActiveRecordItem/ActiveRecordItem";
import React, {useEffect, useRef, useState} from "react";
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import WaitLoader from "../../WaitLoader/WaitLoader";
import TableItem from "../../FormTemplate/TableItem/TableItem";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
export interface TableItemDataItemInt
{
    [key:string]:any
}


export default function SysOption()
{
    const [dataItem, setDataItem] = useState<TableItemDataItemInt>()
    const errorLineRef = useRef<ErrorLineItemRef>(null)

    const updData = useRef({
        id:-1,
        fieldList:new Array<string>,
        valList:new Array<any>
    })
    const [ldrUpdData,rsUpdData,exUpdData] = useApiQuery("/ref/sysoption/update","post",updData);


    const [optData, setOptData] = useState<any[]>([])
    const optionData = useRef()
    const [ldrOption,rsOption,exOption] = useApiQuery("/ref/sysoption","get",optionData);

    useEffect(()=>{
        loadData()
    },[optionData])

    const loadData = ()=>{
        exOption().then((e)=>{
            if(e?.success)
            {
                setOptData(e?.payload)
            }
        })

    }

    const handleUpdate = () =>{
        if(dataItem?.id === undefined) return
        updData.current.id = dataItem?.id
        updData.current.fieldList= []
        updData.current.valList=[]

        updData.current.fieldList.push("val")
        updData.current.valList.push(Number(dataItem?.val.toString()))

        console.log(updData.current)

        exUpdData().then((e)=>{
            console.log(e)
            if(e?.success !== undefined && e.success)
            {
                if(errorLineRef.current !== null)errorLineRef.current?.show("Задача выполнена",false,2000)
                loadData()
                return
            }
            if(errorLineRef.current !== null)errorLineRef.current?.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,2000)

        })


    }


    return(
        <div className={[styles.mainCont].join(' ')}>
            <MainFormTemplate title={"Переменные среды"} maxWidth={"800px"}>
                <ActiveRecordItem active={dataItem?.id ? true : false}></ActiveRecordItem>

                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={dataItem?.name}
                            mode={""}
                            title={"Параметр"}
                            />
                    </div>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={dataItem?.val}
                            mode={"ei"}
                            info={"Значение"}
                            title={"Значение"}
                            regExp={/^[\w\W]+/ui}
                            lineCount={2}
                            inputType={2}
                            onBlur={(e)=>setDataItem({...dataItem,val:e})}

                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <InputItem
                            val={dataItem?.desc}
                            mode={""}
                            title={"Параметр"}
                            inputType={2}
                            lineCount={3}
                        />
                    </div>
                </div>
                <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>
                <div className={"row"}>
                    <div className={"col-sm-2 d-flex align-self-center"}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                    disabled={dataItem?.id ? false: true}
                                    onClick={()=>handleUpdate()}
                            >Обновить</button>
                            <WaitLoader loading={ldrUpdData} api={rsUpdData} onlyLoading={true} >
                            </WaitLoader>

                        </div>
                    </div>
                </div>


            </MainFormTemplate>
            <WaitLoader loading={ldrOption} api={rsOption} onlyLoading={true}>
                <TableItem
                    onPageCount={30}
                    column={[
                        {
                            title: "Параметр",
                            fieldId: "name",
                            mode: "sf",
                            type: 0

                        },
                        {
                            title: "Значение",
                            fieldId: "val",
                            mode: "",
                            type: 0

                        },
                        {
                            title: "Описание",
                            fieldId: "desc",
                            mode: "sf",
                            type: 0,
                            maxLen: 60

                        },

                    ]}

                    data={optData}
                    onReload={()=>loadData()}
                    onRecord={(data)=>{
                        setDataItem(data)
                    }}

                ></TableItem>

            </WaitLoader>


        </div>
    )
}