import styles from './ModalWindow.module.css'
import {RiCloseLine} from "react-icons/ri";
import React, {Children, ReactNode} from "react";
import {useNavigate} from "react-router-dom";

interface ModalWindowCloseInt
{
    ():void
}
interface ModalWindowPropsInt
{
    onClose?:ModalWindowCloseInt
    children: ReactNode| undefined
}

function ModalWindow({children,onClose}:ModalWindowPropsInt)
{
    const navigate = useNavigate()
    const onCloseNav = ()=>{
        if(onClose) onClose()
    }

    const handleClick = (e:any) => {
        console.log(e.target.id);
        if(e?.target?.id ==="modalCenteredWindow")onCloseNav()
    };
    return (
        <>
            <div className={styles.darkBG} onClick={()=>{onCloseNav()}}></div>
            <div id={"modalCenteredWindow"} className={styles.centered } onClick={(e)=>handleClick(e)}>
                {children}
            </div>
        </>

    )
}

export default ModalWindow;