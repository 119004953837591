import styles from './Slide.module.css'
import lgProtect from '../Img/Card/protect.png'
import lgTraffic from '../Img/Card/traffic.png'
import lgTether from '../Img/Card/tethet.png'
import lgUnlim from '../Img/Card/unlim.png'

import snMessage from "../Img/Card/messager.png"
import snMail from "../Img/Card/mail.png"
import lgApMoney from "../Img/Card/apPrice.png"
import lgNoLock from "../Img/Card/nolock.png"
import lgMNP from "../Img/Card/mnp.png"

import lgSimYota from "../Img/Oper/sim_yota.png"
import lgSimMegafon from "../Img/Oper/sim_mega.png"
import lgSimMts from "../Img/Oper/sim_mts.png"
import lgSimRtk from "../Img/Oper/sim_rtk.png"
import lgSimBeeline from "../Img/Oper/sim_bee.png"
import lgSimT2 from "../Img/Oper/sim_t2.png"
import React, {useContext, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSlider} from "../Reducer/GlobalReducer";




interface SlideInt
{
    item:any,
    key:number
}
export default function Slide(props:SlideInt) {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const item =
        {
            name: props?.item?.name,
            tfId:props?.item?.tfId,
            descFile:props?.item?.descFile,
            operId: props?.item?.operId,
            optList: props?.item?.optList,
            snList:props?.item?.snList,
            regionIdList: props?.item?.regionIdList,
            ap: props?.item?.apList,
            net:props?.item?.netList,
            packet: props?.item?.packetList,
            operOne:props?.item?.operOne,
            smsCount:props?.item?.smsCount,
            type:props?.item?.type,
            price:props?.item?.price,
            regMode:props?.item?.regMode,
            bundleId: props?.item?.bundleId,
        }
//    document.documentElement.style.setProperty('--slide-cnt', '3');

    if(!Array.isArray(item?.packet) || !Array.isArray(item?.ap) || !Array.isArray(item?.net))return null

    const lineStyle=(cur:number, max:number, color:string)  =>{
        const per = Math.floor(cur/(max)*100) < 100 ? Math.floor(cur/(max)*100) : 100;
        return {background: `linear-gradient(to right, ${color} 0%, ${color} ${per+1}%, #ebebeb ${per}%, #ebebeb 100%)`}
    }


    const buildOptList = ()=>{
        if(! item.optList) return null
        const opt = item.optList.split(';').map((opt:string, key:number)=>{
            if(opt.match(/^pr$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgProtect}></img>
                        <span>Защита от подписок</span>
                    </div>
                )
            }
            if(opt.match(/^gb$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgTraffic}></img>
                        <span>Перенос остатков гигабайт</span>
                    </div>
                )
            }
            if(opt.match(/^gbm$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgTraffic}></img>
                        <span>Перенос остатков минут и гигабайт</span>
                    </div>
                )
            }
            if(opt.match(/^min$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgTraffic}></img>
                        <span>Перенос остатков минут</span>
                    </div>
                )
            }
            if(opt.match(/^tt$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgTether}></img>
                        <span>Раздача в модеме</span>
                    </div>
                )
            }
            if(opt.match(/^nl$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgNoLock}></img>
                        <span>Не блокируем при нуле</span>
                    </div>
                )
            }
            if(opt.match(/^mp$/ui))
            {
                return(
                    <div key={key} className={styles.optContainer}>
                        <img className={styles.optImage} src={lgMNP}></img>
                        <span>Переход со своим номером</span>
                    </div>
                )
            }




        })
        return opt;

    }

    const buildUnlimOper= () =>{
        if(! item.optList) return null
        const opt = item.optList.split(';').map((opt:string, key:number)=>{
            if(opt.match(/^un$/ui))
            {
                return(
                    <div key={key} className={styles.unlimOper}>
                        <img className={styles.optImage} src={lgUnlim}></img>
                        <span>Безлимит на {item.operId}</span>
                    </div>
                )
            }
        })
        return opt

    }


    const buildUnlimList =()=>{
        if(! item.snList) return null
        const img = item.snList.split(';').map((opt:string,key:number)=>{
            if(opt.match(/^ms$/ui))
            {
                return(
                        <img key={key} className={styles.optImage} src={snMessage}></img>
                )
            }
            if(opt.match(/^ml$/ui))
            {
                return(
                    <img key={key} className={styles.optImage} src={snMail}></img>
                )
            }


        });
        if(! img.length) return null
        return (
            <>
                <div className={styles.optContainer}>
                    <span className={styles.marginRight}>Безлимит на :</span>
                    {img}
                </div>
            </>
        )

    }

    const getOperLogo = (operId: string)=>{

//        const [pic, setPic] = useState([lgSimMegafon,lgSimT2,lgSimYota,lgSimBeeline,lgSimMts,lgSimRtk])

        if(operId === undefined) return lgSimMegafon
        if(operId.match(/mts/ui)) return lgSimMts
        if(operId.match(/beeline/ui)) return lgSimBeeline
        if(operId.match(/rtk/ui)) return lgSimRtk
        if(operId.match(/megafon/ui)) return lgSimMegafon
        if(operId.match(/yota/ui)) return lgSimYota
        if(operId.match(/tele2/ui)) return lgSimT2
        return lgSimMegafon
    }

    let lgSim = getOperLogo(item?.operId)


    const buildSlide = () =>{

        return (
                <div className={styles.slide}>
                    <div className={styles.header}>{item.name}
                        <div className={styles.simImageCon}><img className={styles.simImage} src={lgSim}/></div>

                    </div>
                    <div className={styles.apContainer}>
                        <img src={lgApMoney} className={styles.apImage}/>
                        <span className={styles.apVal}>{item?.ap[0]?.val}</span>
                        <div className={styles.apText}>
                            <span>руб./месяц</span>
                        </div>

                    </div>

                    <div className={styles.packetContainer}>
                        <div className={styles.pText}>
                            <span>Интернет</span>
                            <span>{item?.net[0]?.val+" Гб"}</span>

                        </div>
                        <input className={styles.rangeClass} type="range" defaultValue="40" style={lineStyle(item?.net[0]?.val,150,"rgb(95, 00, 152,0.81)")}/>
                    </div>


                    {(item.type === 0) && <div className={styles.packetContainer}>
                        <div className={styles.pText}>
                            <span>Звонки</span>
                            <span>{item?.packet[0]?.val+" минут"}</span>

                        </div>

                        <input className={styles.rangeClass} type="range" defaultValue="70" style={lineStyle(item?.packet[0]?.val,2000,"rgb(0, 152, 95,0.81)")}/>
                        {buildUnlimOper()}
                    </div>}

                    {(item.type === 1) && <div className={styles.packetContainer}>
                        <div className={styles.pTextInt}>
                            <span>Тариф только для интернета</span>
                        </div>
                        <div className={styles.pText}>
                            <span>Звонки</span>
                            <span>{"0 минут"}</span>
                        </div>
                        <input className={styles.rangeClass} type="range" defaultValue="70" style={lineStyle(item?.packet[0]?.val,2000,"rgb(0, 152, 95,0.81)")}/>
                    </div>}


                    <div className={styles.smsContainer}>
                        <span>{item.smsCount+" "} СМС</span>
                    </div>

                    {buildUnlimList()}
                    <hr className={styles.hrStyle}></hr>
                    {buildOptList()}
                    <div style={{height:"100%"}}></div>
                    <hr className={styles.hrStyle}></hr>
                    <div className={styles.btnContainer}>
                        <div className={styles.btnSelect}
                            onClick={()=>{
                                dispatch(setSlider({dataCardItem: item}))
                                navigate('/order')

                            }}

                        >Подключить</div>
                        <div className={styles.lineAbout}
                            onClick={()=>{
                            //sliderContext.setCardDataItem(item)
                                dispatch(setSlider({
                                    dataCardItem:item,
                                    sliderMode:1
                                }))

                            }}>Подробнее
                        </div>

                    </div>

                </div>
            );

    }




    return (

            buildSlide()

    )
}


