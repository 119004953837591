import styles from './ActiveSimJob.module.css'
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import SortListItem, {SortListItemLineInt, SortListItemRefInt} from "../../FormTemplate/SortListItem/SortListItem";
import React, {useEffect, useRef, useState} from "react";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
import WaitLoader from "../../WaitLoader/WaitLoader";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import TableItem, {TableItemColumnInt} from "../../FormTemplate/TableItem/TableItem";

interface QueryInt
{
    [key:string]:any
}

export default function ActiveSimJob()
{

    const filterData = useRef<QueryInt>({})
    const [ldrFilterData,rsFilterData,exFilterData] = useApiQuery("/api/application/sim/activate/filter","post",filterData);
    const [tData, setTData] = useState([])




    const cDate = new Date()
    const fDay = new Date(cDate.getFullYear(),cDate.getMonth(),1)

    const [mode,setMode] = useState(0)

    const sortListItemRef = useRef<SortListItemRefInt>(null)
    const errorLineRef = useRef<ErrorLineItemRef>(null)
    const [filter,setFilter] = useState({
        dateFrom: fDay.toLocaleString().substring(0,10),
        dateTo: cDate.toLocaleString().substring(0,10)
    })

    const tableBaseColumn:TableItemColumnInt[] = [
        {
            title: "Пользователь",
            fieldId: "user",
            mode: "fs",
            type: 0
        },
        {
            title: "Оператор",
            fieldId: "oper",
            mode: "fs",
            type: 0
        },
        {
            title: "Номер",
            fieldId: "ctn",
            mode: "fs",
            type: 0
        },

        {
            title: "ICC",
            fieldId: "icc",
            mode: "s",
            type: 0
        },
        {
            title: "Дата активации",
            fieldId: "actDate",
            mode: "fs",
            type: 2
        },
        {
            title: "Количество",
            fieldId: "itemCount",
            mode: "s",
            type: 1
        },



    ]

    const [tableColumn, setTableColumn] = useState<TableItemColumnInt[]>(tableBaseColumn)

    const [fieldList, setFieldList] = useState<SortListItemLineInt[]>([
        {
            name: "пользователь",
            field: "user"
        },
        {
            name: "оператор",
            field: "oper"
        },
        {
            name: "Дата Активации",
            field: "actDate"
        },

    ])

    useEffect(()=>{
        console.log(fieldList)
    },[fieldList])

    const loadData = () =>{
        exFilterData().then((e)=>{
            if(e?.success !== undefined && e.success)
            {
                setTData(e?.payload)

            }


        })

    }


    const normDate = (vl: any)=>{
        let val = typeof  vl === "string" ? vl.trim() : vl.toString()
        if(val?.match(/^[0-9]{8}$/ui))
            return val.substring(0,2) + "." + val.substring(2,4) + "." + val.substring(4,8)
        if(val?.match(/^[0-9]{2}.[0-9]{2}.[0-9]{4}$/ui))
            return val.substring(0,2) + "." + val.substring(3,5) + "." + val.substring(6,10)
        return val
    }

    const locDateToISO= (date:string):string =>  {
        if(date.length < 10) return "0000-00-00"
        return date.substring(6,10)+"-"+date.substring(3,5)+"-"+date.substring(0,2)
    }

    useEffect(()=>{
    },[filterData])

    const handleReport = () =>{
        const grList = sortListItemRef.current !==null ? sortListItemRef.current.getGroupList() : []
        console.log("grList", grList)
        filterData.current["groupList"] = grList.length > 0 ? grList.map((item)=>item.field) : undefined
        filterData.current["dateTo"] = filter?.dateTo ? locDateToISO(filter?.dateTo) : undefined
        filterData.current["dateFrom"] = filter?.dateFrom ?  locDateToISO(filter?.dateFrom) : undefined
        let a:TableItemColumnInt[] = []
        a = grList.reduce((acc,item)=>{
            if(item.field.match(/user/ui))acc.push( tableBaseColumn[0])
            if(item.field.match(/oper/ui))acc.push( tableBaseColumn[1])
            if(item.field.match(/actDate/ui))acc.push( tableBaseColumn[4])
            return acc
        },a)
        a.push(tableBaseColumn[5])

        setTableColumn(a.length > 1 ? a : tableBaseColumn)
        console.log(filterData.current)
        loadData()



    }

    return(
        <div className={styles.mainCont} >
            <MainFormTemplate title={"Активации по приложению"} maxWidth={"600px"}>
                <div className={"row mb-3 align-items-center"}>
                    <div className={"col-sm-7"}>
                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <InputItem
                                    val={filter?.dateFrom}
                                    mode={"ei"}
                                    title={"Дата от"}
                                    info={"Дата ХХ.ХХ.ХХХХ"}
                                    onPreProcess={normDate}

                                    type={"numeric"}
                                    regExp={/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui}
                                    //startEdit={true}
                                    onBlur={(e)=>setFilter({...filter,dateFrom: e})}
                                />
                            </div>

                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <InputItem
                                    val={filter?.dateTo}
                                    mode={"ei"}
                                    onPreProcess={normDate}
                                    title={"Дата до"}
                                    info={"Дата ХХ.ХХ.ХХХХ"}
                                    type={"numeric"}
                                    regExp={/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/ui}
                                    //startEdit={true}
                                    onBlur={(e)=>setFilter({...filter,dateTo: e})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col-sm-5   align-items-end"}>
                        <SortListItem fieldList={fieldList} ref={sortListItemRef}/>
                    </div>
                </div>
                <div className={"row mb-3"}>
                </div>

                <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>
                <div className={"row"}>
                    <div className={"col-sm-2 d-flex align-self-center"}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                    onClick={()=>handleReport()}
                            >Поиск</button>

                        </div>
                    </div>
                </div>



            </MainFormTemplate>

            <WaitLoader loading={ldrFilterData} api={rsFilterData} onlyLoading={true}>
                <TableItem
                onPageCount={20}
                data={tData}
                column={tableColumn}
                ></TableItem>

            </WaitLoader>

        </div>
    )
}