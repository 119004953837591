import styles from './ErrorLineItem.module.css'
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";

export interface ErrorLineItemRef { show: (text: string, isError: boolean, timeout: number) => void; }
interface ErrorLineItemPropsInt
{
}
const  ErrorLineItem = forwardRef<ErrorLineItemRef,ErrorLineItemPropsInt>((ErrorLineItemPropsInt, ref)=>
{
    const [itmShow, setItmShow] = useState(false)
    const [itmError, setItmError] = useState(true)
    const [text, setText] = useState("")

    useImperativeHandle(ref, () => ({
        show : show
    }));




    const show = (text: string, isError: boolean, timeout: number) =>{
        setItmError(isError)
        setText(text)
        setItmShow(true)
        if(timeout > 0) setTimeout(()=>setItmShow(false), timeout)
    }

    return(
        <div className={"row"}>
            <div className={[itmShow ? "" : "d-none", itmError ? styles.isErr : styles.isGood, styles.errCont].join(' ')}>{text}</div>
        </div>
    )
})
export default ErrorLineItem