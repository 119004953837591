import {MutableRefObject, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ReduxState} from "../Reducer/GlobalReducer";
import {Reducer} from "redux";

export interface ApiQueryResponse
{
    success:boolean;
    code: number;
    errorAsString: string;
    payload: any;
}
export interface ApiQueryCallBack
{
     (data:any):void
}

export interface ApiQueryExecute
{
    ():Promise<ApiQueryResponse>
}

const useApiQuery = (query:string, type:string, data:MutableRefObject<any> , execute:ApiQueryCallBack = ()=>{}  ):[boolean,ApiQueryResponse, ApiQueryExecute] =>
{
    const [result, setResult] = useState<ApiQueryResponse>(
{
            success : false,
            code : -200,
            errorAsString: 'first start',
            payload : {}
         });

    const baseUrl = useSelector((state:ReduxState)  => {return state.baseUrl});
    const authToken = useSelector((state:ReduxState)  =>  {return state.authToken});

    const [isLoading, setLoading] = useState(false);
    let glData: any;
    async function run():Promise<ApiQueryResponse>{
//        console.log(`base url ${baseUrl}`)
//        console.log(`authToken ${authToken}`)
//        console.log("useApiQuery: start apiQuery",query,data);
        if(query === undefined || !query.match(/^\//ui))
        {
            setResult({ success: false, code: -201, errorAsString: 'Не верный запрос',payload: {}});
            return { success: false, code: -201, errorAsString: 'Не верный запрос',payload: {}};
        }
        if(!type?.match || !type?.match(/^(get)|(post)|(file)/ui))
        {
            setResult({success: false,code: -202,errorAsString: 'Не верный тип запроса',payload: {}});
            return {success: false,code: -202,errorAsString: 'Не верный тип запроса',payload: {}};
        }

        let jsonData;
        const fetchData = data?.current ? data.current : {}
        if(type.match(/(get)|(post)/ui))
        {
            try {
                jsonData = JSON.stringify(fetchData);
            } catch (e)
            {
                setResult({success: false,code: -203,errorAsString: 'Не верные входные данные',payload: {}});
                return {success: false,code: -203,errorAsString: 'Не верные входные данные',payload: {}};
            }
        } else if(type.match(/(file)/ui))
        {
            jsonData = fetchData;

        }

        console.log("data to server",jsonData);
        setLoading(true);
        //      console.log("url",baseUrl+query);
        const bearerAuth = authToken ? authToken : localStorage.getItem('authToken');

        let reqType = "post"
        if(type.match(/(file)|(post)/ui)) reqType="post"
        else reqType="get"


        const baseHeaders =
            {
                'Accept': 'application/json',
                'Connection' : 'keep-alive',
                'Authorization' : "Bearer " + bearerAuth
            }
        let headers;
        if(!type.match(/(file)/ui)) headers = {...baseHeaders,'Content-Type': "application/json"}
        else headers = baseHeaders
//        console.log("headers", headers)
        //jsonData.forEach((item:any)=>console.log(item))

        await fetch(baseUrl+query,
            {
                headers: headers,
                method: reqType,
                credentials: 'include', //Включаем передачу куки и тп
                mode: 'cors', //Используем политику cors
                body: type.match(/(post)|(file)/ui) ? jsonData : null
            }
        ).then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : null;
            glData = data;
            console.log(`useApiFetch: data `,data);
            if( data === null)
            {
                setResult({success: false,code: -204,errorAsString: 'Не корректные данные с сервера',payload: {}});
//                return [isLoading,result,run];
            }
            //console.log("response code", response.status,response.ok);

            setResult(data);
            return Promise.resolve();

        }).catch(error => {
            glData={success: false,code: -205,errorAsString: 'Ошибка запроса к серверу : '+ error.toString(),payload: {}}
            setResult({success: false,code: -205,errorAsString: 'Ошибка запроса к серверу : '+ error.toString(),payload: {}});
//            console.error('There was an error!', error);

        });
        setLoading(false);
        //console.log("return data");
        if(typeof execute === "function")execute(glData);
        return Promise.resolve(glData);
    };
    return [isLoading,result,run];
}
export default useApiQuery;
