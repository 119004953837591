import styles from './Footer.module.css'
export default function Footer(){
    return(
        <div className={styles.mainCont}>
            <div className={"row"}>
                <div className={"col-sm-4 align-items-center"}>
                    <div className={styles.blCont}>
                        <div className={styles.ctTitle}>Контакты</div>
                        <div>630120, г.Новосибирск, ул.Железнодорожная 15/1, оф 39 </div>
                        <div>ООО "ФОРУМ"</div>
                        <div>ИНН 1234567890</div>


                        <div className={styles.href}>Публичная оферта</div>
                        <div className={styles.href}>Политика обработки персональных данных</div>


                    </div>
                </div>

            </div>

        </div>
    )
}
