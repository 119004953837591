import styles from './LeftSideMenu.module.css'
import {ReactNode, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import useApiQuery from "../ApiQuery/UseApiQuery";
import WaitLoader from "../WaitLoader/WaitLoader";
import {Simulate} from "react-dom/test-utils";
import drop = Simulate.drop;
interface MenuItemInt
{
    id:number;
    parent:number;
    name:string;
    path: string;
    drop: boolean;
    subMenuCount: number
}

interface MenuDataItem
{
    id:number;
    parent: number;
    path: string
    name: string;
}

interface LeftSideMenuInt
{
    children: ReactNode| undefined
}
export default function LeftSideMenu({children}:LeftSideMenuInt)
{
    const navigate = useNavigate();

    const data = useRef({})
    const [ldrData,rsDataItem,executeLoadData] = useApiQuery("/menu/load","get",data);

    const [item, setItem]=useState<MenuDataItem[]>()


    async function loadData()
    {
        console.log("load menu data")
        const data = await executeLoadData()
        console.log(data)
        if(data.success ) setItem(data.payload)
    }

    useEffect(()=>{
        loadData().then()
    },[])


    const [menuItem, setMenuItem] = useState<MenuItemInt[]>()
    const [showFullMenu, setShowFullMenu] = useState(true)


    useEffect(()=>{
        setMenuItem( item?.map((item)=>{
            const cnt = getSubMenuCount(item.id) ;
            const sbCount = item.parent===0 ? ( cnt ? cnt: 0 ) : -1
            return {
                ...item,
                drop: false,
                subMenuCount: sbCount
            }
        }))
    },[item])


    const getSubMenuCount = (id:number) =>
    {
        if(item === undefined) return 0;
        return item.reduce((acc,item)=>{
            if(item?.parent === id)acc++
            return acc
        },0)
    }

    const getDropState = (id:number)=>
    {
        return  menuItem?.reduce((acc,item)=>{
            if(item.id === id) acc = item.drop
            return acc
        }, false)
    }

    const setDropState = (id:number, state:boolean)=>
    {
        setMenuItem(menuItem?.map((item)=>{
            if(item.id === id)item.drop = state;
            return item
        }))
    }




    const buildSumMenu = (parent:number)=>{
        if(menuItem === undefined) return null
        const subMenu = menuItem.map((item)=>{
            if(item.parent === parent)
                return(
                    <li key={item?.id}><a className={[styles.linkName].join(' ')} href="#"
                        onClick={()=>{navigate(item.path)}}
                    >
                        {item?.name}</a></li>
                )
            return null
        })
        return subMenu
    }

    const buildMenu=(level:number)=>{
        if(menuItem === undefined) return null
        const menu = menuItem.map((item)=>{
            if(item?.parent===0 && level===0)
            {
                const drop = getDropState(item?.id)
                return(
                    <li className={[styles.subMenu].join(' ')} key={item?.id} >
                        <div className={styles.firstBlock}
                             onClick={()=>{
                                 setDropState(item?.id,!drop)
                                 if(item.subMenuCount ===0) navigate(item.path)
                             }}
                        >
                            <a href="#">
                                <i className={['bx bx-grid-alt', styles.altGrid].join(' ')}></i>
                                <span className={[styles.linkName].join(' ')}>{item?.name}</span>
                            </a>
                            <i className={['bx bxs-chevron-down arrow ',drop ? styles.dropped : "", item?.subMenuCount<=0 ? styles.hide : "" ].join(' ')}></i>
                        </div>
                        <ul className={[styles.subMenu,styles.blank, (drop ? "" : styles.hide)].join(' ')}>
                            {buildSumMenu(item?.id)}
                        </ul>

                    </li>
                )

            }
        })

        return menu;


    }



    return(
        <WaitLoader loading={ldrData} api={rsDataItem} onlyLoading={false}>
        <div className={[styles.sideBarMenuContainer].join(' ')}>

            {
                !showFullMenu &&
                <div className={[styles.sideBarMenuContainer].join(' ')} onClick={()=>setShowFullMenu(true)}>
                    <i className={['bx bx-menu', styles.hideMenu].join(' ')}></i>
                </div>
            }


            {
                showFullMenu &&
                <div className={styles.sideBarMenu}>
                    <ul className={[styles.navLinks].join(' ')}>
                        {buildMenu(0)}
                    </ul>
                    <div className={styles.hideMenuContainer}
                        onClick={()=>{setShowFullMenu(false)}}
                    >
                        <i className={['bx bx-x', styles.hideMenu].join(' ')}></i>
                    </div>
                </div>
            }


            <div className={[styles.mainContent, showFullMenu ? styles.mainContentShift : ""].join(' ')}>
                {children}
            </div>

        </div>
        </WaitLoader>
    )
}
