import styles from './TarifPacketBuilder.module.css'
import {useEffect, useState} from "react";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import TableItem from "../../FormTemplate/TableItem/TableItem";
import filterRegExp from "../../Util/filterRegExp";
interface TarifPacketBuilderPropsInt
{
    data: any[]
    regionList: any[]
    name:string
    onDelete?: (id:number)=>void
    onClear?: ()=>void
    onChange?: (data: any[])=>void
}

interface TarifPacketBuilderRegionItemInt
{
    regionId:number
    name:string
    isMacro:boolean
}
export default function TarifPacketBuilder({data,regionList,onChange,name}:TarifPacketBuilderPropsInt)
{
    const [contentData, setContentData] = useState<any[]>([])

    const [baseVal,setBaseVal]= useState(0)
    const [fullRegionList, setFullRegionList] = useState<TarifPacketBuilderRegionItemInt[]>([])

    const [fltRegionList,setFltRegionList]= useState<TarifPacketBuilderRegionItemInt[]>([])

    const [filterRegionText,setFilterRegionText ] = useState("")

    const [filterResult,setFilterResult] = useState<TarifPacketBuilderRegionItemInt>()
    const [regionVal, setRegionVal] = useState(0)

    const [recordItem, setRecordItem] = useState<any>()


    useEffect(()=>{
        //console.log("fltr result ", filterResult)
    },[filterResult])

    useEffect(()=>{
        console.log("contentData eff", contentData)
        if(onChange)onChange(contentData.map((item)=>{
            item.val = Number(item?.val)
            return item
        }))
    },[contentData])

    useEffect(()=>{
        const vl = data?.reduce((acc,item)=>{
            if(item?.regionId === 0) acc = Number(item?.val)
            return acc
        },0)
        setBaseVal(vl)
        let rgList= new Array<TarifPacketBuilderRegionItemInt>
        regionList.map((item)=>{
            rgList.push({
                regionId: item?.macroRegionId,
                name: item?.name,
                isMacro: true
            })
            if(Array.isArray(item?.regionList))
            {
                const a = item?.regionList.map((itm:any)=>{
                    const a= {
                        regionId: itm?.regionId,
                        name: itm?.name,
                        isMacro:false
                    }
                    rgList.push(a)
                })
            }


        })
        setFullRegionList(rgList)
        setContentData(data.map((item:any)=>{
            return {
                regionId:item?.regionId,
                val: item?.val
            }
        }))


    },[data,regionList])


    const handleFilterRegion = (txt:string)=>{
        //console.log(txt)
        if(txt === undefined || txt===null)return
        setFilterRegionText(txt)
        if(txt?.length < 1) {
            setFltRegionList([])
            setFilterResult(undefined)
            return;
        };
        //console.log("use")

        const ap = fullRegionList.filter((item)=>{
            txt = filterRegExp(txt)
            //console.log(txt)
            const rg = new RegExp(txt,"ui")
            if(item.regionId.toString().match(rg))return true
            if(item.name.match(rg))return true
            return false
        })
        setFltRegionList(ap)
        //console.log(ap)
        //console.log(rgList)
    }

    const handleSetBaseVal = (val:number)=>{
        setBaseVal(val)
        setContentData(contentData.map((item)=>{
            if(item?.regionId === 0)item.val = val
            return item
        }))

    }

    const handleRegionFilterClick = (item:TarifPacketBuilderRegionItemInt) =>{
        setFilterRegionText(`[ ${item?.regionId} ] ${item.name}`)
        setFltRegionList([])
        setFilterResult(item)

    }
    const buildRegionFilter =() =>{
        if(! fltRegionList.length) return null
        const a = fltRegionList.map((item,idx)=>{
            return(
                <div key={idx} className={styles.fltLine}
                    onClick={(e)=>handleRegionFilterClick(item)}
                >{`[ ${item?.regionId} ] ${item.name}`}</div>
            )
        })
        return (
            <div className={styles.rgFilter}>{a}</div>
        )

    }


    const addRegionId = (regionId : number, val:number, rgList: any[])=>
    {
        let found = false
        let newData  = rgList.map((item)=>{
            if(item?.regionId === regionId)
            {
                item.val = val
                found = true
            }
            return item
        })

        if(! found ) newData = [...newData, {
            regionId: regionId,
            val: val
        }]
        return newData
    }

    const handleAdd = ()=>{
        const isCanAdd = filterResult?.name && regionVal ? true : false
        if(! isCanAdd) return

        //console.log(regionList)
        if(filterResult?.isMacro !== undefined && !filterResult?.isMacro)
        {
            setContentData(addRegionId(filterResult?.regionId, regionVal,contentData))
            return;
        }
        const macro = filterResult?.regionId
        //console.log(`add macro ${macro}`)
        regionList?.map((item)=>{
            //console.log(`item macro ${item?.macroRegionId}`)
            let rgData = contentData
            if(item?.macroRegionId === macro)
            {
                //console.log(`item macro list `,item?.regionList)
                item?.regionList?.map((itm:any)=>{
                    rgData = addRegionId(itm?.regionId,regionVal,rgData)
                })
                setContentData(rgData)
            }
        })





    }


    const isCanAdd = filterResult?.name && regionVal ? true : false
    const buildFilter = () =>{
        return(
            <div className={[styles.flCont].join(' ')}>
                <div className={[styles.flItem,,"w-100"].join(' ')}>
                    <div>Регион</div>
                    <div className={[styles.inpCont].join(' ')}>
                        <input className={"form-control form-control-sm"}
                            value={filterRegionText}
                            onChange={(e)=>handleFilterRegion(e.target.value)}
                        ></input>
                    </div>
                    {buildRegionFilter()}
                </div>
                <div className={[styles.flItem].join(' ')}>
                    <div>Значение</div>
                    <div className={[styles.inpCont, styles.inpContVal].join(' ')}>
                        <input className={"form-control form-control-sm"} value={regionVal}
                            onChange={(e)=>setRegionVal(Number(e.target.value))}
                        ></input>
                    </div>
                </div>
                <div className={[styles.flItem].join(' ')} onClick={()=>handleAdd()}>
                    <div className={[styles.addCont, isCanAdd ? "" : styles.dis].join(' ')}><i className='bx bx-plus-medical'></i></div>

                </div>


            </div>
        )
    }

    const handleRecord = (data:any)=>{
        if(data?.regionId !== undefined) setRecordItem(data)
        if(data?.regionId !== undefined && data.regionId === 0)
        {
            setBaseVal(data?.val)
        }
    }

    const handleDelete = (regionId:number) =>{
        if(regionId === undefined || regionId === 0) return
        //console.log(`delete ${regionId}`)
        setContentData(contentData.filter((item)=>{
            if(item?.regionId === regionId) return false
            return true
        }))

    }
    const handleClear = ()=>{
        setContentData(contentData.filter((item)=>{
            if(item?.regionId === 0) return true
            return false
        }))
    }

    const isSelected = recordItem?.regionId !== undefined ? true: false
    return(
        <div className={styles.mainCont}>
            <div>{name}</div>
            <InputItem
                val={baseVal}
                mode={"ei"}
                title={"Базовая стоимость"}
                regExp={/(^[1-9][0-9]+$)|(^0$)/ui}
                onBlur={(e)=>handleSetBaseVal(Number(e))}
            ></InputItem>
            {buildFilter()}
            <div className={styles.tableRow}>
                <div className={styles.tableCont}>
                    <TableItem
                        onPageCount={10}
                        data={contentData}
                        onRecord={(e)=>handleRecord(e)}
                        column={[
                            {
                                title: "Регион",
                                fieldId: "regionId",
                                mode: "fs",
                                type: 1,
                                //maxLen: 10
                            },
                            {
                                title: "Значение",
                                fieldId: "val",
                                mode: "fs",
                                type: 1,
                                //maxLen: 10
                            },
                        ]}
                    ></TableItem>
                </div>
                <div className={styles.buttCont}>

                    <div onClick={()=>handleDelete(recordItem?.regionId)} className={[styles.trBut,isSelected ? "" : styles.dis].join(' ')}><i  className='bx bxs-trash-alt'></i></div>
                    <div onClick={()=>handleClear()} className={[styles.trBut].join(' ')}><i className='bx bx-bracket'></i></div>
                </div>

            </div>
        </div>
    )
}