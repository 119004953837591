import {SliderContext} from "./Slider";
import {useContext} from "react";
import styles from './SlideArrow.module.css'
function SlideArrow()
{
    const context = useContext(SliderContext);
    if(context?.slidesCount === 0 ) return  null
    return (
        <div className={styles.arrows}>
            <div className={[styles.arrow , styles.left].join(' ')} onClick={() => context?.changeSlide(-1)} ></div>
            <div className={[styles.arrow , styles.right].join(' ')} onClick={() => context?.changeSlide(1)} />
        </div>
    );
}
export default SlideArrow