import styles from './OrderSimCard.module.css'
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setBaseRegion, setOrderData, setSlider} from "../Reducer/GlobalReducer";
import RegionChange from "../RegionChange/RegionChange";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import RegModeOne from "./RegModeOne/RegModeOne";


export default function OrderSimCard() {
    const dispatch = useDispatch();





    const navigate = useNavigate()
    const dataCardItem = useSelector((state: ReduxState) => {
        return state.slider.dataCardItem
    })
    const isRegionSelected = useSelector((state: ReduxState) => {
        return state.baseRegion.isRegionChanged
    })
    const region = useSelector((state: ReduxState) => {
        return state.baseRegion
    })
    const [moveToSelectRegion, setMoveToSelectRegion] = useState(false)

    useEffect(()=>{
        console.log("DataCardItem",dataCardItem)
        if(dataCardItem?.tfId /*&& ! region.makeSelectRegion*/)
        {
            dispatch(setOrderData({
                operId: dataCardItem?.operId,
                tariffId: dataCardItem?.tfId,
                regMode: dataCardItem?.regMode,
                macroRegionId:region?.macroRegionId,
                regionId: region?.regionId,
                name: dataCardItem?.name,
                macroRegionName: region?.name,
                price: dataCardItem?.price,
                bundleId: dataCardItem?.bundleId,
            }))
        }
        if(! dataCardItem?.tfId ) navigate('/')


    },[dataCardItem,region])


    if (!isRegionSelected)
        return (
            <div className={styles.orderCont}>
                <p className={styles.regText}>Для продолжения подключения Вам нужно выбрать регион Вашего нахождения, Это нужно для корректного выбора номера и
                    правильности работы тарифа в Вашем регионе, а так же верным параметрам тарифа в регионе
                </p>

                <div className={styles.btnSelect}
                     onClick={() => dispatch(setBaseRegion({makeSelectRegion: true}))}
                >Выбрать регион
                </div>



            </div>
        )



    if(dataCardItem?.regMode === 0)
        return (

            <div className={styles.orderCont}>
                <RegModeOne/>
            </div>

    )

    return null

}
