import React, {useContext, useEffect, useRef, useState} from "react";
import {SliderContext} from "./Slider";
import styles from './SliderList.module.css'
import Slide from "./Slide";
import useWindowSize from "../Hook/UseWindowSize";
import TariffFilter from "../TarifFilter/TariffFilter";

export default function SlideList() {
    const context = useContext(SliderContext);

    const showSlide = (slide: any ,ind:number)=>{
        const slNumber = Number(context?.slideNumber);
        //console.log(`slide number ${slNumber} cur ${ind} on screen ${context?.screenSlideCount}`)

        if(ind>=slNumber && ind <context?.screenSlideCount+slNumber) return (<Slide key={ind}  item={slide}/>)
        return null
    }
    //console.log("context", context)
    if(context?.slidesCount === 0)
        return (
            <div className={styles.sliderContainer}  >
                <div  className={styles.notFound}>По заданным условиям тарифов не найдено</div>
            </div>

        )
    return (
        <div className={styles.sliderContainer}  >
            <div key={context.slideNumber} className={[styles.sliderList, styles.header].join(' ')}  >
                {context?.itemList.map((slide:any, index:number) => {
                    //console.log(`index ${index} cnt ${Number(cnt) } slider ${context?.slideNumber}`)
                    return showSlide(slide,index)
                })
                }
            </div>
        </div>
    );
}


