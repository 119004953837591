import styles from './Header.module.css'
import logo from '../Img/Logo/logo.png'
import {useEffect, useRef} from "react";
import useApiQuery from "../ApiQuery/UseApiQuery";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSlider} from "../Reducer/GlobalReducer";
function Header()
{
    const navigate = useNavigate();
    const data = useRef({name:"Hello", id:[]})
    const onRec = (dt: any)=>{
        console.log("executed", dt)

    }
    const dispatch = useDispatch()

    const [ldTest,rs,run] = useApiQuery("/test","get",data,onRec );


    useEffect(()=>{
        //run().then((e:any)=>{console.log(e)})
    },[])
    return(

        <div className={[styles.header].join(' ')}>
                <div className={["item-1"].join(' ')} onClick={()=>{dispatch(setSlider({sliderMode:0}));navigate('/')}}>
                    <img src={logo} className={styles.logo}/>
                </div>
                <div className={[styles.sloganDivider].join(' ')}></div>
                <div className={["item-2"].join(' ')} onClick={()=>{
                    run();
                }}>
                    <h4 className={[styles.slogan].join(' ')}>Выгодные тарифы, которых не найти у оператора</h4>
                </div>
                <div className={styles.block1}>
                    <div className={styles.item3}>
                        <p>Напишите нам</p>
                        <a className={[styles.email].join(' ')} href={"mailto:sergey28_1979@mail.ru"}>sergey28_1979@mail.ru</a>
                    </div>
                    <div className={styles.item4}>
                        <p>Позвоните нам</p>
                        <a className={[styles.phone].join(' ')} href={"phone:+79612230534"}>8-800-222-5051</a>
                    </div>

                </div>




        </div>

    );
}
export default Header;