import styles from './ActiveSimInfo.module.css'
import React, {useRef, useState} from "react";
import ErrorLineItem, {ErrorLineItemRef} from "../../FormTemplate/ErrorLineItem/ErrorLineItem";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";
import InputItem from "../../FormTemplate/InputItem/InputItem";
import WaitLoader from "../../WaitLoader/WaitLoader";
export default function ActiveSimInfo()
{
    const [mode,setMode] = useState(0)
    const errorLineRef = useRef<ErrorLineItemRef>(null)
    const simData = useRef({
        ctn:""
    })

    const [validCtn,setValidCtn] = useState(false)

    const [ctnInfo,setCtnInfo] = useState({
        ctn:"",
        icc:"",
        family:"",
        name: "",
        middleName : "",
        doc: "",
        docType:"",
        docDate:"",
        user:"",
        oper:"",
        birthDate:"",
        actDate:"",
        dummyPass:false

    })

    const [ctn,setCtn] = useState("")
    const [ldrSimData,rsSimData,exSimData] = useApiQuery("/api/application/sim/activate/info","post",simData);

    const handleFind = () =>{
        if(!ctn.match(/9[0-9]{9}/ui))
        {
            if(errorLineRef.current !== null)errorLineRef.current?.show("Не корректный номер",true,2000)
            return

        }

        simData.current.ctn = ctn
            //setCtn("")
        exSimData().then((e)=>{
            console.log(e)
            if(e?.success !== undefined && e.success)
            {
                if(errorLineRef.current !== null)errorLineRef.current?.show("Задача выполнена",false,2000)
                setMode(1)
                const passData = e?.payload?.passData?.split(';')
                if(!Array.isArray(passData))
                {
                    setMode(2)
                    return;
                }
                const dummy = passData[10] === undefined ? true : Number(passData[10]) === 0 ? false : true
                console.log("dummy" ,passData[10],dummy)

                setCtnInfo({...ctnInfo,
                    ctn: e.payload?.ctn,
                    icc: e.payload?.icc,
                    family: passData[0],
                    name : passData[1],
                    middleName: passData[2],
                    doc: passData[6]+passData[7],
                    docDate: new Date(passData[8]).toLocaleString().substring(0,10),
                    birthDate: new Date(passData[3]).toLocaleString().substring(0,10),
                    docType: passData[5]?.match(/r/ui) ? "РФ" : "Иностранный",
                    user: e.payload?.user,
                    oper: e.payload?.oper,
                    actDate: new Date(e.payload?.actDate).toLocaleString().substring(0,10),
                    dummyPass: dummy
                })






                return
            }
            if(errorLineRef.current !== null)errorLineRef.current?.show(e?.errorAsString ? e.errorAsString : "Ошибка сервера",true,2000)
        })
    }

    const normCtn = (vl:any):string =>{
        const val = typeof vl === "string" ? vl.trim() : vl.toString()
        const kl = val.match(/9[0-9]{9}$/ui)
        if(kl) return kl[0]
        return  val
    }
    const handleBlur = (e:string) =>{
        setCtn(e)
        setValidCtn(true)
    }

    return(
        <div className={[styles.mainCont].join(' ')}>

            <MainFormTemplate title={"Поиск SIM карты"} maxWidth={"1500px"}>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <InputItem
                            val={ctn}
                            type={"numeric"}
                            mode={"ei"}
                            title={"Номер"}
                            onPreProcess={normCtn}
                            info={"10 цифр"}
                            onBlur={(e)=>handleBlur(e)}
                            onIncorrect={(e)=>{
                                setValidCtn(false)
                                setCtn(e)
                            }}
                            regExp={/^[0-9]{10}$/}/>
                    </div>
                    <div className={"col-sm-2 d-flex align-self-center"}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-secondary "
                                    disabled={!validCtn}
                                    onClick={()=>handleFind()}
                            >Поиск</button>
                            <WaitLoader loading={ldrSimData} api={rsSimData} onlyLoading={true} >
                            </WaitLoader>

                        </div>
                    </div>
                    <ErrorLineItem ref={errorLineRef} ></ErrorLineItem>

                    {mode ===1 &&
                    <div>
                        <div className={"row"}>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.dummyPass}
                                    mode={""}
                                    title={"Фиктивный документ"}
                                    inputType={1}

                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.ctn}
                                    mode={""}
                                    title={"Номер"}
                                    />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.icc}
                                    mode={""}
                                    title={"ICC"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.actDate}
                                    mode={""}
                                    title={"Дата активации"}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.family}
                                    mode={""}
                                    title={"Фамилия"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.name}
                                    mode={""}
                                    title={"Имя"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.middleName}
                                    mode={""}
                                    title={"Отчество"}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.doc}
                                    mode={""}
                                    title={"Документ"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.docDate}
                                    mode={""}
                                    title={"Выдан"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.docType}
                                    mode={""}
                                    title={"Тип"}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.birthDate}
                                    mode={""}
                                    title={"Дата рождения"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.oper}
                                    mode={""}
                                    title={"Оператор"}
                                />
                            </div>
                            <div className={"col-sm-4"}>
                                <InputItem
                                    val={ctnInfo.user}
                                    mode={""}
                                    title={"Пользователь"}
                                />
                            </div>
                        </div>
                    </div>
                    }

                </div>


            </MainFormTemplate>
        </div>
    )
}