import styles from './SelectNumber.module.css'
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setBaseRegion, setOrderData} from "../../Reducer/GlobalReducer";
import React, {useEffect, useRef, useState} from "react";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import exp from "constants";
import {useNavigate} from "react-router-dom";

export interface SimCardUnitInt
{
    ctn: string
    icc: string
    isSelected:boolean;
}

interface NextButtonCallBackInt
{
    (data:SimCardUnitInt):void
}

interface SelectNumberPropsInt
{
    cbExecute?: NextButtonCallBackInt
}
interface GetNumberListInt
{
    tariffId?:string
    operId?:string
    macroRegionId?:number
    regionId?:number
    categoryId?:number
    bundleId?:string
}

interface CtnItemInt
{
    sim: SimCardUnitInt;
    selected:boolean
}

export default function SelectNumber({cbExecute}:SelectNumberPropsInt)
{
    const orderData = useSelector((state: ReduxState) => {
        return state.orderData
    })

    const dispatch = useDispatch()

    const ctnData = useRef<GetNumberListInt>()
    const [ldrCtnData,rsCtnData,exCtnData] = useApiQuery("/sell/sim/reserve/new","post",ctnData);

    const resSellData = useRef({
        icc:""
    })
    const [ldrResSell,rsResSell,exResSell] = useApiQuery("/sell/sim/reserve/sell","post",resSellData);


    const navigate = useNavigate()

    const [ctnList, setCtnList]= useState<SimCardUnitInt[]>([])
    const [ctnItem,setCtnItem] = useState<CtnItemInt[]>([])
    const [slCtn, setSlCtn] = useState<SimCardUnitInt>()

    const [stage, setStage] = useState(0)
    const [stRes, setStRes] = useState("")



    useEffect(()=>{
        switch (stage)
        {
            case 0://Старт лоад дата
                loadData()
                break
            case 1: // ошибка резервирования
                setStRes(`Ошибка резервирования номера ${slCtn?.ctn},  возможно номер уже занят, выберите другой`)
                loadData()
                break
            case 2://Выбрался номер
                if(cbExecute && slCtn) {
                    dispatch(setOrderData({...orderData,selectedNumber: slCtn}))
                    cbExecute(slCtn)
                }
                break
            case 3://Нет номеров в списке переход далее
                setStRes("Нет свободных номеров, номер будет присвоен позже")
                //if(cbExecute)cbExecute({isSelected: false})
                break
            default:
                loadData()

        }


    },[stage])


    useEffect(()=>{
        setCtnItem(
            ctnList.map((item,idx)=>{
                const itm:CtnItemInt =
                    {
                        sim: item,
                        selected: idx === 0
                    }
                    if(itm.selected) setSlCtn({...itm.sim, isSelected: true})
                return itm
            })
        )

    },[ctnList])

    useEffect(()=>{

        console.log("SELECTED CTN",slCtn)

    },[slCtn])
    const loadData = ()=>{
        ctnData.current = {
            regionId : orderData?.regionId,
            operId : orderData?.operId,
            macroRegionId : orderData?.macroRegionId,
            tariffId : orderData?.tariffId,
            bundleId : orderData?.bundleId,

        }

        exCtnData().then((e)=>{
            if(e?.success )
            {
                if(e?.payload && Array.isArray(e.payload) && e.payload.length ===0)
                {
                    setStage(3)
                    return
                }
                setCtnList(e?.payload)
            } else {
                setStRes(e?.errorAsString)
                setStage(3)
            }

        }).catch((e)=>{
            setStage(3)
        })
    }



    const setSelected = (id: number)=>{
        setCtnItem(
            ctnItem.map((itm,idx)=>{
                if(idx === id)
                {
                    itm.selected = true
                    setSlCtn({...itm.sim, isSelected: true})
                }
                else itm.selected = false
                return itm
            })

        )
    }

    const sendResult = () =>{
        //if(rpm.isSelected && cbExecute)cbExecute(rpm)
        if(!slCtn){
            setSlCtn({
                ctn:"",
                isSelected: false,
                icc:""
            })
            setStage(2)

        }
        if(slCtn)resSellData.current = slCtn
        exResSell().then((e)=>{
            if(e?.success)
            {
                setStage(2)

            } else setStage(1)
        }).catch((e)=>{
            setStage(1)
        })


    }

    const buildCtnList = ()=>{
        return  ctnItem.map((item,key)=>{
            return(
                <div className={styles.ctnItem} key={key}
                    onClick={()=>{
                        setSelected(key)

                }}
                >
                    <div className={[styles.ctnIcon, item.selected ? styles.ctnIconSelected :"" ].join(' ')}></div>
                    <div>{item?.sim?.ctn}</div>
                </div>
            )
        })
    }

    //if(ctnList.length===0)sendResult();
    return(
        <WaitLoader loading={ldrCtnData} api={rsCtnData} onlyLoading={true} showError={true}>

            <div className={styles.ctnCont}>
                <p className={styles.numTitle}>Для данного тарифа и региона доступны следующие номера, Вы можете выбрать номер, после этого он резервируется на 30 минут,
                в течении этого срока Вам необходимо закончить процедуру оформления. Выберите номер из списка ниже</p>
                <div className={styles.ctnCont}>{buildCtnList()}</div>
                {(stage===1 || stage===3) && <p className={styles.errSt}>{stRes}</p>}


                <div className={styles.btnCont}>
                    <div className={styles.btnSelect}
                        onClick={()=>{
                            sendResult()
                        }}
                    >Далее
                    </div>
                    <div className={styles.btnSelect}
                         onClick={()=>{
                             navigate('/')
                         }}
                    >Назад
                    </div>
                </div>
            </div>
        </WaitLoader>
    )
}