import styles from './Review.module.css'
import React, {useEffect, useRef, useState} from "react";
import capImage from '../../Img/capture.png'
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import {text} from "stream/consumers";
import useFormValidator, {FormValidatorInt} from "../../FormValidator/FormValidator";


interface SetReviewCountInt
{
    (cnt:number):void
}


interface ReviewPropsInt
{
    tariffId:string
    setReviewCount: SetReviewCountInt
}
interface TopicListInt
{
    author: string;
    date: string;
    text: string;
    reply: TopicListInt[]
}
interface NewTopicInt
{
    tariffId:string
    author:string
    capt:string
    text:string
    eMail?:string
}

export default function Review({tariffId,setReviewCount}:ReviewPropsInt)
{

    const data = useRef({
        tariffId:tariffId
    })
    const [ldrData,rsDataItem,executeLoadData] = useApiQuery("/tariff/review/list","post",data);


    const dataCapt = useRef({
        name: "topicCapt",
        len:4
    })
    const [ldrDataCapt,rsDataItemCapt,executeLoadDataCapt] = useApiQuery("/auth/capt","post",dataCapt);

    const dataTopic = useRef<NewTopicInt>()
    const [ldrDataTopic,rsDataItemTopic,executeLoadDataTopic] = useApiQuery("/tariff/review/new","post",dataTopic);



    const [capt,setCap]=useState("")
    const [topicList, setTopicList] = useState<TopicListInt[]>([])

    const [sendResult, setSendResult]= useState({
        text: "",
        code:-1
    })

    const [acceptPd, setAcceptPd]= useState(false)
    const [formData,setFormData]=useState<FormValidatorInt[]>([
        {
            val:"",
            id:"text",
            errText: "Не верное содержание вопроса",
            regExp: /(\S|(\S\s*)){10,}/ui,
            isCheck: true,
            valid: true
        },
        {
            val:"Гость",
            id:"name",
            errText: "Не корректное имя",
            regExp: /^[a-zа-я]+[a-z0-9-а-я]+$/ui,
            isCheck: true,
            valid: true
        },
        {
            val:"",
            id:"eMail",
            errText: "Не корректный eMail",
            regExp: /(^(.+)@(\S+)$)|(^\s*$)/ui,
            isCheck: true,
            valid: true
        },
        {
            val:"",
            id:"capt",
            errText: "Не корректный код",
            regExp: /^[a-z0-9]{4,}$/ui,
            isCheck: true,
            valid: true
        },
    ])
    const [validate,validateById,getValue,setValue,getErrText] = useFormValidator(formData)






    const loadNewCapt= ()=>
    {
        executeLoadDataCapt().then((e)=>{
            if(e?.success)setCap(e?.payload)
        })

    }

    useEffect(()=>{
        loadNewCapt()
    },[dataCapt])

    useEffect(()=>{
        console.log("data re",data)
        executeLoadData().then((e)=>{
            console.log("review", e)
            if(e?.success)
            {

                    const topicList:TopicListInt[] = e?.payload?.map((item:any)=>{
                        const replyList:TopicListInt[] = item?.replyList?.map((itm:TopicListInt)=>{
                            return {
                                author: itm?.author,
                                text: itm?.text,
                                reply: [],
                                date: itm?.date
                            }
                        })

                        return {


                            author: item?.author,
                            text: item?.text,
                            date: item?.date,
                            reply: replyList
                        }
                    })
                setReviewCount(topicList.length)

                setTopicList(topicList)

            }


        }).catch((p)=>console.log("pp",p))
    },[data])

    const buildReply = (reply:TopicListInt[])=>{
        return reply.map((item,key)=>{
            const date = new Date(item.date).toLocaleDateString()

            return(
                <div className={styles.replyCont} key={key}>
                    <div className={styles.lineTitle}>
                        <span className={styles.lineTitleAuthor}>{item.author}</span>
                        <span className={styles.lineTitleData}>{date}</span>
                    </div>
                    <div className={styles.lineText}>
                        {item.text}
                    </div>

                </div>
            )
        })

    }
    const buildLineList = ()=>{

        const ls =topicList.map((item,key)=>{
            const date = new Date(item.date).toLocaleDateString()
            return(
                <div key={key} className={styles.rewLineCont}>
                    <div className={styles.lineTitle}>
                        <span className={styles.lineTitleAuthor}>{item.author}</span>
                        <span className={styles.lineTitleData}>{date}</span>
                    </div>
                    <div className={styles.lineText}>
                        {item.text}
                    </div>
                    {buildReply(item.reply)}
                </div>
            )

        })
        return ls
    }

    const handleSend=()=>{
        if(!validate())return
        dataTopic.current = {
            author: getValue("name"),
            tariffId: tariffId,
            text: getValue("text"),
            capt: getValue("capt"),

        }
        if(getValue("eMail").toString().length > 3)  dataTopic.current['eMail']=getValue("eMail")
        executeLoadDataTopic().then((e)=>{
            if(e.success)
            {
                if(e?.payload?.code === 0)
                {
                    setSendResult({code: 0,text: "Вопрос отправлен, ожидайте модерации"})
                    setValue("capt","")
                    setAcceptPd(false)
                    loadNewCapt()
                }
                else setSendResult({code: 1,text: e?.payload?.errorAsString })


            } else setSendResult({code: 1,text: "Сервис временно не доступен" })
        })



    }

    return(
        <WaitLoader loading={ldrData} api={rsDataItem} onlyLoading={true}>

        <div className={styles.reviewCont}>
            <div className={styles.rewTitleCont}>
                <span>Вопрос - Ответ{" [" + topicList.length + "]"}</span>
            </div>
            <div className={styles.rewContentCont}>
                {buildLineList()}
            </div>
            <div className={styles.formCont}>
                <div className={styles.formTitle}>Задать вопрос</div>
                <div className={styles.formView}>
                    <form>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Вопрос</label>
                                <textarea className={["form-control form-control-sm", styles.textArea].join(' ')} id="inputEmail4" maxLength={255} rows={6}
                                          value={getValue("text")}
                                          onBlur={()=>validateById("text")}
                                          onChange={(e)=>setValue("text",e.target.value)}>

                                </textarea>
                                <div  className={["", styles.errMessage].join(' ')}>{getErrText("text")}</div>
                            </div>
                        </div>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Имя</label>
                                <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"Гость"}
                                       value={getValue("name")}
                                       onBlur={()=>validateById("name")}
                                       onChange={(e)=>setValue("name",e.target.value)}>

                                </input>
                                <div  className={["", styles.errMessage].join(' ')}>{getErrText("name")}</div>



                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputPassword4" className="form-label col-form-label-sm">eMail</label>
                                <input type="text" className="form-control form-control-sm" id="inputPassword4"
                                    value={getValue("eMail")}
                                    onBlur={()=>validateById("eMail")}
                                    onChange={(e)=>setValue("eMail",e.target.value)}>
                                </input>
                                <div  className={["", styles.errMessage].join(' ')}>{getErrText("eMail")}</div>

                            </div>
                        </div>
                        <div className="row g-3 mb-3">
                            <div className="col-12 mb-3">
                                    <div className={styles.captContainer}>
                                        <label htmlFor="exampleInputEmail1 m-0" className="form-label">Проверочный код</label>
                                        <img className={styles.capImg} src={capt} onClick={()=>{loadNewCapt() }}/>
                                    </div>
                                    <input type="text" className="form-control" placeholder={"Код с картинки"}
                                           aria-describedby="emailHelp"
                                           value={getValue("capt")}
                                           onBlur={()=>validateById("capt")}
                                           onChange={(e)=>setValue("capt",e.target.value)}

                                    ></input>
                                    <div  className={["", styles.errMessage].join(' ')}>{getErrText("capt")}</div>
                            </div>

                        </div>
                        <div className="row g-3 mb-2">
                            <div className="col-12 ">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="gridCheck"
                                           checked={acceptPd}
                                           onChange={()=>setAcceptPd(!acceptPd)}

                                    ></input>
                                    <label className="form-check-label col-form-label-sm" htmlFor="gridCheck">
                                        Даю согласие на обработку данных
                                    </label>
                                </div>
                            </div>

                        </div>
                        {(sendResult.code > 0) && <div  className={["mb-3", styles.errMessage].join(' ')}>{sendResult.text}</div>}
                        {(sendResult.code === 0) && <div  className={["mb-3", styles.goodMessage].join(' ')}>{sendResult.text}</div>}

                        <div className="col-12">
                            <button type="button" className="btn btn-primary" disabled={!acceptPd}
                                onClick={()=>handleSend()}
                                    onKeyDown={(e)=>{
                                        if(e.key.match(/enter/i))handleSend()
                                    }}
                            >Отправить</button>
                            <WaitLoader loading={ldrDataTopic} api={rsDataItemTopic} onlyLoading={true} ><></></WaitLoader>

                        </div>
                    </form>
                </div>
            </div>
        </div>
        </WaitLoader>
    )
}
