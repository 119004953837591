import styles from './FullCard.module.css'
import React, {useContext, useEffect, useRef, useState} from "react";
import {SliderContext} from "./Slider";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setSlider} from "../Reducer/GlobalReducer";
import useApiQuery from "../ApiQuery/UseApiQuery";
import {AppContext} from "../App";
import WaitLoader from "../WaitLoader/WaitLoader";
import ModalWindow from "../ModalWindow/ModalWindow";
import {useNavigate} from "react-router-dom";
import Review from "./Review/Review";


export default function FullCard()
{
    const dispatch = useDispatch();
    const dataCardItem = useSelector((state:ReduxState)  => {return state.slider.dataCardItem})

    //const appContext = useContext(AppContext);
    const navigate = useNavigate()
    //const sliderContext = useContext(SliderContext)
    const [ctn,setCtn]= useState("")
    const [ctnBtnState, setCtnBtnState] = useState(true)
    const [isCtn, setIsCtn]= useState(true)

    const containerRef = useRef<HTMLInputElement>(null)
    const btnCheckRef = useRef<HTMLButtonElement>(null)

    const [tagList, setTagList] = useState<any[]>([])

    const dataCard = useRef({
        tariffId:dataCardItem?.tfId,
    })
    const [ldrDataCard,rsDataCard,exDataCard] = useApiQuery("/tariff/card","post",dataCard);

    const mnpData = useRef({
        ctn:"",
        tariffName:""
    })
    const [ldrMnpData,rsMnpData,exMnpData] = useApiQuery("/tariff/mnp/check","post",mnpData);

    const [reviewCount,setReviewCount] = useState(0)

    const [mnpResultCode ,setMnpResultCode] = useState(-1)

    const mnpUseData = useRef({
        tariffId:dataCardItem?.tfId,
    })
    const [ldrMnpUseData,rsMnpUseData,exMnpUseData] = useApiQuery("/tariff/mnp/available","post",mnpUseData);


    const [mnpAvailable, setMnpAvailable] = useState(false)

    const [mnpMode, setMnpMode] = useState(0)


    useEffect(()=>{
        loadData()

    },[dataCard])


    useEffect(()=>{
        if(containerRef === null) return
        containerRef?.current?.scrollIntoView()
    },[tagList])



    console.log("Full card ",dataCardItem)
    if(! dataCardItem?.tfId) dispatch(setSlider({sliderMode:0}))//Если мод есть а катры нет

    const  loadData = () =>
    {
        exDataCard().then((e)=>{
            console.log("exDataCard",e)
            if(e?.success !== undefined && e.success)
            {
                setTagList(e?.payload)
                exMnpUseData().then((k)=>{
                    console.log("exMnpUseData",k)
                    if(k?.success !== undefined && k.success)
                    {
                        if(k?.payload?.useMnp !== undefined && k?.payload?.useMnp)
                        {
                            setMnpAvailable(true)
                        }
                    }
                })

                return
            }
            navigate('/')

        })
    }

    const buildList = () =>{
        return tagList.map((item,key)=>{
            if(item.val)item.val = item?.val.replace(/\{\{oper\}\}/ui,dataCardItem.operOne.name)
            return(
                    <div key={key} className={styles.tagLinePls}><p><span className={styles.tagLineCapt}>{item?.tagName +" :"}</span>{item?.val}</p></div>
            )
        })
    }

    const buildTariffContent = () =>{
        return(
            <div>
                <ul>
                    <li><span>Пакет интернета :{" " + dataCardItem?.net[0].val + " Гб."}</span></li>
                    {(dataCardItem?.type ===0) && <li><span>Голосовой пакет :{" " + dataCardItem?.packet[0].val + " мин."}</span></li>}
                    {(dataCardItem?.type ===0) && <li><span>Пакет SMS :{" " + dataCardItem?.smsCount + " шт."}</span></li>}
                </ul>
            </div>
        )
    }


    const setCtnVal = (e:any)=> {
        setCtnBtnState(true)
        if( ! e?.target?.value  ) {setCtn(""); return}
        const vl = e?.target?.value
        //console.log(e.target)
        const ms = vl.match(/(\+7|8|^)9[0-9]{9}$/ui)
        //console.log("ms",ms)
        if(!ms) {setCtn(vl); return}
        const flCtn = "+7"+ ms[0].match(/[0-9]{10}$/)[0]
        //console.log("fctn",flCtn)
        setCtn(flCtn)
        setCtnBtnState(false)
        setIsCtn(true)
        if(btnCheckRef.current)btnCheckRef.current.focus()
    }
    const checkCtn = (e:any)=>{
        const flCtn = ctn.match(/\+79[0-9]{9}$/ui)

        if(!flCtn) {setIsCtn(false) ; return }
        setIsCtn(true)
        //setCtn("+7"+flCtn[0])
        //setCtnBtnState(false)

    }

    const loadMnpData=()=>{
        mnpData.current.ctn = ctn
        mnpData.current.tariffName = dataCardItem?.name
        exMnpData().then((e)=>{
            console.log("exMnpData",e)
            if(e?.success !== undefined && e.success)
            {
                if(e?.payload?.useMnp)
                {
                    setMnpMode(1)
                    //setMnpAvailable(true)
                    return
                }
                setMnpMode(2)
                return;
            }
            console.log("setMnpMode")
            setMnpMode(3)
        }).catch((e)=> setMnpMode(3))
    }

    const checkSubMit = ()=>{
        if(! ctn.match(/\+79[0-9]{9}$/ui)) return
        loadMnpData();

    }

    const checkNumber=()=>{
        return(
                <form className={styles.checkCtn} onSubmit={(e)=> e.preventDefault()}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto">
                            <label htmlFor="inputPassword6" className="col-form-label">Ваш номер</label>
                        </div>
                        <div className="col-auto">
                            <input type="text" id="inputPassword6" className="form-control" placeholder={"xxxxxxxxxx"} value={ctn} inputMode="numeric"
                                   aria-describedby="passwordHelpInline"
                                   readOnly={ldrMnpData}
                                   onChange={(e)=>setCtnVal(e)}

                                   onBlur={(e)=>checkCtn(e)}

                                   onKeyDown={(e)=>{if(e.key.match(/enter/i))checkSubMit()}}
                            ></input>

                        </div>
                        <div className="col-auto">
                            <button ref={btnCheckRef} type="button" className= {["btn btn-secondary",styles.btnCheck].join(' ')} disabled={ctnBtnState}
                            onClick={()=>loadMnpData()}

                            >Проверить</button>
                        </div>
                        <div className="col-auto">
                            <WaitLoader loading={ldrMnpData} api={rsMnpData} onlyLoading={true}><></></WaitLoader>

                        </div>
                    </div>
                    {!isCtn && <div  className={["", styles.errMessage].join(' ')}>{"Не корректный номер"}</div>}
                </form>


        )
    }

    const buildMnpResult = (payload:any)=>{
        if(mnpMode === 3) return (
            <div className={styles.mnpResCon}>
                <div className={styles.mnpError}><div style={{whiteSpace:'nowrap'}}>Сервис не доступен, попробуйте позже</div></div>
            </div>
        )
        if(payload?.canMnp)return (
            <div className={styles.mnpResCon}>
                <div className={styles.mnpResLine}>Регион :<span>{payload?.regionName}</span></div>
                <div className={styles.mnpResLineV2}>Ваш номер может быть портирован на этот тариф</div>
                <button type="button" className= {["btn btn-secondary",styles.btnCheck].join(' ')} >Перейти</button>
            </div>
        )
        if(! payload?.canMnp)return (
            <div className={styles.mnpResCon}>
                <div className={styles.mnpResLineV2}>Номер не может быть портирован</div>
                <div className={styles.mnpResLine}><div style={{whiteSpace:'nowrap'}}>Причина :</div><span>{payload?.mnpState ? payload?.mnpState : "Сервис не доступен"}</span></div>
            </div>
        )
        return null


    }

    const buildMnp = ()=>{

        const txt = mnpAvailable ? "Да":"Нет"
        console.log("mnpAvailable",mnpAvailable)

        if(mnpAvailable) return(
            <div className={styles.mnp}>
                <div className={styles.tagLineMnp}>
                    <div className={styles.tagLineMnpCont}>
                        <div className={styles.tagCapt}>Возможность перейти со своим номером (MNP)</div>
                        <div className={[styles.tagCont,styles.smallView].join(' ')}>{txt}</div>
                    </div>
                    <div className={styles.tagLineMnpCont}>
                        {checkNumber()}
                    </div>
                    {mnpMode >0 && <div className={styles.tagLineMnpCont}>
                        {buildMnpResult(rsMnpData.payload)}
                    </div>}
                </div>
                <div className={[styles.tagCont,styles.bigView].join(' ')}>{txt}</div>

            </div>
        )

        return (
            <div className={styles.tagLine}>
                <div className={styles.tagCapt}>Возможность перейти со своим номером</div>
                <div className={[styles.tagCont].join(' ')}>{txt}</div>
            </div>
        )


    }

    const navButton = ()=>{
        return(
            <div className={styles.btnContainer}>
                <div className={styles.btnSelect}
                    onClick={()=>navigate('/order')}
                >Подключить</div>
                <div className={styles.btnSelect}
                     onClick={()=>{
                         dispatch(setSlider({sliderMode:0}))
                         //sliderContext.setMode(0)
                         //navigate('/')
                     }}
                >Назад</div>

            </div>
        )
    }

    //console.log("data card iten", dataCardItem)
    return(
        <WaitLoader loading={ldrDataCard} api={rsDataCard} onlyLoading={true}>
            <div className={styles.tagListContainer}   ref={containerRef}>
                <div className={styles.card}>
                    <div className={styles.tagLine}>
                        <div className={styles.tagCapt}>Оператор</div>
                        <div className={styles.tagCont}>{dataCardItem?.operOne?.name}</div>
                    </div>
                    <div className={styles.tagLine}>
                        <div className={styles.tagCapt}>Название тарифа</div>
                        <div className={styles.tagCont}>{dataCardItem?.name}</div>
                    </div>
                    <div className={styles.tagLine}>
                        <div className={styles.tagCapt}>Стоимость подключения</div>
                        <div className={[styles.tagCont,styles.price].join(' ')}>{dataCardItem?.price+" руб."}</div>
                    </div>
                    <div className={styles.tagLine}>
                        <div className={styles.tagCapt}>Абонентская плата</div>
                        <div className={styles.tagCont}>{dataCardItem?.ap[0]?.val+" руб./мес."}</div>
                    </div>

                    <WaitLoader loading={ldrMnpUseData} api={rsMnpUseData} onlyLoading={true}>
                        {buildMnp()}

                    </WaitLoader>


                    <div className={styles.tagCenter}>
                        <div><span>В абонентскую плату входит</span></div>

                    </div>
                    <div className={styles.tagLine}>
                        {buildTariffContent()}
                    </div>
                    <div  style={{padding:"10px 0 0 0" }}>
                        {mnpAvailable && <div className={styles.tagLinePls}><p><span className={styles.tagLineCapt}>Как перейти :</span>На данный тариф можно перейти с другого оператора, для проверки возможности перехода с Вашего номера, введите номер и нажмите проверить</p></div>}

                        {buildList()}
                    </div>
                        {navButton()}

                </div>
                <Review tariffId={dataCardItem?.tfId} setReviewCount={setReviewCount}></Review>
                {(reviewCount>5 )&& <>{navButton()}</>}
            </div>
        </WaitLoader>
    )
}