import styles from './MainMenu.module.css'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSlider} from "../Reducer/GlobalReducer";
function MainMenu()
{
    const [showShortMenu, setShowShortMenu] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mainMenu, setMainMenu] = useState( [
        {
            type:0,
            visible: true,
            text:"Главная",
            id:0,
            sub:[],
            logo: <i className='bx bx-home'></i>,
            active: true,
            path: "/"
        },
        {
            type:0,
            visible: true,
            text:"Доставка",
            id:1,
            sub:[],
            logo: <i className='bx bxs-truck'></i>,
            active: false,
            path: "/"
        },
        {
            type:0,
            visible: true,
            text:"Оплата",
            id:2,
            sub:[],
            logo: <i className='bx bx-wallet'></i>,
            active: false,
            path: "/"
        },
        {
            type:0,
            visible: false,
            text:"Акции",
            id:3,
            sub:[],
            active: false,
            path: "/"

        },
        {
            type:0,
            visible: true,
            text:"Гарантии",
            id:4,
            sub:[],
            logo: <i className='bx bx-receipt'></i>,
            active: false,
            path: "/"

        },
        {
            type:0,
            visible: true,
            text:"Активация",
            id:5,
            sub:[],
            logo: <i className='bx bx-memory-card'></i>,
            active: false,
            path: "/"

        },
        {
            type:0,
            visible: true,
            text:"Перенос (MNP)",
            id:8,
            sub:[],
            logo: <i className='bx bx-transfer'></i>,
            active: false,
            path: "/"

        },
        {
            type:0,
            visible: true,
            text:"Контакты",
            id:6,
            sub:[],
            logo: <i className='bx bxs-contact'></i>,
            active: false,
            path: "/"

        },
        {
            type:0,
            visible: false,
            text:"Корзина",
            id:7,
            sub:[],
            logo: <i className='bx bx-cart-alt'></i>,
            active: false,
            path: "/"


        },
    ]);


    const activateMenuItem=(id:number) =>{
        let path = "/"
        const newMenu = mainMenu.map((item)=>{
            if(item.id === id) {item.active = true; path = item.path;}
            else item.active = false;
            return item
        })
        setMainMenu(newMenu);
        console.log(`Navigate to ${path}`)
        dispatch(setSlider({sliderMode:0}))
        navigate(path)

    }
    const buildMenu = ()=>{

        return mainMenu.map((item,idx)=>{
            if(! item?.visible) return null
            const active = item?.active ? styles.menuActiveItem : ""
            if(item?.logo)
                return (
                    <div key={idx+11} className={[styles.menuItem, active].join(' ')} onClick={(e)=>activateMenuItem(item.id)}>
                        {item.logo}
                        <li><a href="#" key={idx+10}>{item.text}</a></li>
                    </div>

                );

            return(
                <div key={idx+11} className={[styles.menuItem, active].join(' ')} onClick={(e)=>activateMenuItem(item.id)}>
                    <li><a href="#" key={idx+10}>{item.text}</a></li>
                </div>

            );
        })
    }

    return(
        <div className={styles.mainMenu}>
            <div className={[styles.menuContainer, showShortMenu ? styles.active :""].join(' ')}>
                <ul className={styles.menuList}>
                    {buildMenu()}

                </ul>
            </div>
        <div className={styles.menuButton} onClick={()=>setShowShortMenu(! showShortMenu)}><i className='bx bx-menu'></i></div>
        </div>
    );
}
export default MainMenu