import {useEffect, useMemo, useRef, useState} from "react";
import styles from './DeliveryItem.module.css'
import logoPost from '../Img/Delivery/logoPost.png'
import logoCdek from '../Img/Delivery/logoCdek.png'
import {
    Map,
    ObjectManager,
    Placemark,
    SearchControl,
    useYMaps,
    withYMaps,
    YMaps,
    ZoomControl
} from "@pbe/react-yandex-maps";
import imgMarker from '../Img/Delivery/markerSmall.png'
import {ListBoxItem} from "@pbe/react-yandex-maps";
import {ListBox} from "@pbe/react-yandex-maps";
import {YMapsModules} from "@pbe/react-yandex-maps/typings/util/typing";
import {debounceTime, Subject,from} from "rxjs";
import useApiQuery from "../ApiQuery/UseApiQuery";
import WaitLoader from "../WaitLoader/WaitLoader";

interface YMFeatureObjectInt
{
    type: string
    id:number
    geometry: {
        type:string
        coordinates: number[]
    }
    properties: {
        hintContent: string
        balloonContent: string
        city: string
        isCluster: boolean
        clusterCount: number
        postCode:number
        [key:string]: any

    }
}

interface DeliveryPointInfo
{
    show: boolean
    city: string
    address: string
    postalCode: string
    typeId:number
    price?: number
    duration?: number

}

interface MapStateInt
{
    zoom:number
    topLeft: number[]
    bottomRight:number[]
}


export default function DeliveryItem() {

    const [customerId, setCustomerId] = useState(0)//0 СДЭК 1 Почта
    const [customerList,setCustomerList] = useState([
        {
            name:"компания сдэк",
            logo: logoCdek,
            isSelected: true,
            customerId: 0
        },
        {
            name:"почта России",
            logo:logoPost,
            isSelected: false,
            customerId: 1
        },
    ])


    const pointListData = useRef({
        deliveryType:customerId,
        zoom : 12,
        topLeft:[82.75,54.8],
        bottomRight:[83.16,55.199]
    })
    const [ldrPointList,rsPointList,exPointList] = useApiQuery("/geo/point/load","post",pointListData);

    const openClusterData = useRef({
        clusterId:-1
    })

    const pointInfoData = useRef({
        deliveryId:0,
        pointId: ""
    })
    const [ldrPointInfo,rsPointInfo,exPointInfo] = useApiQuery("/geo/point/info","post",pointInfoData);


    const [pointList,setPointList] = useState<YMFeatureObjectInt[]>([])
    const [clusterList,setClusterList] = useState<YMFeatureObjectInt[]>([])



    const [mapSize, setMapSize]= useState([window.innerWidth < 610 ? window.innerWidth-20 : 800,window.innerWidth < 610 ? window.innerWidth-20 : 600])
    const [bound, setBound] = useState([[54.80, 82.75],[55.199,83.16]])

    const refPointManager = useRef<any>(undefined)
    const refClusterManager = useRef<any>(undefined)
    const refMap = useRef<any>()


    const [vl , setVl] = useState("")
    const [debounceVl,setDebounceVl] = useState("")

    const yApi = useRef<any>(null)

    const rxSearch = useRef( new Subject<string>())
    const rxMove = useRef( new Subject<MapStateInt>())


    const [mapState, setMapState] = useState<MapStateInt>({
        zoom: 10,
        bottomRight:[83.16,55.199],
        topLeft:[ 82.75,54.8]
    })


    const [selectedPoint, setSelectedPoint] = useState<YMFeatureObjectInt>()
    const [selectedPointInfo, setSelectedPointInfo] = useState<any>()

    const [addPay,setAddPay] = useState(0)





    useEffect(()=>{
        pointListData.current = {
            deliveryType: customerId,
            zoom: mapState.zoom,
            topLeft: mapState.topLeft,
            bottomRight: mapState.bottomRight
        }
        loadPointList()
        //setSelectedPoint(undefined)
    },[mapState])

    useEffect(()=>{
        loadPointList()
    },[pointListData,customerId])

    useEffect(()=>{
        loadPointInfo()
    },[selectedPoint])


    useEffect(()=>{
        //if(mapRef.current)mapRef.current.setZoom(10)
    },[pointList])

    const handlePointClick = (e:any) =>{
        const  objectId = e.get('objectId')
        const filter =pointList.filter((item)=>{
            if(item.id === objectId) return true
            return false
        })
        //console.log("filter",filter)
        if (filter.length)  setSelectedPoint(filter[0])
    }
    const handleClusterClick = (e:any) =>{
        setSelectedPoint(undefined)
        try {
            const  objectId = e?.get('objectId')
            const obj = refClusterManager.current?.objects?.getById(objectId)
            if(obj === null) return;
            const coord = obj?.geometry?.coordinates

            let zoom = refMap.current.getZoom()
            zoom =  (zoom+1)>21 ? 21: zoom+1
            refMap.current.setZoom(zoom)
            refMap.current.setCenter(coord)


        } catch (e) {console.log(e)}


    }

    const deliveryLogo = ()=>{
        let logo = undefined
        if(customerId === 0)logo = logoCdek
        if(customerId === 1)logo = logoPost
        if(logo === undefined) return null
        return (
            <div className={styles.logoCont}>
                <img src={logo}/>
            </div>
        )


    }



    const loadPointList = () =>{
        //if(ref.current)ref.current.removeAll()
        exPointList().then((e)=>{
            if(e.success)
            {
               // console.log(e?.payload)
                const apl:YMFeatureObjectInt[] = e?.payload?.map((item:any)=>{
                    const itm:YMFeatureObjectInt = {
                        id: item?.id,
                        type:"Feature",
                        geometry: {
                            type: "Point",
//                            coordinates: [item?.longitude, item?.latitude]
                            coordinates: item?.geometry?.coordinates?.reverse()
                        },
                        properties: {
                            hintContent: item?.properties?.postCode ? item.properties.postCode : "--",
                            balloonContent: "",
                            city: item?.properties?.city ? item.properties.city : "---",
                            isCluster: item?.properties?.isCluster ? true : false,
                            clusterCount: item?.properties?.clusterCount !== undefined ? item?.properties?.clusterCount : 0,
                            postCode: item?.properties?.postCode,
                            typeId: item?.properties?.typeId
                        }
                    }
                    //console.log(itm)
                    //if(ref.current)ref.current.add(itm)
                    return itm
                })

                const point = apl.filter((item)=>{
                    if(item.properties?.isCluster) return false
                    return true
                })
                setPointList(point)

                const cluster = apl.filter((item)=>{
                    if(item.properties?.isCluster) return true
                    return false
                })

                const formattedCluster = cluster.map((item)=>{
                    const count = item?.properties?.clusterCount !== undefined ? item?.properties?.clusterCount : 0
                    return {...item, /*options: {iconColor: "#ff0000", preset: "islands#redCircleIcon"} ,*/ properties: {...item.properties, iconContent: count,hintContent: `${count} точек`} }
                })


                setClusterList(formattedCluster)


            }
        })
    }



    const  handleSearch = (e:string)=>{
        rxSearch.current.next(e)
        setVl(e)
    }

    useEffect(()=> {
        rxSearch.current.pipe(debounceTime(700)).subscribe((e) => {
            setDebounceVl(e ? e?.toString() : "")
        })
    },[rxSearch]);

    useEffect(()=> {
        rxMove.current.pipe(debounceTime(800)).subscribe((e:MapStateInt) => {
            //console.log("map set :",e)
           // setBound([e.topLeft.reverse(),e.bottomRight.reverse()])
            if(ldrPointList) return;
            //console.log("set by move",e)
            setMapState(e)
        })
    },[rxMove]);


    const YaApiItem = ({ymaps}:any)=> {
        if(ymaps )
        {
            yApi.current = ymaps
        }
        return null
    }


    useEffect(()=>{
        if (debounceVl.length > 3 && yApi.current) {

            yApi.current?.geocode(debounceVl,{json:true}).then((e:any)=>{
                try {
                  //  console.log("found",e)
                    const list = e?.GeoObjectCollection?.featureMember
                    if(list === undefined || !Array.isArray(list)) return
                    const addList = list.map((item: any, idx)=>{
                        //console.log(item?.GeoObject)
                        if(idx === 0)
                        {
                            const lb = item?.GeoObject?.boundedBy?.Envelope?.lowerCorner?.split(' ')
                            const tr = item?.GeoObject?.boundedBy?.Envelope?.upperCorner?.split(' ')
                            setBound([[Number(lb[1]),Number(lb[0])],  [Number(tr[1]), Number(tr[0])] ])
                            console.log("found ",[Number(lb[0]),Number(lb[1])],[Number(tr[0]), Number(tr[1])])
                            setMapState({...mapState,topLeft:[Number(lb[0]),Number(lb[1])],bottomRight:[Number(tr[0]), Number(tr[1])]})
                        }
                        return item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.text
                    })
                    console.log("list", addList)
                } catch (e) {console.log(e)}
            })
        }
    },[debounceVl])

    const searchItem = () =>{
        return(
            <div className={styles.inCont}>
                <input className="form-control"
                       type={"text"}
                       value={vl}
                       onChange={(e)=>handleSearch(e.target.value)}
                       placeholder={"Поиск [город, улица]"}

                ></input>
                <div className={styles.ldrCont}>
                    <WaitLoader loading={ldrPointList} api={rsPointList} onlyLoading={true}>

                    </WaitLoader>
                </div>
            </div>
        )
    }






    const ButtonWithYMap = useMemo(()=>{
        return withYMaps(YaApiItem, true,['route','geocode'])
    },[Map])


    const buildSelectedPoint = () =>{
        if(! selectedPoint) return (
            <div>Выберите ПВЗ на карте</div>
        )

        if(customerId === 0)
        {
            return(
                <div>
                    {`Выбранный ПВЗ : [ ${selectedPoint.properties?.postCode} ] ${selectedPoint.properties.city}`}
                </div>
            )
        }
        if(customerId === 1)
        {
            let warning = undefined
            console.log(selectedPoint)
            switch (selectedPoint.properties?.typeId)
            {
                case 33:
                    warning= <span>[ постомат ]</span>
                    break
                case 46:
                    warning= <span>[ партнеры ]</span>
                    break
                case 18:
                    warning= <span>[ передвижной пункт ]</span>
                    break
                default:

            }
            return(
                <div className={styles.spTitle}>
                    {`Выбранный ПВЗ : [ ${selectedPoint.properties?.postCode} ] ${selectedPoint.properties.city} `}{warning}
                </div>
            )
        }

        return null



    }


    const handleMapEvent = (e:any) =>{
        //console.log("fired",e.originalEvent.target.getZoom(),e.originalEvent.target.getBounds())
        try {
            const bound = e.originalEvent.target.getBounds()
            //console.log("bound :", bound)
            if(rxMove)rxMove.current.next({
                zoom: e.originalEvent.target.getZoom(),
                bottomRight: bound[1].reverse(),
                topLeft: bound[0].reverse()
            })

        } catch (e) {

        }
    }


    const handleSelectCustomer = (id :number)=>{
        const n = customerList.map((item,idx)=>{
            item.isSelected = false
            if(idx === id){
                item.isSelected = true
                setCustomerId(item.customerId)
                if(pointListData.current)pointListData.current.deliveryType = item.customerId
                setSelectedPoint(undefined)
            }
            return item
        })
        setCustomerList(n)
    }
    const buildDeliveryList = ()=>{
        return customerList.map((item,idx)=>{
            return(
                <div key={idx} className={styles.delCont}
                    onClick={(e)=>handleSelectCustomer(idx)}
                >
                    <div className={[styles.delMarker, item.isSelected ? styles.marSelected : ""].join(' ')}></div>
                    <img src={item.logo}/>
                    <div className={styles.delText}>{item.name}</div>
                </div>
            )
        })
    }

    const loadPointInfo = () =>{
        if(!selectedPoint) return;
        pointInfoData.current.deliveryId =  customerId
        switch (customerId)
        {
            case 0:
                pointInfoData.current.pointId = selectedPoint?.properties?.postCode ? selectedPoint?.properties?.postCode.toString() : ""
                break
            case 1:
                pointInfoData.current.pointId = selectedPoint?.properties?.postCode ? selectedPoint?.properties?.postCode.toString() : ""
                break
            default: pointInfoData.current.pointId = ""
        }
        if(pointInfoData.current.pointId.length < 1) return
        exPointInfo().then((e)=>{
            if(e.success)
            {
                const item = e?.payload
                setSelectedPointInfo(e?.payload)
                if(e?.payload?.addPay)setAddPay(e?.payload?.addPay)
            }
        })


    }

    const getDeliveryCustomerLine = ()=>{
        const customer = customerList.filter((item)=>{
            if(item.isSelected) return true
            return false
        })
        if(! customer.length) return null
        return (
            <div>Оператор : {customer[0]?.name}</div>
        )
    }

    const getDeliveryPrice = ()=>{
        if(! selectedPointInfo) return null
        switch (customerId)
        {
            case 0:
                if(selectedPointInfo?.delivery?.delivery_sum === undefined) return null
                return (
                    <div>Стоимость доставки : <span className={styles.warInfo}>{selectedPointInfo?.delivery?.delivery_sum + addPay}</span>руб.</div>
                )
            case 1:
        }
        return null




    }
    const getDeliveryDuration = ()=>{
        if(! selectedPointInfo) return null
        switch (customerId)
        {
            case 0:
                if(selectedPointInfo?.delivery?.period_max === undefined) return null
                return (
                    <div>Срок доставки : {selectedPointInfo?.delivery?.period_max + " дн."}</div>
                )
            case 1:
        }
        return null



    }
    const getDeliveryIndexLine = ()=>{
        if(! selectedPointInfo) return
        switch (customerId)
        {
            case 1:
                if(selectedPointInfo?.point?.typeId === undefined) return null
                switch (selectedPointInfo.point.typeId)
                {
                    case 33:
                        return(<div>Индекс : {selectedPointInfo.point.postalCode}<span className={styles.warInfo}>[постомат]</span></div>)
                    case 46:
                        return(<div>Индекс : {selectedPointInfo.point.postalCode}<span className={styles.warInfo}>[партнеры]</span></div>)
                    case 18:
                        return(<div>Индекс : {selectedPointInfo.point.postalCode}<span className={styles.warInfo}>[моб.пункт]</span></div>)
                    default:
                        return(<div>Индекс : {selectedPointInfo.point.postalCode}</div>)

                }
            case 0:
                if(selectedPointInfo?.point?.code === undefined) return null
                return (
                    <div>Код : {selectedPointInfo?.point?.code}</div>
                )
        }
        return null


    }
    const getDeliveryPlace = ()=>{
        if(! selectedPointInfo) return null
        switch (customerId)
        {
            case 0:
                if(selectedPointInfo?.point?.city === undefined) return null
                return (
                    <div>Пункт : {selectedPointInfo?.point?.city }</div>
                )
            case 1:
                if(selectedPointInfo?.point?.city === undefined) return null
                return (
                    <div>Пункт : {selectedPointInfo?.point?.city }</div>
                )
        }
        return null

    }
    const getDeliveryAddress = ()=>{
        if(! selectedPointInfo) return null
        switch (customerId)
        {
            case 0:
                if(selectedPointInfo?.point?.address === undefined) return null
                return (
                    <div>Адрес : {selectedPointInfo?.point?.address }</div>
                )
            case 1:
                if(selectedPointInfo?.point?.address === undefined) return null
                return (
                    <div>Адрес : {selectedPointInfo?.point?.address }</div>
                )
        }
        return null

    }

    return (
        <div className={styles.mCont}>
            <div className={styles.title}>Выберите оператора доставки и пункт выдачи</div>
            <div className={styles.mainCont}>

                <div className={styles.selCont}>
                    <div>
                        {buildDeliveryList()}
                    </div>
                    {selectedPoint &&
                        <WaitLoader loading={ldrPointInfo} api={rsPointInfo} onlyLoading={true}>
                            <div className={styles.infoTitle}>Информация о пункте</div>
                            <div className={styles.infCont}>
                                {getDeliveryCustomerLine()}
                                {getDeliveryIndexLine()}
                                {getDeliveryPlace()}
                                {getDeliveryAddress()}
                                {getDeliveryPrice()}
                                {getDeliveryDuration()}
                            </div>
                        </WaitLoader>
                    }
                </div>

                <YMaps query={{apikey:"a75cc522-75f7-4eb5-bceb-fec4909cee6e",lang:"ru_RU",suggest_apikey: "39210c46-b1e0-48e3-8ccd-f99df46ba09e"}}   >
                    <Map
                        state={{
                            bounds:bound,
                        }}

                        instanceRef={(e)=>{
                            try {
                                if(refMap.current )
                                {
                                    refMap.current?.events.remove('actionend',handleMapEvent)
                                }
                                if(e === null) return
                                refMap.current = e
                                e.events.remove('actionend',handleMapEvent)
                                e.events.add('actionend',handleMapEvent)

                            } catch (e) { console.log(e)}
                        }}
                        height={mapSize[1]}
                        width={mapSize[0]}
                        options={{suppressMapOpenBlock: true,suppressObsoleteBrowserNotifier:true,yandexMapDisablePoiInteractivity: true,copyrightLogoVisible:false,copyrightUaVisible: false,}}
                        //instanceRef={ref}
                        onClick={(e:any)=>{
                            console.log(e.get("coords"))
                            setSelectedPoint(undefined)
                        }}



                    >
                        <div className={styles.pvzTitle}>
                            {buildSelectedPoint()}
                        </div>
                        <ZoomControl  options={{}} />
                        <ObjectManager
                            options={{
                                clusterize: false,
                                gridSize: 0,
                            }}
                            instanceRef = {(e:any)=>{
                                try {
                                    if(refPointManager.current )
                                    {
                                        refPointManager.current?.events.remove('click',handlePointClick)
                                    }
                                    if(e === null) return
                                    refPointManager.current = e
                                    e.events.remove('click',handlePointClick)
                                    e.events.add('click',handlePointClick)
                                } catch (e) {console.log(e)}
                            }}
                            objects={{
                                openBalloonOnClick: false,
                                preset: "islands#greenDotIcon",
                                //iconLayout: 'default#image',
                                // Добавляем своё изображение иконки метки
                                //iconImageHref: imgMarker,
                                // Указываем размеры метки
                                //iconImageSize: [30, 60],
                                // Изменяем положение левого верхнего угла иконки относительно её точки привязки
                                //iconImageOffset: [-10, -20],
                                // Не скрывать метку при открытии балуна
                                // hideIconOnBalloonOpen: false,

                            }}
                            clusters={{
                                preset: "islands#greenClusterIcons",
                            }}
                            features = {{type: "FeatureCollection", features: pointList}}
                            modules={[
                                "objectManager.addon.objectsBalloon",
                                "objectManager.addon.objectsHint",
                            ]}
                        />


                        <ObjectManager
                            options={{
                                clusterize: false,
                                gridSize: 0,
                            }}
                            instanceRef = {(e:any)=>{
                                try {
                                    if(refClusterManager.current )
                                    {
                                        refClusterManager.current?.events.remove('click',handleClusterClick)
                                    }
                                    if(e === null) return
                                    refClusterManager.current = e
                                    e.events.remove('click',handleClusterClick)
                                    e.events.add('click',handleClusterClick)
                                } catch (e) {console.log(e)}
                            }}
                            objects={{
                                openBalloonOnClick: false,
                                preset: "islands#redCircleIcon",
                                //iconLayout: 'default#image',
                                // Добавляем своё изображение иконки метки
                                //iconImageHref: imgMarker,
                                // Указываем размеры метки
                                //iconImageSize: [30, 60],
                                // Изменяем положение левого верхнего угла иконки относительно её точки привязки
                                //iconImageOffset: [-10, -20],
                                // Не скрывать метку при открытии балуна
                                // hideIconOnBalloonOpen: false,

                            }}
                            clusters={{
                                preset: "islands#redCircleIcon",
                            }}
                            features = {{type: "FeatureCollection", features: clusterList}}
                            modules={[
                                "objectManager.addon.objectsBalloon",
                                "objectManager.addon.objectsHint",
                            ]}
                        />



                        {searchItem()}
                        {deliveryLogo()}

                        <ButtonWithYMap />
                    </Map>
                </YMaps>

            </div>

        </div>


    )
}