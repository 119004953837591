import {useContext} from "react";
import {SliderContext} from "./Slider";
import styles from './SliderDotList.module.css'

interface DotPropsInt
{
  number:number
}
export default function Dot({number}:DotPropsInt) {
  const context = useContext(SliderContext);

  return (
    <div
      className={ [styles.dot, context?.slideNumber === number ? styles.selected : "" ].join(' ')}
      onClick={() => context?.goToSlide(number)}
    />
  );
}