import styles from './RegModeOne.module.css'
import React, {useEffect, useRef, useState} from "react";
import SelectNumber, {SimCardUnitInt} from "../SelectNumber/SelectNumber";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setBaseRegion, setOrderData} from "../../Reducer/GlobalReducer";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import RuPersonalData, {PersonalDataInt} from "../PersonalData/PersonalData";
import Payment, {PaymentInt} from "../Payment/Payment";
import DeliveryItem from "../../DeliveryItem/DeliveryItem";




export default function RegModeOne()
{
    const dispatch = useDispatch()
    const orderData = useSelector((state: ReduxState) => {
        return state.orderData
    })
    const [selectedCtn,setSelectedCtn] = useState<SimCardUnitInt>()


    const region = useSelector((state: ReduxState) => {
        return state.baseRegion
    })

    const [stage, setStage] =useState(0)

    useEffect(()=>{
        setStage(4)
    },[region.makeSelectRegion])

    useEffect(()=>{
        if(stage == 0)
        {
//            setSelectedCtn(null)
        }
    },[stage])



    const stageOneRs = (sim:SimCardUnitInt)=>{
        console.log("call stageOneRes",sim)
        if(sim?.isSelected)
        {
            setSelectedCtn(sim)
            dispatch(setOrderData({selectedNumber:sim}))
        }
        setStage(2)
    }

    const stageTwoRs = (data: PersonalDataInt | undefined)=>{

        setStage(3)
    }

    const stageThreeRs = (data: PaymentInt) =>{
        setStage(4)
    }

    return(

        <div className={styles.orderCont}>

            <div className={styles.orderLine}>
                <div >Ваш тариф :</div>
                <span>{orderData.name}</span>
            </div>

            <div className={styles.orderLine}>
                <div >Ваш макрорегион :</div>
                <span>{orderData.macroRegionName}</span>
            </div>


            <div className={styles.orderLine}>
                <div >Стоимость подключения :</div>
                <span className={styles.redColor}>{orderData.price + " руб."}</span>
            </div>
            { stage>1  && selectedCtn?.isSelected &&
                <div className={styles.orderLine}>
                    <div >Выбранный номер :</div>
                    <span>{selectedCtn?.ctn}</span>
                </div>
            }
            { stage>1  && !selectedCtn?.isSelected &&
                <div className={styles.orderLine}>
                    <div >Выбранный номер :</div>
                    <span>Назначит оператор</span>
                </div>
            }

            { stage>2 &&
                <div className={styles.orderLine}>
                    <div >Персональные данные :</div>
                    <span>ОК</span>
                </div>
            }
            { stage>3 &&
                <div className={styles.orderLine}>
                    <div >Доставка выбрана :</div>
                    <span>{orderData?.payment?.amount + " руб."}</span>
                </div>
            }
            { stage>4 &&
                <div className={styles.orderLine}>
                    <div >Оплата прошла :</div>
                    <span>{orderData?.payment?.amount + " руб."}</span>
                </div>
            }


            {stage===0 &&
                <div className={styles.btnLine}>

                    <div className={styles.btnSelect}
                    onClick={()=>setStage(1)}
                    >Продолжить
                    </div>
                </div>
            }



            {stage===1 &&
                <SelectNumber cbExecute={stageOneRs}/>

            }

            {stage===2&&
                <RuPersonalData cbExecute={stageTwoRs}/>
            }

            {stage===4&&
                <Payment cbExecute={stageThreeRs}></Payment>
            }

            {stage===3 &&
                <DeliveryItem/>
            }

        </div>

    )
}