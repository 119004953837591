import styles from './Payment.module.css'
import React, {useEffect, useRef, useState} from "react";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import IframeResizer from 'iframe-resizer-react'
import {PersonalDataInt} from "../PersonalData/PersonalData";
import WaitLoader from "../../WaitLoader/WaitLoader";
import ReactDOM from 'react-dom'
import ModalWindow from "../../ModalWindow/ModalWindow";
import imSBP from '../../Img/Payment/sbp.svg'
import imMir from '../../Img/Payment/mir.svg'
import imVisa from '../../Img/Payment/visa.svg'
import imMaster from '../../Img/Payment/mastercard.svg'
import imMaestro from '../../Img/Payment/maestro.svg'
import imHalva from '../../Img/Payment/halva.svg'
import {useDispatch, useSelector} from "react-redux";
import {ReduxState, setOrderData} from "../../Reducer/GlobalReducer";


export interface PaymentInt
{
    orderId:string
    amount:number
    paymentType: string
}

interface NextButtonCallBackInt
{
    (data:PaymentInt):void
}

export interface PaymentPropsInt
{
    cbExecute: NextButtonCallBackInt
}

export default function Payment({cbExecute}:PaymentPropsInt)
{

    const dispatch = useDispatch()
    const orderData = useSelector((state: ReduxState) => {
        return state.orderData
    })

    const mainRef = useRef<HTMLDivElement>(null)

    const [payment, setPayment] = useState<PaymentInt>({
        orderId:"",
        amount:0,
        paymentType:""
    })


    const [frLdr, setFrLdr] = useState(true)
    const [stage,setStage] = useState(0)
    const [pmUrl, setPmUrl]=useState("")
    const [orderId,setOrderId] = useState("")
    const { innerWidth: width, innerHeight: height } = window;
    const [checkTaskTimerId,setCheckTaskTimerId] = useState<NodeJS.Timer>()
    const [pmWinHeight, setPmWinHeight] = useState(0)

    const [pmType,setPmType] = useState(0)



    const [exRs, setExRs] = useState("")

    const pmUrlData = useRef<PaymentInt>({
        paymentType:"",
        amount: 10,
        orderId:""
    })
    const [ldrPmUrl,rsPmUrl,exPmUrl] = useApiQuery("/payment/url","post",pmUrlData);


    const pmCheckData = useRef(
        {
            orderId:""
        }
    )
    const [ldrPmCheck,rsPmCheck,exPmCheck] = useApiQuery("/payment/state","post",pmCheckData);


    useEffect(()=>{
        setPmWinHeight(Math.floor(height*0.8))//Расчет высоты для фрейма
    },[height])

    useEffect(()=>{
        switch (stage)
        {
            case 0:
                break
            case 1://Кликнули оплатить
                clearInterval(checkTaskTimerId)
                loadUrl()

                break
            case 2: // Ошибка
                break
            case 3://Загрузился iFrame
                checkPayment(orderId)
                break
            case 4: //Платеж прошел
                clearInterval(checkTaskTimerId)
                setFrLdr(true)
                dispatch(setOrderData({...orderData,payment: payment}))
                if(cbExecute)cbExecute(payment)

                break

            default:
        }
        return ()=>{
            clearInterval(checkTaskTimerId)
        }

    },[stage,pmUrlData])


    const checkPayment = (orderIdm: string) =>{
        clearInterval(checkTaskTimerId)
        let count = 0
        const int = setInterval(()=>{
            count++
            console.log("int orderId", orderId,orderIdm,count)
            if(count>100)clearInterval(checkTaskTimerId)
            pmCheckData.current.orderId = orderIdm
            exPmCheck().then((e)=>{
                try {
                    console.log("task check",e)
                    if(e?.success) {
                        console.log(e)
                        if(e?.payload?.state === 1)
                        {
                            clearInterval(checkTaskTimerId)
                            setStage(4)
                        }
                    }

                } catch (e){
                    setStage(2)
                    setExRs("Ошибка формата данных")
                }

                console.log()

            }).catch((e)=>{
                clearInterval(int)
                //setStage(0)
            })
        },2500)
        setCheckTaskTimerId(int)
        console.log("EXIT")
    }

    const loadUrl = ()=>{
        pmUrlData.current = {
            orderId: "",
            amount: 10,
            paymentType: pmType === 0 ? "SBP" : "ACQUIRING"
        }

        setPayment(pmUrlData.current)

        exPmUrl().then((e)=>{
            if(e?.success)
            {
                console.log("url data ",e?.payload)
                setPmUrl(e?.payload?.url)
                setOrderId(e?.payload?.orderId)
                dispatch(setOrderData({...orderData,orderId: e?.payload?.orderId}))
                setStage(3)
                //checkPayment(e?.payload?.orderId)
                return
            }
            setExRs(e?.errorAsString)
            setStage(2)

        }).catch((e)=>{
            setExRs("Ошибка обращения к серверу")
            setStage(2)
            console.log(e)
        })
    }

    const pmClose = ()=>{
        setStage(0)
        setFrLdr(true)
        //        clearInterval(checkTaskTimerId)
    }


    // @ts-ignore
    // @ts-ignore
    return(



        <div className={styles.payCont} ref={mainRef}>


            {stage >= 0 &&
                <div className={styles.pmTypeCont}>
                    <div className={styles.pmTitle}>
                        Выберите способ оплаты
                    </div>
                    <div className={styles.pmTypeSelector}>
                        <div className="form-check">
                            <div className={styles.pmSelLine}>
                                <div className={styles.pmRadio}>
                                    <input className="form-check-input" type={"radio"} name={"pmMode"} value={"sbp"} checked={pmType === 0} id={"spb"}
                                        onClick={()=>setPmType(0)}
                                        onChange={()=>{}}
                                    ></input>
                                    <label className="form-check-label" htmlFor={"spb"}>Ситсема быстрых платежей</label>
                                </div>
                                {pmType === 0 &&
                                    <div className={styles.pmLineImg}>
                                        <div className={styles.pmImList}>
                                            <img src={imSBP}></img>
                                        </div>
                                        <p className={styles.pmItemText}>Оплата заказа по QR-коду или по ссылке. Мгновенное зачисление.Неважно какой банк, главное, чтобы он был подключен к СБП.</p>
                                    </div>
                                }
                            </div>
                            <div className={styles.pmSelLine}>
                                <div className={styles.pmRadio}>
                                    <input className="form-check-input" type={"radio"} name={"card"} value={"sbp"}  id={"card"} checked={pmType === 1}
                                           onClick={()=>setPmType(1)}
                                           onChange={()=>{}}
                                        ></input>
                                    <label className="form-check-label" htmlFor={"card"}>Банковская карта</label>
                                </div>
                                {pmType === 1 &&
                                    <div className={styles.pmLineImg}>
                                        <div className={styles.pmImList}>
                                            <img src={imMir}></img>
                                            <img src={imVisa}></img>
                                            <img src={imMaster}></img>
                                            <img src={imMaestro}></img>
                                            <img src={imHalva}></img>
                                        </div>
                                        <p className={styles.pmItemText}>Принимаются к оплате карты Visa, MasterCard, Maestro, МИР, карта рассрочки «Халва».
                                            Оплата осуществляется в защищенном режиме через платежный шлюз Сбербанка России.</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={styles.pmSelLine}>

                        </div>
                    </div>
                    <div className={styles.btnLine}>

                        <div className={styles.btnSelect}
                             onClick={()=>setStage(1)}
                        >Оплатить
                        </div>
                    </div>

                </div>
            }

            {stage === 1 &&
                <WaitLoader loading={ldrPmUrl} api={rsPmUrl} onlyLoading={true}>
                </WaitLoader>

            }

            {stage === 2 &&  <div className={styles.err}>{exRs}</div>}


            {stage === 3 &&
                <ModalWindow onClose={()=>pmClose()}>

                    <div className={styles.frameCont} style={{height: pmWinHeight+"px"}}>
                        <div style={{position:"absolute"}}>
                            <WaitLoader loading={frLdr} api={rsPmUrl} onlyLoading={true}>
                                <></>
                            </WaitLoader>
                        </div>

                        {!frLdr &&
                            <div className={styles.pmCloseCont}>
                                <div className={styles.pmClose}
                                     onClick={()=>pmClose()}
                                >
                                    Закрыть
                                </div>
                            </div>

                        }
                        <iframe className={styles.ifPayment} src={pmUrl} frameBorder={0}
                                height={pmWinHeight-50+ "px"}
                                onLoad={(e)=>
                                {
                                    setFrLdr(false)
                                    window.scrollTo({top:0})
                                    if(mainRef !==null )mainRef?.current?.scrollIntoView()
                                }}
                        ></iframe>

                    </div>
                </ModalWindow>

            }
            { stage === 4 &&
                <div><h3>Платеж прошел</h3></div>
            }

        </div>
    )
}

