import styles from './NewSimParty.module.css'
import React, {useEffect, useRef, useState} from "react";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import SelectUnit, {SelectUnitDataInt} from "../../FormTemplate/SelectUnit/SelectUnit";
import MainFormTemplate from "../../FormTemplate/MainFormTemplate/MainFormTemplate";

interface NewPartyInt {
    operId: string,
    tariffId: string,
    typeId: number,
    macroRegionId: number,
    bundleId:string,
    categoryId:number,
    date : string,
    filePath:string,
    useAnyRegion:boolean
}

export default function NewSimParty()
{
    const [fileSelected, setFileSelected] = useState(false)
    const data = useRef(new FormData())
    const [ldrXlsFile,rsXlsFile,exeXlsFile] = useApiQuery("/excel/upload","file",data);


    const newPartyData = useRef<NewPartyInt>()
    const [ldrNewParty,rsNewParty,exeNewParty] = useApiQuery("/sim/party/new","post",newPartyData);


    const operData = useRef()
    const [ldrOper,rsOper,exeOper] = useApiQuery("/ref/oper","get",operData);

    const macroRegionData = useRef()
    const [ldrMacroRegion,rsMacroRegion,exeMacroRegion] = useApiQuery("/ref/region/macro","get",macroRegionData);


    const simTypeData = useRef()
    const [ldrSimType,rsSimType,exeSimType] = useApiQuery("/ref/sim/type","get",simTypeData);


    const simCategoryData = useRef()
    const [ldrSimCategory,rsSimCategory,exeSimCategory] = useApiQuery("/ref/sim/category","get",simCategoryData);


    const tariffListData = useRef({
        operId:""
    })
    const [ldrTariff,rsTariff,exeTariff] = useApiQuery("/ref/tariff","post",tariffListData);

    const bundleData = useRef({
        operId:""
    })
    const [ldrBundle,rsBundle,exeBundle] = useApiQuery("/ref/tariff/bundle","post",bundleData);



    const [operList, setOperList] = useState<SelectUnitDataInt[]>([])
    const [operListVal, setOperListVal] = useState<string[]>([])

    const [tariffList, setTariffList] = useState<SelectUnitDataInt[]>([
        {
            name: "Любой тариф",
            val: "ANY"
        }
    ])
    const [tariffListVal, setTariffListVal] = useState<string[]>([])


    const [simTypeList, setSimTypeList] = useState<SelectUnitDataInt[]>([])
    const [simTypeListVal, setSimTypeListVal] = useState<string[]>([])

    const [simCategoryList, setSimCategoryList] = useState<SelectUnitDataInt[]>([])
    const [simCategoryListVal, setSimCategoryListVal] = useState<string[]>([])

    const [bundleList, setBundleList] = useState<SelectUnitDataInt[]>([])
    const [bundleListVal, setBundleListVal] = useState<string[]>([])


    const [macroRegionList, setMacroRegionList] = useState<SelectUnitDataInt[]>([])
    const [macroRegionListVal, setMacroRegionListVal] = useState<string[]>([])


    const [useAnyRegion, setUseAnyRegion] = useState(false)

    const [sendResult, setSendResult]= useState({
        text: "",
        code:-1
    })


    const handleFile = (e :React.ChangeEvent<HTMLInputElement>) =>{
        data.current.delete("file")
        if(! e.target.files)
        {
            setFileSelected(false)
            return
        }
        if(e.target.files.length > 0 )setFileSelected(true)
        else setFileSelected(false)
        data.current.append("file",e.target.files[0])
    }

    useEffect(()=>{
        console.log("Значение тарифа",tariffListVal)

    },[tariffListVal])
    useEffect(()=>{
        console.log("Значение оператора",operListVal)
        if(operListVal.length === 0) return
        tariffListData.current.operId = operListVal[0]
        exeTariff().then((e)=>{
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.name, val: item?.tfId}
                })
                if(newList)setTariffList([tariffList[0],... newList])
                //if(newOperList)setMacroRegionList([macroRegionList[0],...newOperList])


            }
        })

        bundleData.current.operId = operListVal[0]
        exeBundle().then((e)=>{
            console.log("bundle", e)
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.desc, val: item?.bundleId}
                })
                if(newList)setBundleList(newList)
                //if(newOperList)setMacroRegionList([macroRegionList[0],...newOperList])


            }
        })


    },[operListVal])

    useEffect(()=>{
        data.current.forEach((item)=>{
            console.log("data items", item)
        })
    },[data.current])

    useEffect(()=>{
        exeOper().then((e)=>{
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.name, val: item?.operId}
                })
                if(newList)setOperList(newList)


            }

        })
    },[operData])

    useEffect(()=>{
        exeSimType().then((e)=>{
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.name, val: item?.typeId}
                })
                if(newList)setSimTypeList(newList)


            }

        })
    },[simTypeData])

    useEffect(()=>{
        exeSimCategory().then((e)=>{
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.name, val: item?.categoryId}
                })
                if(newList)setSimCategoryList(newList)


            }

        })
    },[simCategoryData])

    useEffect(()=>{
        exeMacroRegion().then((e)=>{
            if(e.success)
            {
                const newList:SelectUnitDataInt[] = e?.payload?.map((item:any)=>{
                    return {name: item?.name, val: item?.macroRegionId}
                })
                //console.log("Регионы",[{name:"Любой регион",val:0},...newOperList ])
                if(newList)setMacroRegionList(newList)


            }

        })
    },[operData])


    const validateForm = ()=>{
        return true
    }
    const sendData= ()=>{

        console.log(simTypeListVal)

        //return;
        if(! validateForm()) return
        newPartyData.current ={
            operId: operListVal[0],
            tariffId: tariffListVal[0],
            typeId: Number(simTypeListVal[0]),
            macroRegionId: Number( macroRegionListVal[0]),
            bundleId:bundleListVal[0],
            categoryId:0,
            date : new Date().toISOString(),
            filePath:"",
            useAnyRegion: useAnyRegion
        }

        exeXlsFile().then((e)=>{
            if(e?.success)
            {
                if(newPartyData.current)newPartyData.current.filePath= e?.payload
                console.log("data ", newPartyData.current)
                exeNewParty().then((k)=>{

                    if(k?.success)
                    {
                        setSendResult({code: 0,text: `Данные загружены, внесено ${k?.payload?.count} записей`})
                    }
                    console.log(k)
                }).catch((e)=>{
                    setSendResult({code: 1,text: e?.errorAsString})
                })

                return;
            }
            setSendResult({code: 1,text: e?.errorAsString})
        }).catch((e)=>{
            setSendResult({code: 1,text: e?.errorAsString})
        })
    }
    //console.log(`operListVal ${operListVal.length}`)
    return(

            <MainFormTemplate title={"Загрузка списка SIM карт"} maxWidth={"800px"}>
                <div className={styles.suCont}>
                        <SelectUnit inData={operList} capt={"Оператор"} getVal={setOperListVal} />
                        <SelectUnit inData={tariffList} capt={"Тариф"} getVal={setTariffListVal} enabled={operListVal.length > 0} />
                        <SelectUnit inData={macroRegionList} capt={"Макрорегион"} getVal={setMacroRegionListVal} />

                </div>

                <div className={styles.suCont}>
                    <SelectUnit inData={simTypeList} capt={"Тип SIM"} getVal={setSimTypeListVal} />
                    <SelectUnit inData={bundleList} capt={"Бандл"} getVal={setBundleListVal} enabled={operListVal.length > 0}/>
                    <SelectUnit inData={simCategoryList} capt={"Категория"} getVal={setSimCategoryListVal} />
                </div>

                <div className="row g-3 mb-2">
                    <div className="col-12 ">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="gridCheck"
                                   checked={useAnyRegion}
                                   onChange={()=>setUseAnyRegion(! useAnyRegion)}
                            ></input>
                            <label className="form-check-label col-form-label-sm" htmlFor="gridCheck">
                                Можно подключать в любом регионе
                            </label>
                        </div>
                    </div>

                </div>



                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Файл для загрузки</label>
                    <input type="text" className="form-control"
                           aria-describedby="emailHelp"></input>
                </div>
                <div className="input-group mb-3">
                    <input type="file" className="form-control" accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                           onChange={(e)=>handleFile(e)}
                    >
                    </input>
                </div>

                {(sendResult.code > 0) && <div  className={["mb-3", styles.errMessage].join(' ')}>{sendResult.text}</div>}
                {(sendResult.code === 0) && <div  className={["mb-3", styles.goodMessage].join(' ')}>{sendResult.text}</div>}

                <div className="input-group mb-3 ">
                    <div className={styles.loaderContainer}>
                        <button type="button" className="btn btn-secondary" disabled={!fileSelected || !validateForm()}
                                onClick={()=>sendData()}
                        >Загрузить</button>
                        <WaitLoader loading={ldrXlsFile || ldrNewParty} api={rsXlsFile} onlyLoading={true} showError={true} ><></></WaitLoader>

                    </div>
                </div>

            </MainFormTemplate>
    )
}